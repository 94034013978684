<template>
    <div class="sons_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title> {{ $t("TITLES.my_sons") }} </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.my_sons") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="sons_content_wrapper fadeIn" v-else>
            <div class="container">
                <div class="row">
                    <!-- START:: SON CARD -->
                    <div
                        v-for="son in userData"
                        :key="son.id"
                        class="col-lg-3 my-3"
                    >
                        <router-link
                            :to="`/son-courses/${son.child_data.id}`"
                            class="son_card_wrapper"
                        >
                            <!-- START:: IMAGE WRAPPER -->
                            <div class="image_wrapper">
                                <img
                                    :src="son.child_data.image"
                                    alt="Son Image"
                                    width="100"
                                    height="100"
                                />
                            </div>
                            <!-- END:: IMAGE WRAPPER -->

                            <!-- START:: NAME -->
                            <h3 class="name">{{ son.child_data.fullname }}</h3>
                            <!-- END:: NAME -->

                            <!-- START:: STUDY YEAR -->
                            <h4 class="study_year">
                                {{ son.child_data.academic_year.name }}
                            </h4>
                            <!-- END:: STUDY YEAR -->
                        </router-link>
                    </div>
                    <!-- END:: SON CARD -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "Sons",

    components: {
        Breadcrumb,
        MainLoader,
    },

    data() {
        return {
            // START:: SONS DATA
            userData: "",
            isLoading: false,
        };
    },
    methods: {
        getSons() {
            // START:: GET TEACHER HOME DATA
            this.isLoading = true;
            this.$axios
                .get("parent/my-children", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isLoading = false;
                    this.userData = res.data.data;
                });
        },
    },
    mounted() {
        this.getSons();
    },
};
</script>
