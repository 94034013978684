<template>
    <div class="registered_students_wrapper">
        <h3 class="title">
            <p>
                {{ $t("TITLES.registered_students") }}
                <span class="students_count">
                    ({{ courseRegisteredStudents.length }}
                    {{ $t("TITLES.student") }})
                </span>
            </p>

            <button
                class="all_registered_students_btn"
                @click="toggleStudentsModal"
                v-if="courseRegisteredStudents.length > 3"
            >
                {{ $t("BUTTONS.show_all") }}
            </button>
        </h3>

        <!-- START:: STUDENTS CARDS -->
        <div class="students_cards_wrapper">
            <div class="row">
                <!-- START:: STUDENT CARD -->
                <div
                    v-for="student in slicedStudentsArray"
                    :key="student.id"
                    class="col-lg-4 my-3"
                >
                    <div class="student_card">
                        <!-- START:: IMAGE -->
                        <div class="image_wrapper">
                            <img
                                :src="student.user.image"
                                :alt="student.user.fullname"
                                width="60"
                                height="60"
                            />
                        </div>
                        <!-- END:: IMAGE -->

                        <!-- START:: NAME -->
                        <h3 class="name">
                            {{ student.user.fullname }}
                        </h3>
                        <!-- END:: NAME -->
                        <div class="d-flex">
                        <router-link
                            :to="`/reports/course=${courseId}/student=${student.user.id}`"
                            class="chat_route ml-2"
                        >
                            <i class="fal fa-address-card"></i>
                        </router-link>

                        <!-- START:: CHAT ROUTE WRAPPER -->
                        <router-link
                            :to="`/chat/${student.id}`"
                            class="chat_route"
                        >
                            <i class="fal fa-comment-lines"></i>
                        </router-link>
                        </div>
                        <!-- END:: CHAT ROUTE WRAPPER -->
                    </div>
                </div>
                <!-- END:: STUDENT CARD -->
            </div>
        </div>
        <!-- END:: STUDENTS CARDS -->

        <!-- START:: ALL REGISTERED STUDENTS -->
        <RegisteredStudentsModal
            :students="courseRegisteredStudents"
            :modalApperanceData="studentsModalIsOpen"
            @controleModalApperance="toggleStudentsModal"
        />
        <!-- END:: ALL REGISTERED STUDENTS -->
    </div>
</template>

<script>
// START:: IMPORTING MODALS
import RegisteredStudentsModal from "../../components/modals/CourseRegisteredStudentsModal.vue";
// END:: IMPORTING MODALS

export default {
    name: "CourseRegisteredStudents",

 props: ["courseRegisteredStudents"],

    components: { RegisteredStudentsModal },

    data() {
        return {
            // START:: MODALS HANDLING DATA
            studentsModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SLICED STUDENTS ARRAY
            slicedStudentsArray: this.courseRegisteredStudents.slice(0, 3),
            // END:: SLICED STUDENTS ARRAY
            courseId: this.$route.params.id,

        };
    },
mounted(){
    console.log(this.courseId);
},
    methods: {
        // START:: TOGGLE STUDENTS MODAL METHOD
        toggleStudentsModal() {
            this.studentsModalIsOpen = !this.studentsModalIsOpen;
        },
        // END:: TOGGLE STUDENTS MODAL METHOD
    },
};
</script>
