import iziToast from "izitoast";
import i18n from "@/i18n.js";

// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS

export default {
    // START:: GET CHATS LIST
    getChatsList(context) {
        axios
            .get(`chats`, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then((res) => {
                context.commit("setChatsList", res.data.data);
            });
    },
    // END:: GET CHATS LIST

    // START:: SEND CHAT MESSAGE
    sendMessage(context, payload) {
        axios
            .post(`chats`, payload.messageDetails, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then((res) => {
                context.commit("setChatsList", res.data.data);
                context.dispatch("getChatContent", payload.id);
                context.dispatch("getChatsList");
            })
            .catch((err) => {
                iziToast.error({
                    timeout: 2000,
                    message: err.response.data.message,
                    messageSize: "22",
                    position: i18n.t("iziToastConfigs.position"),
                    rtl: i18n.t("iziToastConfigs.dir"),
                });
                return;
            });
    },
    // END:: SEND CHAT MESSAGE

    // START:: GET CHAT CONTENT
    getChatContent(context, payload) {
        axios
            .get(`chats/${payload}`, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then((res) => {
                context.commit("setSingleChatData", res.data);
            });
    },
    // END:: GET CHAT CONTENT
};
