<template>
    <div class="skeleton_loader_card_wrapper">
        <div class="skeleton_box chat_card_loader">
            <div class="row">
                <div class="col-4">
                    <div class="skeleton_unit avatar"></div>
                </div>

                <div class="col-8">
                    <div class="row mt-4">
                        <div class="col-8">
                            <div
                                class="skeleton_unit details_skeleton_row"
                            ></div>
                        </div>
                        <div class="col-4">
                            <div
                                class="skeleton_unit details_skeleton_row"
                            ></div>
                        </div>
                        <div class="col-12">
                            <div
                                class="skeleton_unit details_skeleton_row"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatCardSkeletonLoader",
};
</script>
