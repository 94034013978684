<template>
    <div class="featured_courses_wrapper">
        <div class="container">
            <!-- START:: HEADER WRAPPER -->
            <div class="section_title_wrapper">
                <h2 class="title">
                    <img
                        src="../../assets/media/shapes/title_icon.svg"
                        alt="Title Icon"
                    />
                    <span> {{ $t("TITLES.recently_added") }} </span>
                </h2>

                <div class="route_wrapper">
                    <router-link to="/course-section/distance-learning-lessons">
                        {{ $t("BUTTONS.show_all") }}
                    </router-link>
                </div>
            </div>
            <!-- END:: HEADER WRAPPER -->

            <!-- START:: FEATURED COURSES SLIDER -->
            <div class="featured_courses_slider_wrapper mt-5">
                <carousel
                    dir="rtl"
                    :nav="false"
                    :dots="true"
                    :items="4"
                    :margin="15"
                    :loop="false"
                    :autoplay="true"
                    :autoplayTimeout="6000"
                    :dragEndSpeed="2000"
                    :smartSpeed="2000"
                    :responsive="{
                        0: {
                            items: 1,
                        },
                        750: {
                            items: 2,
                        },
                        900: {
                            items: 4,
                        },
                    }"
                >
                    <div
                        :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                        class="slider_image_wrapper"
                        v-for="course in Items"
                        :key="course.id"
                    >
                        <router-link :to="'/courses-categories/' + course.id">
                            <ImagedCard>
                                <template v-slot:card_image>
                                    <img
                                        :src="course.specialization_ar"
                                        alt="Course Category Image"
                                    />
                                </template>

                                <template v-slot:card_text>
                                    <div class="wrapper">
                                        <p class="course_name">
                                            {{ course.subject_name }}
                                        </p>
                                        <p class="courses_count">
                                            {{ course.num_of_courses }} <span class="font-weight-light text-secondary">كورس</span>
                                        </p>
                                    </div>

                                    <div class="wrapper">
                                        <p class="teachers_count">
                                            {{ course.num_of_teachers }} <span class="font-weight-light text-secondary">معلم</span>
                                        </p>
                                    </div>
                                </template>
                            </ImagedCard>
                        </router-link>
                    </div>
                </carousel>
            </div>
            <!-- END:: FEATURED COURSES SLIDER -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// START:: IMPORTING VUEX HELPERS

// START:: IMPORTING OWL CAROUSEL
import carousel from "vue-owl-carousel";
// END:: IMPORTING OWL CAROUSEL

// START:: IMPORTING IMAGED CARD COMPONENT
import ImagedCard from "../ui/ImagedCard.vue";
// END:: IMPORTING IMAGED CARD COMPONENT

export default {
    name: "StudentFeaturedCourses",

    components: {
        carousel,
        ImagedCard,
    },

    computed: {
        // START:: VUEX GET APP THEME
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP THEME
    },
    props: ["Items"],
    data() {
        return {
            // START:: FEATURED COURSES DATA
            // END:: FEATURED COURSES DATA
        };
    },
};
</script>
