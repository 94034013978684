<template>
    <div class="solid_card_wrapper">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "SolidCard"
}
</script>