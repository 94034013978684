<template>
    <div class="delivery_info_form_wrapper">
        <!-- START:: TITLE -->
        <h3 class="form_title">{{ $t("TITLES.delivery_info") }}</h3>
        <!-- END:: TITLE -->

        <!-- START:: FORM -->
        <form @submit.prevent="validateFormInputs">
            <div class="row">
                <!-- START:: PAYMENT CARD SELECT -->
                <div class="input_wrapper imaged_radio my-3">
                    <div class="form-check">
                        <div class="check_wrapper" v-if="userType == 'teacher'">
                            <input
                                id="wallet"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="wallet"
                                v-model="deliveryInfo.paymentType"
                            />

                            <label class="form-check-label" for="wallet">
                                <img
                                    src="../../assets/media/payment/wallet.svg"
                                    alt="Wallet Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>

                        <div class="check_wrapper">
                            <input
                                id="visa"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="visa"
                                v-model="deliveryInfo.paymentType"
                            />
                            <label class="form-check-label" for="visa">
                                <img
                                    src="../../assets/media/payment/visa.svg"
                                    alt="Visa Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>

                        <div class="check_wrapper">
                            <input
                                id="cash"
                                class="form-check-input"
                                type="radio"
                                name="payment_type_select"
                                value="cash"
                                v-model="deliveryInfo.paymentType"
                            />

                            <label class="form-check-label" for="cash">
                                <img
                                    src="../../assets/media/payment/cash.svg"
                                    alt="Cash Logo"
                                    width="90"
                                    height="60"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END:: PAYMENT CARD SELECT -->

                <!-- START:: CITY INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper select_wrapper">
                        <multiselect
                            v-model="deliveryInfo.city"
                            track-by="name"
                            label="name"
                            :placeholder="$t('PLACEHOLDERS.city')"
                            :options="cities"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <!-- END:: CITY INPUT -->

                <!-- START:: DISTRICT INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.district')"
                            v-model.trim="deliveryInfo.district"
                        />
                    </div>
                </div>
                <!-- END:: DISTRICT INPUT -->

                <!-- START:: STREET INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.street')"
                            v-model.trim="deliveryInfo.street"
                        />
                    </div>
                </div>
                <!-- END:: STREET INPUT -->

                <!-- START:: PHONE INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.another_phone')"
                            v-model.trim="deliveryInfo.another_phone"
                        />
                    </div>
                </div>
                <!-- END:: PHONE INPUT -->

                <!-- START:: MESSAGE INPUT -->
                <div class="col-lg-12 my-3">
                    <div class="input_wrapper">
                        <textarea
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.additional_info')"
                            v-model.trim="deliveryInfo.additional_info"
                            rows="3"
                        ></textarea>
                    </div>
                </div>
                <!-- END:: MESSAGE INPUT -->

                <!-- START:: SUBMIT BUTTON WRAPPER -->
                <div class="col-12 btn_wrapper my-3">
                    <button class="w-100 mt-0">
                        {{ $t("BUTTONS.confirm_payment") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON WRAPPER -->
            </div>
        </form>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions, mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "DeliveryInfo",

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            // START:: CITIES DATA
            cities: [],
            // END:: CITIES DATA

            // START:: DELIVERY INFO
            deliveryInfo: {
                paymentType: null,
                city: null,
                district: null,
                street: null,
                another_phone: null,
                additional_info: null,
            },
            // END:: DELIVERY INFO
        };
    },

    methods: {
        // START:: VUEX REMOVE ITEM FROM SHOPPING CART
        ...mapActions("StoreAndWishlistModule", ["getCart"]),
        // END:: VUEX REMOVE ITEM FROM SHOPPING CART

        // START:: GET CITES
        getCites() {
            this.$axios
                .get("cities", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.cities = res.data.data;
                });
        },
        // END:: GET CITES

        // START:: VALIDATE FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;

            if (!this.deliveryInfo.paymentType) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.select_payment_type"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.deliveryInfo.city) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.select_city"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.deliveryInfo.district) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.district"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.deliveryInfo.street) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.street"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitForm();
            }
        },
        // END:: VALIDATE FORM INPUTS

        // START:: SUBMIT FORM
        submitForm() {
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND DATA
            theData.append("pay_type", this.deliveryInfo.paymentType);
            theData.append("city_id", this.deliveryInfo.city.id);
            theData.append("neighborhood", this.deliveryInfo.district);
            theData.append("street", this.deliveryInfo.street);
            theData.append("phone", this.deliveryInfo.another_phone);
            theData.append("addition_data", this.deliveryInfo.additional_info);
            theData.append("transactionId", "static transaction id");
            // END:: APPEND DATA

            // START:: SEND REQUEST
            this.$axios
                .post("user/order", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;

                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("MESSAGES.paied_successfully"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.clearPaymentFormData();
                    this.getCart();
                    this.$router.replace("/my-orders");
                    return;
                })
                .catch((err) => {
                    this.isWaitingRequest = false;

                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: err.response.data.message,
                        messageSize: "22",
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT FORM

        // START:: CLEAR PAYMENT FORM DATA
        clearPaymentFormData() {
            this.deliveryInfo.paymentType = null;
            this.deliveryInfo.city = null;
            this.deliveryInfo.district = null;
            this.deliveryInfo.street = null;
            this.deliveryInfo.another_phone = null;
            this.deliveryInfo.additional_info = null;
        },
        // END:: CLEAR PAYMENT FORM DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCites();
        // END:: FIRE METHODS
    },
};
</script>
