<template>
    <div class="cards_outer_wrapper">
        <div class="strengthening_requests_card">
            <!-- START:: REQUEST DETAILS -->
            <div class="request_details">
                <!-- START:: CARD IMAGE -->
                <div class="avatar_wrapper">
                    <img
                        :src="
                            userType == 'teacher'
                                ? requestData.student.image
                                : requestData.teacher.image
                        "
                        :alt="
                            userType == 'teacher'
                                ? requestData.student.fullname
                                : requestData.teacher.fullname
                        "
                        width="120"
                        height="110"
                    />
                </div>
                <!-- END:: CARD IMAGE -->

                <div class="wrapper">
                    <!-- START:: NAME AND DATE -->
                    <h3 class="name_and_date">
                        <span class="name">
                            {{
                                userType == "teacher"
                                    ? requestData.student.fullname
                                    : requestData.teacher.fullname
                            }}
                        </span>
                        <span class="date"> {{ requestData.created_at }} </span>
                    </h3>
                    <!-- END:: NAME AND DATE -->

                    <!-- START:: SUBJECT NAME AND STUDY YEAR -->
                    <h4 class="subject_name">{{ requestData.subjectName }}</h4>
                    <h4 class="study_year" v-if="userType == 'parent'">
                        {{ requestData.student.fullname }}
                    </h4>
                    <h4 class="study_year">
                        {{ requestData.student.academic_year.name }}
                    </h4>
                    <!-- END:: SUBJECT NAME AND STUDY YEAR -->
                </div>
            </div>
            <!-- END:: REQUEST DETAILS -->

            <!-- START:: REQUEST STATUS -->
            <div class="request_status">
                <!-- START:: STATUS -->
                <div class="status_wrapper" :class="requestData.status">
                    <span class="icon">
                        <i
                            class="fad fa-spinner-third"
                            v-if="requestData.status == 'waitting'"
                        ></i>
                        <i
                            class="far fa-check-circle"
                            v-else-if="requestData.status == 'accepted'"
                        ></i>
                        <i class="far fa-times-circle" v-else></i>
                    </span>
                    <span class="status" v-if="requestData.status == 'waitting'">
                        {{ $t("STATUS.new_request") }}
                    </span>
                    <span
                        class="status"
                        v-else-if="requestData.status == 'accepted'"
                    >
                        {{ $t("STATUS.accepted") }}
                    </span>
                    <span class="status" v-else>
                        {{ $t("STATUS.canceled") }}
                    </span>
                </div>
                <!-- END:: STATUS -->

                <!-- START:: CONTACT SECTION -->
                <div
                    class="contact_options_wrapper"
                    v-if="requestData.status == 'accepted'"
                >
                    <!-- START:: CONTACT OPTIONS TOGGELER-->
                    <button
                        class="toggle_contact_options"
                        @click="toggleContactOptions"
                    >
                        <span v-if="getAuthenticatedUserData.type == 'parent'">
                            {{ $t("BUTTONS.contact_teacher") }}
                        </span>
                        <span v-if="getAuthenticatedUserData.type == 'teacher'">
                            {{ $t("BUTTONS.contact_parent") }}
                        </span>
                    </button>
                    <!-- START:: CONTACT OPTIONS TOGGELER-->

                    <!-- START:: CONTACT OPTIONS -->
                    <div
                        class="row justify-content-around"
                        v-if="contactOptionsIsOpen"
                    >
                        <div class="col-12 my-2 p-0">
                            <h5 class="m-0 text-center phone_number">
                                <span>
                                    <i class="fas fa-phone"></i>
                                </span>

                                <span class="mx-2" v-if=" getAuthenticatedUserData.type == 'teacher' ">
                                    {{ requestData.parent.phone }}
                                </span>
                                <span class="mx-2" v-else>
                                    {{ requestData.teacher.phone }}
                                </span>
                            </h5>
                        </div>
                        <!-- START:: CHAT ROUTE -->
                        <div class="col-5 my-2 p-0">
                            <router-link
                                :to="
                                    getAuthenticatedUserData.type == 'teacher'
                                        ? `/chat/${requestData.parent.id}`
                                        : `/chat/${requestData.teacher.id}`
                                "
                                class="chat_route"
                            >
                                <i class="fal fa-comment-lines"></i>
                            </router-link>
                        </div>
                        <!-- END:: CHAT ROUTE -->

                        <!-- START:: PHONE CONTACT -->
                        <div class="col-5 my-2 p-0">
                            <a
                                :href="
                                    getAuthenticatedUserData.type == 'teacher'
                                        ? 'tel:' + requestData.parent.phone
                                        : 'tel:' + requestData.teacher.phone
                                "
                                class="phone_contact"
                            >
                                <i class="fal fa-mobile-android"></i>
                            </a>
                        </div>
                        <!-- END:: PHONE CONTACT -->
                    </div>
                    <!-- END:: CONTACT OPTIONS -->
                </div>
                <!-- END:: CONTACT SECTION -->

                <!-- START:: STATUS ACTIONS BUTTONS -->
                <div
                    class="status_actions_btns_wrapper"
                    v-if="
                        getAuthenticatedUserData.type == 'teacher' &&
                        requestData.status == 'waitting'
                    "
                >
                    <button
                        class="accept_request"
                        @click="
                            changeStrengtheningRequestsStatus({
                                id: requestData.id,
                                newStatus: 'accepted',
                            })
                        "
                    >
                        {{ $t("BUTTONS.accept") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>

                    <button
                        class="reject_request"
                        @click="
                            changeStrengtheningRequestsStatus({
                                id: requestData.id,
                                newStatus: 'rejected',
                            })
                        "
                    >
                        {{ $t("BUTTONS.reject") }}
                    </button>
                </div>
                <!-- END:: STATUS ACTIONS BUTTONS -->
            </div>
            <!-- END:: REQUEST STATUS -->
        </div>

        <!-- START:: SUCCESS MODAL -->
        <SuccessModal
            :modalApperanceData="successModalIsOpen"
            @controleModalApperance="toggleSuccessModal"
        >
            <template #title>
                {{ $t("TITLES.request_accepted") }}
            </template>
            <template #sub_title>
                {{ $t("TITLES.you_can_chat_the_parent") }}
            </template>
            <template #success_btn>
                <button @click="toggleSuccessModal">
                    {{ $t("BUTTONS.ok") }}
                </button>
            </template>
        </SuccessModal>
        <!-- END:: SUCCESS MODAL -->

        <!-- START:: CANCEL MODAL -->
        <CancelModal
            :modalApperanceData="cancelModalIsOpen"
            @controleModalApperance="toggleCancelModal"
        >
            <template #title>
                {{ $t("TITLES.request_canceled") }}
            </template>
            <template #cancel_btn>
                <button @click="toggleCancelModal">
                    {{ $t("BUTTONS.ok") }}
                </button>
            </template>
        </CancelModal>
        <!-- END:: CANCEL MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions, mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING MODALS
import SuccessModal from "../modals/SuccessModal.vue";
import CancelModal from "../modals/CancelModal.vue";
// END:: IMPORTING MODALS

export default {
    name: "StrengtheningRequestCard",

    components: {
        SuccessModal,
        CancelModal
    },

    props: ["requestData"],

    watch: {
        "requestData.status"(newVal) {
            if (newVal == "accepted") {
                this.toggleSuccessModal();
            } else if (newVal == "rejected") {
                this.toggleCancelModal();
            }
        },
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: CONTACT OPTIONS VISIBILITY DATA
            contactOptionsIsOpen: false,
            // END:: CONTACT OPTIONS VISIBILITY DATA

            // START:: MODALS HANDLING DATA
            successModalIsOpen: false,
            cancelModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE DATA
        };
    },

    methods: {
        // START:: VUEX GET STRENGTHENING REQUESTS DATA
        ...mapActions("StrengtheningRequestsModule", [
            "changeStrengtheningRequestsStatus",
        ]),
        // END:: VUEX GET STRENGTHENING REQUESTS DATA

        // START:: TOGGLE CONTACT OPTIONS
        toggleContactOptions() {
            this.contactOptionsIsOpen = !this.contactOptionsIsOpen;
        },
        // END:: TOGGLE CONTACT OPTIONS

        // START:: TOGGLE SUCCESS MODAL METHOD
        toggleSuccessModal() {
            this.successModalIsOpen = !this.successModalIsOpen;
        },
        // END:: TOGGLE SUCCESS MODAL METHOD

        // START:: TOGGLE SUCCESS MODAL METHOD
        toggleCancelModal() {
            this.cancelModalIsOpen = !this.cancelModalIsOpen;
        },
        // END:: TOGGLE SUCCESS MODAL METHOD
    },
};
</script>
