<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="account_form_wrapper fadeIn" v-else>
            <!-- START:: STUDENT CODE WRAPPER -->
            <div v-if="getAuthenticatedUserData.type == 'student'" >
                                <h4 class="text-center">:QR Code</h4>
                                <img :src="editAccountData.qrImageLink" alt="Qr Link" style="width: 220px; height: auto;" />
                            </div>

            <h3
                class="student_code_wrapper"
                v-if="getAuthenticatedUserData.type == 'student'"
            >
                <span class="title"> {{ $t("TITLES.student_code") }}: </span>
                <span class="code"> #{{ editAccountData.studentCode }} </span>
            </h3>
            <!-- END:: STUDENT CODE WRAPPER -->

            <!-- START:: FORM -->
            <form @submit.prevent="validateEditProfileForm" autocomplete="off">
                <div class="row justify-content-center">
                    <!-- START:: AVATAR INPUT -->
                    <div class="col-12 my-3">
                        <div class="single_image_input_wrapper">

                            <div class="wrapper">
                                <label for="user_avatar">
                                    <i class="fal fa-camera-alt"></i>
                                </label>
                                <img
                                    class="user_avatar"
                                    :src="editAccountData.avatar.bath"
                                    alt="User Avatar"
                                    width="150"
                                    height="150"
                                />
                                <input
                                    type="file"
                                    accept="image/png, image/jpg, image/jpeg"
                                    class="form-control"
                                    id="user_avatar"
                                    :placeholder="
                                        $t('PLACEHOLDERS.student_code')
                                    "
                                    @change="selectUploadedImage"
                                />
                            </div>
                   
                        </div>
                    </div>
                    <!-- END:: AVATAR INPUT -->

                    <!-- START:: NAME INPUT GROUP -->
                    <div
                        class="my-3"
                        :class="
                            getAuthenticatedUserData.type == 'parent'
                                ? 'col-lg-12'
                                : 'col-lg-6'
                        "
                    >
                        <div class="input_wrapper">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.user_name')"
                                v-model.trim="editAccountData.name"
                            />
                        </div>
                    </div>
                    <!-- END:: NAME INPUT GROUP -->

                    <!-- START:: STUDENT CODE INPUT GROUP -->
                    <template v-if="getAuthenticatedUserData.type == 'parent'">
                        <div
                            class="col-lg-6 my-3"
                            v-for="(
                                code, index
                            ) in editAccountData.studentCodes"
                            :key="code.id"
                        >
                            <div class="input_wrapper form_repeater">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.student_code')
                                    "
                                    v-model="
                                        editAccountData.studentCodes[index]
                                            .student_code
                                    "
                                />

                                <button
                                    type="button"
                                    class="delete_child"
                                    @click="
                                        deleteChild(
                                            editAccountData.studentCodes[index]
                                                .student_code
                                        )
                                    "
                                    :disabled="
                                        editAccountData.studentCodes.length == 1
                                    "
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    </template>

                    <!-- START:: STUDENT NEW CODES INPUT GROUP -->
                    <template v-if="getAuthenticatedUserData.type == 'parent'">
                        <div
                            class="col-lg-6 my-3"
                            v-for="(
                                code, index
                            ) in editAccountData.newStudentCodes"
                            :key="code.id"
                        >
                            <div class="input_wrapper form_repeater">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.student_code')
                                    "
                                    v-model="
                                        editAccountData.newStudentCodes[index]
                                            .student_code
                                    "
                                />

                                <button
                                    type="button"
                                    class="delete_child"
                                    @click="
                                        deleteNewChild(
                                            editAccountData.newStudentCodes[
                                                index
                                            ].id
                                        )
                                    "
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    </template>

                    <div
                        class="col-lg-6 my-3"
                        v-if="getAuthenticatedUserData.type == 'parent'"
                    >
                        <div class="input_wrapper form_repeater">
                            <button
                                type="button"
                                class="add_another w-100 justify-content-center"
                                @click="addAnotherStudentCode"
                            >
                                <i class="fal fa-plus"></i>
                                {{ $t("BUTTONS.add_another") }}
                            </button>
                        </div>
                    </div>
                    <!-- END:: STUDENT NEW CODES INPUT GROUP -->

                    <!-- START:: STUDY DEGREE INPUT GROUP -->
                    <div
                        class="col-lg-6 my-3"
                        v-if="getAuthenticatedUserData.type == 'student'"
                    >
                        <div class="input_wrapper select_wrapper">
                            <multiselect
                                v-if="studyDegreesData"
                                v-model="editAccountData.studyDegree"
                                track-by="name"
                                label="name"
                                :placeholder="$t('PLACEHOLDERS.study_degree')"
                                :options="studyDegreesData"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                :open-direction="'bottom'"
                                @input="
                                    getStudyYears(
                                        editAccountData.studyDegree.id
                                    )
                                "
                            >
                            </multiselect>
                        </div>
                    </div>
                    <!-- END:: STUDY DEGREE INPUT GROUP -->

                    <!-- START:: STUDY YEAR INPUT GROUP -->
                    <div
                        class="col-lg-6 my-3"
                        v-if="getAuthenticatedUserData.type == 'student'"
                    >
                        <div class="input_wrapper select_wrapper">
                            <multiselect
                                v-model.trim="editAccountData.studyYear"
                                track-by="name"
                                label="name"
                                :placeholder="$t('PLACEHOLDERS.study_year')"
                                :options="studyYearsData"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <!-- END:: STUDY YEAR INPUT GROUP -->

                    <!-- START:: PHONE INPUT GROUP -->
                    <div class="col-lg-6 my-3">
                        <div
                            class="input_wrapper phone_input_wrapper"
                            dir="ltr"
                        >
                            <div
                                class="selected_key_wrapper"
                                @click="toggleCountryKeysMenu"
                                v-if="countriesData.selectedCountry"
                            >
                                <div class="flag_wrapper">
                                    <img
                                        :src="countriesData.selectedCountry.flag"
                                        :alt="countriesData.selectedCountry.name"
                                        width="30"
                                        height="20"
                                    />
                                </div>
                                <div class="key_wrapper" dir="ltr">
                                    {{ countriesData.selectedCountry.key }}
                                </div>

                                <transition name="fadeInUp" mode="out-in">
                                    <div
                                        class="country_keys_menu_wrapper"
                                        v-if="countryKiesMenuIsOpen"
                                    >
                                        <ul class="country_keys_menu">
                                            <li
                                                class="menu_item"
                                                v-for="countryKey in countriesData.allCountries"
                                                :key="countryKey.id"
                                                @click="
                                                    changeSelectedPhoneCode(countryKey);
                                                    getCities(countryKey.id);
                                                "
                                            >
                                                <div class="flag_wrapper">
                                                    <img
                                                        :src="countryKey.flag"
                                                        alt="Flag"
                                                        width="30"
                                                        height="20"
                                                    />
                                                </div>
                                                <div
                                                    class="key_wrapper"
                                                    dir="ltr"
                                                >
                                                    {{ countryKey.key }}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </transition>
                            </div>

                            <input
                                type="number"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.phone')"
                                @focus="countryKiesMenuIsOpen = false"
                                v-model.trim="editAccountData.phone"
                            />
                        </div>
                    </div>
                    <!-- END:: PHONE INPUT GROUP -->

                    <!-- START:: CITY INPUT GROUP -->
                    <div class="col-12 my-3">
                        <div class="input_wrapper select_wrapper">
                            <multiselect
                                v-model="editAccountData.city"
                                track-by="name"
                                label="name"
                                :placeholder="$t('PLACEHOLDERS.city')"
                                :options="citiesData"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                :open-direction="'bottom'"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <!-- END:: CITY INPUT GROUP -->

                    <!-- START:: PASSWORD INPUT GROUP -->
                    <div class="col-lg-6 my-3">
                        <div class="input_wrapper password_input_wrapper">
                            <input
                                type="password"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.password')"
                                v-model.trim="editAccountData.password"
                            />
                            <button
                                type="button"
                                class="password_visibility_toggler"
                                @click="togglePasswordVisibility"
                            >
                                <i class="fal fa-eye"></i>
                                <i class="fal fa-eye-slash"></i>
                            </button>
                        </div>
                    </div>
                    <!-- END:: PASSWORD INPUT GROUP -->

                    <!-- START:: CONFIRM PASSWORD INPUT GROUP -->
                    <div class="col-lg-6 my-3">
                        <div class="input_wrapper password_input_wrapper">
                            <input
                                type="password"
                                class="form-control"
                                :placeholder="
                                    $t('PLACEHOLDERS.confirm_password')
                                "
                                v-model.trim="editAccountData.confirmPassword"
                            />
                            <button
                                type="button"
                                class="password_visibility_toggler"
                                @click="togglePasswordVisibility"
                            >
                                <i class="fal fa-eye"></i>
                                <i class="fal fa-eye-slash"></i>
                            </button>
                        </div>
                    </div>
                    <!-- END:: CONFIRM PASSWORD INPUT GROUP -->

                    <!-- START:: SUBMIT BUTTON WRAPPER -->
                    <div class="col-lg-7 my-3">
                        <div class="btn_wrapper">
                            <button class="w-100 mt-0">
                                {{ $t("BUTTONS.edit_info") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequest"
                                ></span>
                            </button>
                        </div>
                    </div>
                    <!-- END:: SUBMIT BUTTON WRAPPER -->
                </div>
            </form>
            <!-- END:: FORM -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// START:: IMPORTING VUEX HELPERS

import avatar from "../../assets/media/images/user_avatar.png";
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "PersonalInfoForm",
    components: {
        MainLoader,
    },
    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START:: VUEX GET API GETS DATA
        ...mapGetters("ApiGetsModule", ["countriesData", "citiesData", "studyDegreesData","studyYearsData",]),
        // END:: VUEX GET API GETS DATA

        // START:: STUDENTS CODES SHIFTED FIRST DUMMY ELEMENT ARRAY
        studentCodesShifted() {
            return this.editAccountData.studentCodes.filter((item, index) => {
                return index != 0;
            });
        },
        // END:: STUDENTS CODES SHIFTED FIRST DUMMY ELEMENT ARRAY
    },

    data() {
        return {
            isLoading: false,
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: REGISTER TYPE DATA
            registerationType: this.$route.params.user_type,
            // END:: REGISTER TYPE DATA

            // START:: COUNTRIES KEYS MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KEYS MENU HANDLING DATA

            // START:: EDIT ACCOUNT DATA
            editAccountData: {
                avatar: {
                    bath: avatar,
                    file: null,
                },
                name: null,
                studentCodes: [
                    {
                        id: 1,
                        student_code: null,
                    },
                ],
                newStudentCodes: [],
                studyDegree: null,
                studyYear: null,
                phone: null,
                city: null,
                qrImageLink: null,
                password: null,
                confirmPassword: null,
            },
            // END:: EDIT ACCOUNT DATA
        };
    },

    methods: {
        // START:: VUEX SET AUTHENTICATED USER DATA
        ...mapActions("AuthenticationModule", ["setAuthenticatedUserData"]),
        // END:: VUEX SET AUTHENTICATED USER DATA

        // START:: VUEX API GETS ACTIONS
        ...mapActions("ApiGetsModule", [
            "getCountries",
            "getCities",
            "changeSelectedPhoneCode",
            "getStudyDegrees",
            "getStudyYears",
        ]),
        // END:: VUEX API GETS ACTIONS

        // START:: SELECT UPLOADED IMAGE
        selectUploadedImage(e) {
            this.editAccountData.avatar.bath = URL.createObjectURL(
                e.target.files[0]
            );
            this.editAccountData.avatar.file = e.target.files[0];
        },
        // END:: SELECT UPLOADED IMAGE

        // START:: TOGGLE COUNTRIES KEYS MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KEYS MENU

        // START:: TOGGLE PASSWORD VISIBILITY METHOD
        togglePasswordVisibility(e) {
            let passwordElement = e.currentTarget.parentElement.children[0];
            let passwordTogglerBtn = e.currentTarget;
            if (passwordElement.type == "password") {
                passwordElement.type = "text";
                passwordTogglerBtn.classList.add("password_is_visible");
            } else if (passwordElement.type == "text") {
                passwordElement.type = "password";
                passwordTogglerBtn.classList.remove("password_is_visible");
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY METHOD

        // START: GET STUDENT PROFILE DATA
        getProfileData() {
            this.isLoading = true;
            if (this.getAuthenticatedUserData.type == "parent") {
                this.$axios
                    .get("parent/profile", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.editAccountData.avatar.bath =res.data.data.profile_image;
                        this.editAccountData.name = res.data.data.fullname;
                        this.editAccountData.phone = res.data.data.phone;
                        this.editAccountData.city = res.data.data.city;
                        this.editAccountData.studentCodes = res.data.data.children;
                        this.changeSelectedPhoneCode(res.data.data.country);
                        // START:: GET CITIES
                        this.getCities(res.data.data.country.id);
                        // END:: GET CITIES
                        this.isLoading = false;
                    });
            } else if (this.getAuthenticatedUserData.type == "student") {
                this.$axios
                    .get("student/profile", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.editAccountData.avatar.bath = res.data.data.profile_image;
                        this.editAccountData.studentCode = res.data.data.student_code;
                        this.editAccountData.name = res.data.data.fullname;
                        this.editAccountData.studyDegree = res.data.data.degree;
                        this.editAccountData.studyYear = res.data.data.academic_year;
                        this.editAccountData.phone = res.data.data.phone;
                        this.editAccountData.city = res.data.data.city;
                        this.editAccountData.qrImageLink = res.data.data.qr_image_link;
                        this.changeSelectedPhoneCode(res.data.data.country);
                        // START:: GET CITIES
                        this.getCities(res.data.data.country.id);
                        // END:: GET CITIES

                        // START:: GET STUDY YEARS
                        this.getStudyYears(res.data.data.degree.id);
                        // START:: GET STUDY YEARS
                        this.isLoading = false;
                    });
            } else if (this.getAuthenticatedUserData.type == "teacher") {
                this.$axios
                    .get("teacher/profile", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.editAccountData.avatar.bath = res.data.data.profile_image;
                        this.editAccountData.name = res.data.data.fullname;
                        this.editAccountData.phone = res.data.data.phone;
                        this.editAccountData.city = res.data.data.city;
                        this.changeSelectedPhoneCode(res.data.data.country);
                        // START:: GET CITIES
                        this.getCities(res.data.data.country.id);
                        // END:: GET CITIES
                        this.isLoading = false;
                    });
            }
        },
        // END: GET STUDENT PROFILE DATA

        // START:: ADD ANOTHER STUDENT CODE
        addAnotherStudentCode() {
            this.editAccountData.newStudentCodes.push({
                id: Date.now(),
                student_code: null,
            });
        },
        // END:: ADD ANOTHER STUDENT CODE

        // START:: DELETE CHILD CODE
        deleteChild(code) {
            this.$axios
                .delete(`parent/delete_child/${code}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.$iziToast.success({
                        timeout: 2000,
                        message: res.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });

                    // START:: GET UPDATED PROFILE DATA
                    this.getProfileData();
                    // END:: GET UPDATED PROFILE DATA
                })
                .catch((err) => {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                });
        },

        deleteNewChild(codeId) {
            let nweFilteredArr = this.editAccountData.newStudentCodes.filter(
                (element) => element.id != codeId
            );
            this.editAccountData.newStudentCodes = nweFilteredArr;
        },
        // END:: DELETE CHILD CODE

        // START:: VALIDATE EDIT PROFILE
        validateEditProfileForm() {
            this.isWaitingRequest = true;

            if (!this.editAccountData.name) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.name"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (
                this.getAuthenticatedUserData.type == "student" &&
                !this.editAccountData.studyYear
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.study_year"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (
                this.getAuthenticatedUserData.type == "student" &&
                !this.editAccountData.studyDegree
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.study_degree"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editAccountData.phone) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.phone_number"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editAccountData.city) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.select_city"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.editProfileData();
            }
        },
        // END:: VALIDATE EDIT PROFILE

        // START:: EDIT PROFILE DATA
        editProfileData() {
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND GENERAL DATA
            if (this.editAccountData.avatar.file) {
                theData.append("image", this.editAccountData.avatar.file);
            }
            theData.append("fullname", this.editAccountData.name);
            theData.append("country_id", this.countriesData.selectedCountry.id);
            theData.append("phone", this.editAccountData.phone);
            theData.append("city_id", this.editAccountData.city.id);
            if (
                this.editAccountData.password &&
                this.editAccountData.confirmPassword
            ) {
                theData.append("password", this.editAccountData.password);
                theData.append(
                    "password_confirmation",
                    this.editAccountData.confirmPassword
                );
            }
            // END:: APPEND GENERAL DATA

            if (this.getAuthenticatedUserData.type == "parent") {
                // START:: APPEND PARENT DATA
                let allChildrenCodes = [
                    ...this.editAccountData.studentCodes,
                    ...this.editAccountData.newStudentCodes,
                ];
                allChildrenCodes.forEach((item, index) => {
                    theData.append(
                        `studentIds[${index}][student_id]`,
                        item.student_code
                    );
                });
                // END:: APPEND PARENT DATA

                // START:: SEND REQUEST
                this.$axios
                    .post("parent/update_profile", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isWaitingRequest = false;

                        this.$iziToast.success({
                            timeout: 2000,
                            message: res.data.message,
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });

                        this.editAccountData.newStudentCodes = [];

                        // START:: LOCAL STORAGE SET REGISTERED USER DATA
                        this.setAuthenticatedUserData({
                            type: localStorage.getItem(
                                "elmo3lm_elmosa3d_user_type"
                            ),
                            token: localStorage.getItem(
                                "elmo3lm_elmosa3d_user_token"
                            ),
                            avatar: res.data.data.profile_image,
                        });
                        // END:: LOCAL STORAGE SET REGISTERED USER DATA

                        // START:: GET UPDATED PROFILE DATA
                        this.getProfileData();
                        // END:: GET UPDATED PROFILE DATA
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;

                        this.$iziToast.error({
                            timeout: 2000,
                            message: err.response.data.message,
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });
                        return;
                    });
                // END:: SEND REQUEST
            } else if (this.getAuthenticatedUserData.type == "student") {
                // START:: APPEND STUDENT DATA
                theData.append(
                    "degree_id",
                    this.editAccountData.studyDegree.id
                );
                theData.append(
                    "academic_year_id",
                    this.editAccountData.studyYear.id
                );
                // END:: APPEND STUDENT DATA

                // START:: SEND REQUEST
                this.$axios
                    .post("student/update_profile", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isWaitingRequest = false;

                        this.$iziToast.success({
                            timeout: 2000,
                            message: res.data.message,
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });

                        // START:: LOCAL STORAGE SET REGISTERED USER DATA
                        this.setAuthenticatedUserData({
                            type: localStorage.getItem(
                                "elmo3lm_elmosa3d_user_type"
                            ),
                            token: localStorage.getItem(
                                "elmo3lm_elmosa3d_user_token"
                            ),
                            avatar: res.data.data.profile_image,
                        });
                        // END:: LOCAL STORAGE SET REGISTERED USER DATA

                        // START:: GET UPDATED PROFILE DATA
                        this.getProfileData();
                        // END:: GET UPDATED PROFILE DATA
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;

                        this.$iziToast.error({
                            timeout: 2000,
                            message: err.response.data.message,
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });
                        return;
                    });
                // if( localStorage.getItem("elmo3lm_elmosa3d_user_token") ) {
                // }
                // END:: SEND REQUEST
            } else if (this.getAuthenticatedUserData.type == "teacher") {
                // START:: SEND REQUEST
                this.$axios
                    .post("teacher/update_profile", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isWaitingRequest = false;

                        this.$iziToast.success({
                            timeout: 2000,
                            message: res.data.message,
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });

                        // START:: LOCAL STORAGE SET REGISTERED USER DATA
                        this.setAuthenticatedUserData({
                            type: localStorage.getItem(
                                "elmo3lm_elmosa3d_user_type"
                            ),
                            token: localStorage.getItem(
                                "elmo3lm_elmosa3d_user_token"
                            ),
                            avatar: res.data.data.profile_image,
                        });
                        // END:: LOCAL STORAGE SET REGISTERED USER DATA

                        // START:: GET UPDATED PROFILE DATA
                        this.getProfileData();
                        // END:: GET UPDATED PROFILE DATA
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;

                        this.$iziToast.error({
                            timeout: 2000,
                            message: err.response.data.message,
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });
                        return;
                    });
                // END:: SEND REQUEST
            }
        },
        // END:: EDIT PROFILE DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCountries();
        this.getStudyDegrees();
        this.getProfileData();
        // END:: FIRE METHODS
    },
};
</script>
