<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="account_form_wrapper fadeIn" v-else>
            <form
                class="mt-4"
                @submit.prevent="validateCareerInfoForm"
                autocomplete="off"
            >
                <div class="row justify-content-center">
                    <!-- START:: LICENCE NUMBER INPUT GROUP -->
                    <div class="col-lg-6 my-3">
                        <div class="input_wrapper">
                            <input
                                type="number"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.licence_number')"
                                v-model.trim="careerInfoData.licenceNumber"
                            />
                        </div>
                    </div>
                    <!-- END:: LICENCE NUMBER INPUT GROUP -->

                    <!-- START:: FACULTY INPUT GROUP -->
                    <div class="col-lg-6 my-3">
                        <div class="input_wrapper">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.faculty')"
                                v-model.trim="careerInfoData.faculty"
                            />
                        </div>
                    </div>
                    <!-- END:: FACULTY INPUT GROUP -->

                    <!-- START:: STUDY DEGREE INPUT GROUP -->
                    <!-- <div class="col-lg-6 my-3">
                        <div class="input_wrapper select_wrapper">
                            <multiselect
                                v-if="studyDegreesData"
                                v-model="careerInfoData.studyDegree"
                                :open-direction="'bottom'"
                                track-by="name"
                                label="name"
                                :placeholder="$t('PLACEHOLDERS.study_degree')"
                                :options="studyDegreesData"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="
                                    getSpecializations(
                                        careerInfoData.studyDegree.id
                                    )
                                "
                            >
                            </multiselect>
                        </div>
                    </div> -->
                    <!-- END:: STUDY DEGREE INPUT GROUP -->

                    <!-- START:: TEACHER SPECIALTY INPUT GROUP -->
                    <!-- <div class="col-lg-6 my-3">
                        <div class="input_wrapper select_wrapper">
                            <multiselect
                                v-model="careerInfoData.teacherSpecialty"
                                :open-direction="'bottom'"
                                track-by="name"
                                label="name"
                                :placeholder="
                                    $t('PLACEHOLDERS.teacher_specialty')
                                "
                                :options="specializationsData"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                :multiple="true"
                                :allowEmpty="true"
                                :disabled="true"
                            >
                            </multiselect>
                        </div>
                    </div> -->
                    <!-- END:: TEACHER SPECIALTY  INPUT GROUP -->

                    <div class="col-12 p- 0 form_repeater_wrapper">
                        <div 
                            class="row"
                            v-for=" degree in careerInfoData.studyDegree " 
                            :key=" degree.id "
                        >
                            <!-- START:: STUDY DEGREE INPUT GROUP -->
                            <div class="col-lg-6 my-3">
                                <div class="input_wrapper select_wrapper">
                                    <multiselect
                                        v-if="studyDegreesData"
                                        v-model="degree.degree"
                                        :open-direction="'bottom'"
                                        track-by="name"
                                        label="name"
                                        :placeholder="$t('PLACEHOLDERS.study_degree')"
                                        :options="studyDegreesData"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        :disabled="true"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <!-- END:: STUDY DEGREE INPUT GROUP -->

                            <!-- START:: TEACHER SPECIALTY INPUT GROUP -->
                            <div class="col-lg-6 my-3">
                                <div class="input_wrapper select_wrapper form_repeater">
                                    <multiselect
                                        v-if="specializationsData"
                                        v-model="degree.specializations"
                                        :open-direction="'bottom'"
                                        track-by="name"
                                        label="name"
                                        :placeholder="$t('PLACEHOLDERS.teacher_specialty')"
                                        :options="specializationsData"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        :multiple="true"
                                        :allowEmpty="true"
                                        :disabled="true"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <!-- END:: TEACHER SPECIALTY  INPUT GROUP -->
                        </div>
                    </div>

                    <!-- START:: FACULTY INPUT GROUP -->
                    <!-- <div class="col-lg-12 my-3">
                        <div class="input_wrapper">
                            <textarea
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.note')"
                                v-model.trim="careerInfoData.note"
                                rows="3"
                            ></textarea>
                        </div>
                    </div> -->
                    <!-- END:: FACULTY INPUT GROUP -->

                    <!-- START:: TOGGLE STRENGTHENING REQUESTS INPUT GROUP -->
                    <div class="col-12">
                        <div class="input_wrapper checkbox_input my-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <div class="text-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="activate_strengthening_requests"
                                            v-model="
                                                careerInfoData.activateStrengtheningRequests
                                            "
                                        />
                                        {{
                                            $t(
                                                "PLACEHOLDERS.activate_strengthening_requests"
                                            )
                                        }}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- END:: TOGGLE STRENGTHENING REQUESTS INPUT GROUP -->

                    <!-- START:: SUBMIT BUTTON WRAPPER -->
                    <div class="col-lg-7 my-3">
                        <div class="btn_wrapper">
                            <button class="w-100 mt-0">
                                {{ $t("BUTTONS.edit_info") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequest"
                                ></span>
                            </button>
                        </div>
                    </div>
                    <!-- END:: SUBMIT BUTTON WRAPPER -->
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "CareerInfoForm",
    components: {
        MainLoader,
    },
    computed: {
        // START:: VUEX GET API GETS DATA
        ...mapGetters("ApiGetsModule", [
            "studyDegreesData",
            "specializationsData",
        ]),
        // END:: VUEX GET API GETS DATA
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: CAREER INFO DATA
            careerInfoData: {
                licenceNumber: null,
                studyDegree: null,
                teacherSpecialty: [],
                faculty: null,
                note: null,
                activateStrengtheningRequests: 0,
            },
            isLoading: false,
            // END:: CAREER INFO DATA
        };
    },

    methods: {
        // START:: VUEX API GETS ACTIONS
        ...mapActions("ApiGetsModule", [
            "getStudyDegrees",
            "getSpecializations",
        ]),
        // END:: VUEX API GETS ACTIONS

        // START: GET STUDENT PROFILE DATA
        getProfileData() {
            this.isLoading = true;
            this.$axios
                .get("teacher/profile", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    // START:: SET PROFILE CAREER INFO DATA
                    this.careerInfoData.licenceNumber = res.data.data.professional_license_number;
                    this.careerInfoData.studyDegree = res.data.data.degrees;
                    // this.careerInfoData.teacherSpecialty = res.data.data.specializations;
                    this.careerInfoData.faculty = res.data.data.university;
                    this.careerInfoData.note = res.data.data.desc;
                    this.careerInfoData.activateStrengtheningRequests = res.data.data.special_course_request;
                    // START:: SET PROFILE CAREER INFO DATA

                    // START:: GET  SELECTED SPECIALIZATIONS
                    // this.getSpecializations(res.data.data.degree.id);
                    // END:: GET  SELECTED SPECIALIZATIONS
                    this.isLoading = false;
                });
        },
        // END: GET STUDENT PROFILE DATA

        // START:: VALIDATE CAREER INFO FORM
        validateCareerInfoForm() {
            this.isWaitingRequest = true;

            if (!this.careerInfoData.licenceNumber) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.licence_number"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (this.careerInfoData.teacherSpecialty.length == 0) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.teacher_specialty"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.careerInfoData.studyDegree) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.study_degree"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.careerInfoData.faculty) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.faculty"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.careerInfoData.note) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.note"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitEditCareerInfoForm();
            }
        },
        // END:: VALIDATE CAREER INFO FORM

        // START:: SUBMIT CAREER INFO FORM
        submitEditCareerInfoForm() {
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND TEACHER DATA
            // theData.append("degree_id", this.careerInfoData.studyDegree.id);
            theData.append("university", this.careerInfoData.faculty);

            // this.careerInfoData.teacherSpecialty.forEach((element, index) => {
            //     theData.append(
            //         `specializationIds[${index}][specialization_id]`,
            //         element.id
            //     );
            // });

            theData.append(
                "professional_license_number",
                this.careerInfoData.licenceNumber
            );
            theData.append("desc", this.careerInfoData.note);
            theData.append(
                "special_course_request",
                +this.careerInfoData.activateStrengtheningRequests
            );
            // END:: APPEND TEACHER DATA

            // START:: SEND REQUEST
            this.$axios
                .post("teacher/update_profile", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isWaitingRequest = false;

                    this.$iziToast.success({
                        timeout: 2000,
                        message: res.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });

                    // START:: GET UPDATED PROFILE DATA
                    this.getProfileData();
                    // END:: GET UPDATED PROFILE DATA
                })
                .catch((err) => {
                    this.isWaitingRequest = false;

                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT CAREER INFO FORM
    },

    mounted() {
        // START:: FIRE METHODS
        this.getProfileData();
        this.getStudyDegrees();
        // END:: FIRE METHODS
    },
};
</script>
