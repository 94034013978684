<template>
    <div class="teacher_favs_wrapper">
        <div class="container">
            <!-- START:: MAIN LOADER -->
            <MainLoader v-if="isLoading" />
            <!-- END:: MAIN LOADER -->
            <div class="row fadeIn" v-else>
                <!-- START:: REGISTERED COURSE CARD -->
                <div
                    v-for="course in favItems"
                    :key="course.id"
                    class="col-lg-3 my-3"
                >
                    <!-- START:: COURSE CARD -->
                    <router-link
                        :to="`/teacher/course-details/online/${course.dashboard_subject.id}`"
                    >
                        <ImagedCard>
                            <template v-slot:card_image>
                                <img
                                    :src="course.dashboard_subject.image"
                                    alt="Course Category Image"
                                />
                            </template>

                            <template v-slot:card_text>
                                <div class="wrapper">
                                    <p class="course_name">
                                        {{
                                            course.dashboard_subject
                                                .subject_name.subject_name
                                        }}
                                    </p>
                                </div>

                                <div
                                    class="
                                        wrapper
                                        w-100
                                        d-flex
                                        justify-content-between
                                    "
                                >
                                    <span class="rate">
                                        <i class="fas fa-star"></i>
                                        <span>
                                            {{
                                                course.dashboard_subject
                                                    .avg_rate
                                            }}
                                        </span>
                                    </span>
                                    <p class="price">
                                        {{ course.dashboard_subject.price }}
                                        {{ course.dashboard_subject.currency }}
                                    </p>
                                </div>
                            </template>
                        </ImagedCard>
                    </router-link>
                    <!-- END:: COURSE CARD -->
                </div>
                <!-- END:: REGISTERED COURSE CARD -->
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "TeacherFavorites",

    components: {
        ImagedCard,
        MainLoader,
    },

    computed: {},

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE
            favItems: "",
        };
    },
    methods: {
        getFav() {
            this.isLoading = true;
            this.$axios
                .get(`user/favourite`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isLoading = false;
                    this.favItems = res.data.data;
                });
        },
    },
    mounted() {
        this.getFav();
    },
};
</script>
