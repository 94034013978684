<template>
    <footer>
        <div class="container">
            <div class="footer_content">
                <div class="row justify-content-between">
                    <div class="col-lg-3 my-3">
                        <!-- START:: FOOTER LOGO -->
                        <div class="logo_wrapper">
                            <router-link to="/">
                                <img
                                    src="../../assets/media/logo/logo.svg"
                                    alt="Al-Moalem Al-Mosaed App Logo"
                                    width="160"
                                    height="145"
                                />
                            </router-link>
                            <!-- <div class="text">
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس
                                المساحة، لقد تم توليد هذا
                            </div> -->
                        </div>
                        <!-- END:: FOOTER LOGO -->
                    </div>

                    <div class="col-lg-5 my-3">
                        <div class="links_wrapper">
                            <div class="wrapper">
                                <h4>{{ $t("TITLES.futurezoon") }}</h4>
                                <ul class="footer_links_wrapper">
                                    <li class="footer_link">
                                        <router-link to="/">
                                            {{ $t("FOOTER.home") }}
                                        </router-link>
                                    </li>

                                    <li class="footer_link">
                                        <router-link to="/about-us">
                                            {{ $t("FOOTER.about_us") }}
                                        </router-link>
                                    </li>

                                    <li
                                        class="footer_link"
                                        v-if="
                                            getAuthenticatedUserData.type ==
                                            'student'
                                        "
                                    >
                                        <router-link to="/store">
                                            {{ $t("FOOTER.store") }}
                                        </router-link>
                                    </li>

                                    <li
                                        class="footer_link"
                                        v-if="
                                            getAuthenticatedUserData.type ==
                                            'student'
                                        "
                                    >
                                        <router-link
                                            to="/course-section/distance-learning-lessons"
                                        >
                                            {{ $t("FOOTER.subjects") }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>

                            <div class="wrapper">
                                <h4>{{ $t("TITLES.help") }}</h4>
                                <ul class="footer_links_wrapper">
                                    <li class="footer_link">
                                        <router-link to="/privacy-policy">
                                            {{ $t("FOOTER.privacy") }}
                                        </router-link>
                                    </li>

                                    <li class="footer_link">
                                        <router-link to="/terms">
                                            {{ $t("FOOTER.terms") }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 my-3">
                        <div class="app_platforms">
                            <div class="social_media_links_wrapper">
                                <a
                                    :href="homeData.contacts.social.instagram"
                                    target="_blank"
                                >
                                    <i class="fab fa-instagram"></i>
                                </a>

                                <a
                                    :href="homeData.contacts.social.twitter"
                                    target="_blank"
                                >
                                    <i class="fab fa-twitter"></i>
                                </a>

                                <a
                                    :href="homeData.contacts.social.facebook"
                                    target="_blank"
                                >
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a
                                    :href="homeData.contacts.social.snapchat"
                                    target="_blank"
                                >
                                    <i class="fab fa-snapchat"></i>
                                </a>
                                <a
                                    :href="homeData.contacts.social.youtube"
                                    target="_blank"
                                >
                                    <i class="fab fa-youtube"></i>
                                </a>
                            </div>

                            <h4>{{ $t("TITLES.find_out_app") }}</h4>

                            <div class="download_platforms">
                                <a
                                    :href="homeData.contacts.link_ios"
                                    target="_blank"
                                >
                                    <img
                                        src="../../assets/media/stores_logos/apple-store.svg"
                                        alt="Play Store"
                                        width="125"
                                        height="90"
                                    />
                                </a>

                                <a
                                    :href="homeData.contacts.link_android"
                                    target="_blank"
                                >
                                    <img
                                        src="../../assets/media/stores_logos/google-play.svg"
                                        alt="Google Play"
                                        width="125"
                                        height="90"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy_rights_wrapper">
            <p>
                {{ $t("FOOTER.allRightsReserved") }}
                {{ $t("TITLES.futurezoon") }} &copy;{{ currentYear }}
            </p>
        </div>
    </footer>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "TheFooter",

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: CURRENT YEAR DATA
            currentYear: new Date().getFullYear(),
            // END:: CURRENT YEAR DATA

            // START:: HOME DATA
            homeData: null,
            // END:: HOME DATA
        };
    },

    methods: {
        // START:: GET HOME PAGE DATA
        getHomeData() {
            // START:: GET TEACHER HOME DATA
            this.isLoading = true;
            var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
            if (userType == "teacher") {
                this.$axios
                    .get("teacher/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            } else if (userType == "student") {
                this.$axios
                    .get("student/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            } else if (userType == "parent") {
                this.$axios
                    .get("parent/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            } else {
                this.$axios
                    .get("visitor/home", {
                        headers: {
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            }
            // END:: GET TEACHER HOME DATA
        },
        // END:: GET HOME PAGE DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getHomeData();
        // END:: FIRE METHODS
    },
};
</script>
