<template :key="$route.path">
    <div>
        <MainLoader v-if="isLoading" />
        <div class="teachers_wrapper fadeIn" v-else>
            <ImageHeader>
                <template>
                    <div class="header_container pb-5">
                        <div class="container">
                            <div class=" d-flex">
                            <!-- Move the image before the title wrapper -->
                            <div class="header_title_wrapper mt-6">
                                <img src="./../../assets/media/images/pi.png" alt="teachers"
                                class="w-50" />
                                <h1 class="">
                                    {{ $t("TITLES.teachers") }}
                                </h1>
                            </div>
                            <!-- <img src="./../../assets/media/illustrations/header.png" alt="teachers" 
                                style="width: 18%;height: auto; margin-right: auto;" /> -->
                        </div>
                    </div>
                    </div>
                </template>
            </ImageHeader>
        </div>

        <div class="container courses_categories_wrapper">
            <div class="container">
                <div class="col-12">
                    <div class="input_wrapper position-relative">
                        <!-- Add the image icon and click event -->
                        <i class="fa fa-search fa-lg search-icon"></i>
                        <img src="@/assets/media/icons/filter.png" class="filterIcon" title="filter" @click="toggleDropdown"
                            alt="search for teacher" />
                        <input type="text" class="form-control teachersSearchInput py-3"
                            :placeholder="$t('PLACEHOLDERS.search_for_teacher')" @keydown.enter="onSearchInput" />
                        <!-- Dropdown list -->
                        <ul v-if="showDropdown" class="dropdown-list">
                            <!-- START:: SUBJECT NAME INPUT GROUP -->
                            <div class="my-3 col-12">
                                <div class="input_wrapper">
                                    <select class="form-select" v-model.trim="selectedSubjectId">
                                        <option selected disabled :value="null">
                                            {{ $t("PLACEHOLDERS.subject_name") }}
                                        </option>
                                        <option v-for="subject in subjects" :key="subject.id" :value="subject.id">
                                            {{ subject.subject_name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- END:: SUBJECT NAME INPUT GROUP -->
                            <!-- START:: SUBJECT NAME INPUT GROUP -->
                            <div class="my-3 col-12">
                                <div class="input_wrapper">
                                    <select class="form-select" v-model.trim="selectedCityId">
                                        <option selected disabled :value="null">{{ $t("PLACEHOLDERS.city") }}</option>
                                        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- END:: SUBJECT NAME INPUT GROUP -->

                            <!-- START:: SUBJECT NAME INPUT GROUP -->
                            <div class="my-3 col-12">
                                <div class="input_wrapper">
                                    <select class="form-select" v-model.trim="selectedRate">
                                        <option selected disabled :value="null">{{ $t("PLACEHOLDERS.rate") }}</option>
                                        <option>1 star</option>
                                        <option>2 stars</option>
                                        <option>3 stars</option>
                                        <option>4 stars</option>
                                        <option>5 stars</option>
                                    </select>
                                </div>
                            </div>
                            <!-- END:: SUBJECT NAME INPUT GROUP -->

                            <!-- Add your dropdown items here -->
                            <!-- Example: <li v-for="item in dropdownItems" :key="item.id">{{ item.label }}</li> -->
                            <div class="d-flex justify-content-between">
                                <div class="btn_wrapper w-50">
                                    <button class="m-3" type="submit" @click="filterTeachers">{{ $t("BUTTONS.filter")
                                    }}</button>
                                </div>
                                <button @click.prevent="resetFilters"> <i class="fas fa-trash"></i></button>
                            </div>
                        </ul>
                    </div>

                </div>
                <!-- START:: COURSES CATEGORIES CARD -->
                <div class="row mt-5" v-if="teachers.length > 0">
                    <!-- <div class="col-lg-6 my-3" v-for="teacher in teachers" :key="teacher.id" :value="id"> -->
                    <div class="col-lg-6 my-3" v-for="teacher in paginatedTeachers" :key="teacher.id">
                        <SolidCard>
                            <router-link :to="`/teacher/${teacher.id}`" class="card_content_wrapper row ml-2">
                                <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                    <img :src="teacher.profile_image" :alt="teacher.user_type" width="125" height="140" />
                                </div>
                                <div class="col mt-5">
                                    <h2 class="font-weight-bold">{{ teacher.fullname }}</h2>
                                    <div class="d-flex">
                                        <div v-for="specialization in teacher.specializations.slice(
                                            0,
                                            2
                                        )" :key="specialization.id" :value="specialization.id">
                                            <h4 class="text-secondary">
                                                {{ specialization.name }}
                                                <span v-if="teacher.specializations.length > 1" class="text-secondary">
                                                    /
                                                </span>
                                                <span v-if="teacher.specializations.length == 2" class="text-secondary">
                                                    <!-- remove the last slash -->
                                                </span>
                                            </h4>
                                        </div>
                                        <div v-if="teacher.specializations.length > 2">
                                            <h4 class="text-secondary">...</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <!-- <div class="text-center allTeacherRate py-1">
                                        <i class="fas fa-star"></i>
                                        <span> 4.5 </span>
                                    </div> -->
                                </div>
                                <h4 class="text-secondary mt-4">{{ teacher.desc }}</h4>
                            </router-link>
                        </SolidCard>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="text-center mt-4" v-else>
                    <h4 class="text-danger mt-5">{{ $t("TITLES.noTeachers") }}</h4>
                </div>
                <pagenation :last_page="totalPages" :current_page="currentPage" @pagenationClick="changePage" />
            </div>
        </div>
    </div>
</template>
  
<script>
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
import ImageHeader from "../../components/ui/ImageHeader.vue";
import SolidCard from "@/components/ui/SolidCard.vue";
import pagenation from "./../../components/ui/pagenation.vue";


export default {
    name: "CourseDetails",

    components: {
        MainLoader,
        ImageHeader,
        SolidCard,
        pagenation
    },

    data() {
        return {
            isLoading: true,
            routeName: this.$route.name,
            registeredUserType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            teachers: [],
            searchKeyword: "",
            searchCityId: null,
            searchAvgRate: null,
            searchSubjectId: null,
            currentPage: 1,
            itemsPerPage: 6,
            showDropdown: false,
            selectedCityId: null,
            selectedSubjectId: null,
            selectedRate: null,
        };
    },

    methods: {
        changePage(pageNum) {
            this.currentPage = pageNum;
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        filterTeachers() {
            this.isLoading = false;
            this.searchTeachers();
            this.showDropdown = false;
        },
        getTeachers() {
            if (this.registeredUserType == "student") {
                this.$axios
                    .get(`student/all_teachers`, {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.teachers = res.data.data;
                        console.log(this.teachers);
                    });
            }
            this.searchTeachers('');
        },

        // START:: GET CITIES NAME
        getCitiesName() {
            this.$axios
                .get(`cities`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.cities = res.data.data;
                    console.log(this.cities);
                });
        },
        // END:: GET CITIES NAME

        // START:: GET CITIES NAME
        getSubjectsNames() {
            this.$axios
                .get(`student/all-subject-name`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.subjects = res.data.data;
                    console.log(this.subjects);
                });
        },
        // END:: GET CITIES NAME

        searchTeachers(keyword) {
            if (this.registeredUserType === "student") {
                this.isLoading = true;
                this.$axios
                    .get(`student/all_teachers`, {
                        params: {
                            keyword: keyword,
                            city_id: this.selectedCityId,
                            avg_rate: this.selectedRate,
                            subject_id: this.selectedSubjectId,
                        },
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.teachers = res.data.data;
                        console.log(this.teachers);
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.error("Error fetching teachers:", error);
                    });
            }
        },

        resetFilters() {
            this.isLoading = false;
            this.selectedCityId = null;
            this.selectedRate = null;
            this.selectedSubjectId = null;
        },

        onSearchInput(event) {
            const keyword = event.target.value.trim();
            if (keyword === '' || (event.key === 'Enter' && keyword === '')) {
                this.getTeachers();
            } else {
                this.searchTeachers(keyword);
            }
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.teachers.length / this.itemsPerPage);
        },
        paginatedTeachers() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.teachers.slice(start, end);
        },
    },
    mounted() {
        this.getTeachers();
        this.getCitiesName();
        this.getSubjectsNames();
    },
};
</script>
  
<style>
.card_content_wrapper {
    background-color: var(--lighter_gray_clr);
}

.allTeacherRate {
    background-color: #f8f4dc;
    color: var(--gold_clr);
    border-radius: 20px;
    font-size: 21px;
    width: 80px;
    position: absolute;
    top: 30px;
    left: 20px;
}

.teachersSearchInput {
    background-color: #f7f7fa !important;
    border: none !important;
    border-radius: 8px !important;
    padding-right: 40px !important;
    font-size: 20px !important;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.dropdown-list {
    position: absolute;
    left: 0;
    /* top: 10px; */
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 8px;
    list-style: none;
}

.dropdown-list::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 22%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
}

.filterIcon {
    position: absolute;
    left: 20px;
}

.filterIcon:hover {
    cursor: pointer;
}</style>