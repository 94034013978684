export default {
    // START:: SET CHATS LIST
    setChatsList(state, payload) {
        state.chatsList = payload;
    },
    // END:: SET CHATS LIST

    // START:: SET CHATS LIST
    setSingleChatData(state, payload) {
        state.singleChatData = payload;
    },
    // END:: SET CHATS LIST
};
