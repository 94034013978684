<template>
    <div class="edit_course_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.edit_course_info") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.edit_course_info") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <!-- START:: PAGE CONTENT -->
        <div class="edit_course_page_content py-5">
            <div class="container">
                <div class="add_subject_steps_wrapper">
                    <div class="add_subject_steps_card_wrapper">
                        <div class="container">
                            <!-- START:: FORM -->
                            <form @submit.prevent="validateEditCourseForm">
                                <div class="row justify-content-center">
                                    <!-- START:: SUBJECT UPLOADED INPUT -->
                                    <div class="col-12 my-3">
                                        <div class="single_image_input_wrapper">
                                            <div class="wrapper">
                                                <label for="user_avatar">
                                                    <!-- <i class="fal fa-camera-alt"></i> -->
                                                </label>
                                                <img
                                                    class="user_avatar"
                                                    :src="
                                                        editCourseData.image
                                                            .path
                                                    "
                                                    alt="User Avatar"
                                                    width="150"
                                                    height="150"
                                                />
                                                <input
                                                    type="file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    class="form-control"
                                                    id="user_avatar"
                                                    :placeholder="
                                                        $t(
                                                            'PLACEHOLDERS.student_code'
                                                        )
                                                    "
                                                    @change="
                                                        selectUploadedImage
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END:: SUBJECT UPLOADED INPUT -->





                                    <!-- START:: STUDY DEGREE INPUT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('PLACEHOLDERS.study_degree')"
                                                v-model="editCourseData.degree.degree.name"
                                                disabled
                                            />
                                            <!-- <select
                                                class="form-select"
                                                v-model="editCourseData.degree.id"
                                                @change="
                                                    getSubjectsName(addSubject.degree.degree.id); 
                                                    getStudyYears(addSubject.degree.degree.id);
                                                    addSubject.name = null;
                                                    addSubject.studyYear = null;
                                                "
                                            >
                                                <option selected disabled :value="null">
                                                    {{ $t("PLACEHOLDERS.study_degree") }}
                                                </option>
                                                <option
                                                    v-for="degree in studyDegrees"
                                                    :key="degree.id"
                                                    :value="degree"
                                                >
                                                    {{ degree.degree.name }}
                                                </option>
                                            </select> -->
                                        </div>
                                    </div>
                                    <!-- END:: STUDY DEGREE INPUT GROUP -->

                                    <!-- START:: SUBJECT NAME INPUT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <select
                                                class="form-select"
                                                v-model="editCourseData.name"
                                            >
                                                <option selected disabled :value="null">
                                                    {{ $t("PLACEHOLDERS.subject_name") }}
                                                </option>
                                                <option
                                                    v-for="subjectName in subjectsNames"
                                                    :key="subjectName.data.id"
                                                    :value="subjectName.data.id"
                                                >
                                                    {{ subjectName.data.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- END:: SUBJECT NAME INPUT GROUP -->

                                    <!-- START:: STUDY YEAR INPUT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <select
                                                class="form-select"
                                                v-model="editCourseData.studyYear"
                                            >
                                                <option selected disabled :value="null">
                                                    {{ $t("PLACEHOLDERS.study_year") }}
                                                </option>
                                                <option
                                                    v-for="year in studyYears"
                                                    :key="year.id"
                                                    :value="year.id"
                                                >
                                                    {{ year.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- END:: STUDY YEAR INPUT GROUP -->





                                    <!-- START:: SUBJECT CATEGORY INPUT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <select
                                                class="form-select"
                                                v-model.trim="editCourseData.subjectCat"
                                            >
                                                <option selected disabled :value="null">
                                                    {{ $t("PLACEHOLDERS.subject_cat") }}
                                                </option>
                                                <option
                                                    v-for="cat in subjectCats"
                                                    :key="cat.id"
                                                    :value="cat.id"
                                                >
                                                    {{ cat.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- END:: SUBJECT CATEGORY INPUT GROUP -->

                                    <!-- START:: LANGUAGE USED SELECT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <select
                                                class="form-select"
                                                v-model.trim="
                                                    editCourseData.language
                                                "
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    :value="null"
                                                >
                                                    {{
                                                        $t(
                                                            "PLACEHOLDERS.used_language"
                                                        )
                                                    }}
                                                </option>
                                                <option value="ar">
                                                    {{
                                                        $t(
                                                            "PLACEHOLDERS.arabic"
                                                        )
                                                    }}
                                                </option>
                                                <option value="en">
                                                    {{
                                                        $t(
                                                            "PLACEHOLDERS.english"
                                                        )
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- END:: LANGUAGE USED SELECT GROUP -->

                                    <!-- START:: DATEPICKER INPUT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <a-date-picker
                                            :placeholder="
                                                $t('PLACEHOLDERS.start_date')
                                            "
                                            v-model="editCourseData.start_date"
                                            format="YYYY-MM-DD"
                                            valueFormat="YYYY-MM-DD"
                                        />
                                    </div>
                                    <!-- END:: DATEPICKER INPUT GROUP -->

                                    <!-- START:: HOUR COUNT INPUT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <input
                                                type="number"
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'PLACEHOLDERS.hour_count'
                                                    )
                                                "
                                                v-model.trim="
                                                    editCourseData.hour_count
                                                "
                                            />
                                        </div>
                                    </div>
                                    <!-- END:: HOUR COUNT INPUT GROUP -->

                                    <!-- START:: TYPE SELECT GROUP -->
                                    <div class="col-md-6 my-3">
                                        <div class="input_wrapper">
                                            <select
                                                class="form-select"
                                                v-model.trim="
                                                    editCourseData.type_subject
                                                "
                                                disabled
                                            >
                                                <option
                                                    disabled
                                                    selected
                                                    value="null"
                                                >
                                                    {{
                                                        $t(
                                                            "PLACEHOLDERS.subject_type"
                                                        )
                                                    }}
                                                </option>
                                                <option value="online">
                                                    {{
                                                        $t(
                                                            "PLACEHOLDERS.online"
                                                        )
                                                    }}
                                                </option>
                                                <option value="record">
                                                    {{
                                                        $t(
                                                            "PLACEHOLDERS.record"
                                                        )
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- END:: TYPE SELECT GROUP -->

                                    <!-- START:: PRICE INPUT GROUP -->
                                    <div
                                        class="my-3 col-md-6"
                                        v-if="
                                            editCourseData.type_subject ==
                                            'online'
                                        "
                                    >
                                        <div class="input_wrapper">
                                            <input
                                                type="number"
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'PLACEHOLDERS.price_subject'
                                                    )
                                                "
                                                v-model.trim="
                                                    editCourseData.price_subject
                                                "
                                            />
                                        </div>
                                    </div>
                                    <!-- END:: PRICE INPUT GROUP -->

                                    <!-- START:: DESCRIPTION GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <textarea
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'PLACEHOLDERS.desc_subject'
                                                    )
                                                "
                                                v-model.trim="
                                                    editCourseData.desc_subject
                                                "
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- END:: DESCRIPTION GROUP -->

                                    <!-- START:: GOALS SUBJECT GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <textarea
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'PLACEHOLDERS.goals_subject'
                                                    )
                                                "
                                                v-model.trim="
                                                    editCourseData.goals_subject
                                                "
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- END:: GOALS SUBJECT GROUP -->

                                    <!-- START:: ENRICHMENTS GROUP -->
                                    <div class="my-3 col-md-6">
                                        <div class="input_wrapper">
                                            <textarea
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'PLACEHOLDERS.course_enrichments'
                                                    )
                                                "
                                                v-model.trim="
                                                    editCourseData.course_enrichments
                                                "
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- END:: ENRICHMENTS GROUP -->

                                    <!-- START:: OFFER CHICK INPUT -->
                                    <div class="my-3 col-12">
                                        <div class="input_wrapper switch_wrapper" dir="ltr" v-if="editCourseData.type_subject == 'online'">
                                            <h5 class="switch_label"> يوجد عروض علي المادة؟ </h5>
                                            <v-switch
                                                v-model="editCourseData.has_offer"
                                                color="success"
                                                hide-details
                                            >
                                            </v-switch>
                                        </div>
                                    </div>
                                    <!-- END:: OFFER CHICK INPUT -->

                                    <!-- START:: OFFER DESC -->
                                    <div 
                                        class="my-3 col-md-6" 
                                        v-show="editCourseData.has_offer"
                                    >
                                        <div class="input_wrapper">
                                            <textarea
                                                class="form-control"
                                                :placeholder="
                                                    $t('PLACEHOLDERS.offer_desc')
                                                "
                                                v-model.trim="editCourseData.offer_desc"
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- END:: OFFER DESC -->

                                    <!-- START:: OFFER AMOUNT -->
                                    <div 
                                        class="my-3 col-md-6" 
                                        v-show="editCourseData.has_offer"
                                    >
                                        <div class="input_wrapper">
                                            <input
                                                type="number"
                                                class="form-control"
                                                :placeholder="$t('PLACEHOLDERS.offer_amount')"
                                                v-model.trim="editCourseData.offer_amount"
                                            />
                                        </div>
                                    </div>
                                    <!-- END:: OFFER AMOUNT -->

                                    <!-- START:: MULTI UPLOAD FILES GROUP -->
                                    <div class="my-3 col-12">
                                        <div class="page_small_title">
                                            <h3>
                                                {{ $t("TITLES.attachments") }}
                                            </h3>
                                        </div>

                                        <div
                                            class="
                                                input_wrapper
                                                multiple_upload_file
                                            "
                                        >
                                            <div class="file_preview_wrapper">
                                                <div
                                                    v-for="attachment in editCourseData.attachmentsFiles"
                                                    :key="attachment.id"
                                                    class="file_preview_card"
                                                >
                                                    <div class="card_overlay">
                                                        <button
                                                            type="button"
                                                            class="
                                                                remove_preview_card_btn
                                                            "
                                                            @click="
                                                                removePreviewCard(
                                                                    attachment.id
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fal
                                                                    fa-trash-alt
                                                                "
                                                            ></i>
                                                        </button>
                                                    </div>

                                                    <span class="file_icon">
                                                        <i
                                                            class="
                                                                fal
                                                                fa-file-alt
                                                            "
                                                        ></i>
                                                    </span>

                                                    <span class="file_name">
                                                        {{
                                                            attachment.file_name
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                class="label_and_input_wrapper"
                                            >
                                                <input
                                                    id="course_multiple_files"
                                                    type="file"
                                                    accept=".txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    multiple
                                                    @change="
                                                        handleUploadAttachments(
                                                            $event
                                                        )
                                                    "
                                                />
                                                <label
                                                    for="course_multiple_files"
                                                >
                                                    <i class="fal fa-plus"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- START:: MULTI UPLOAD FILES GROUP -->

                                    <!-- START:: DAYS CARDS  PREVIEW -->
                                    <div class="cards_wrapper my-5">
                                        <div class="row">
                                            <div
                                                class="
                                                    day_card
                                                    fadeIn
                                                    col-lg-6
                                                    my-3
                                                "
                                                v-for="(
                                                    single, index
                                                ) in newDayArray"
                                                :key="single.id"
                                            >
                                                <div class="page_small_title">
                                                    <h3>
                                                        {{
                                                            $t("TITLES.the_day")
                                                        }}
                                                        #{{ index + 1 }}
                                                    </h3>

                                                    <div class="btns_wrapper">
                                                        <button
                                                            type="button"
                                                            class="
                                                                edit_lesson_button
                                                            "
                                                            @click="
                                                                toggleEditLessonModal(
                                                                    single
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fal
                                                                    fa-edit
                                                                "
                                                            ></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="
                                                                delete_lesson_button
                                                            "
                                                            @click="
                                                                toggleDeleteModal(
                                                                    single
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fal
                                                                    fa-trash-alt
                                                                "
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- START:: COURSE TIME INPUT -->
                                                <div class="my-3 input_wrapper">
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_time'
                                                            )
                                                        "
                                                        v-model.trim="
                                                            single.duration
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                                <!-- END:: COURSE TIME INPUT -->

                                                <!-- START:: COURSE NAME -->
                                                <div class="my-3 input_wrapper">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_name'
                                                            )
                                                        "
                                                        v-model.trim="
                                                            single.name
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                                <!-- END:: COURSE NAME -->

                                                <!-- START:: COURSE LINK -->
                                                <div class="my-3 input_wrapper">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_link'
                                                            )
                                                        "
                                                        v-model.trim="
                                                            single.link
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                                <!-- END:: COURSE LINK -->

                                                <!-- START:: DATEPICKER INPUT GROUP -->
                                                <div
                                                    class="my-3"
                                                    v-if="
                                                        editCourseData.type_subject ==
                                                        'online'
                                                    "
                                                >
                                                    <a-date-picker
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.start_date_course'
                                                            )
                                                        "
                                                        format="YYYY-MM-DD"
                                                        valueFormat="YYYY-MM-DD"
                                                        v-model="
                                                            single.start_date
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                                <!-- END:: DATEPICKER INPUT GROUP -->

                                                <!-- START:: TIMEPICKER INPUT GROUP -->
                                                <div
                                                    class="my-3"
                                                    v-if="
                                                        editCourseData.type_subject ==
                                                        'online'
                                                    "
                                                >
                                                    <a-time-picker
                                                        class="w-100"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_start_time'
                                                            )
                                                        "
                                                        format="HH:mm"
                                                        valueFormat="HH:mm"
                                                        v-model="
                                                            single.start_time
                                                        "
                                                        readonly
                                                    />
                                                </div>
                                                <!-- END:: TIMEPICKER INPUT GROUP -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END:: DAYS CARDS PREVIEW -->

                                    <!-- START:: NEW ADDED DAYS CARDS -->
                                    <div class="cards_wrapper my-5">
                                        <div class="row">
                                            <div
                                                class="
                                                    day_card
                                                    fadeIn
                                                    col-lg-6
                                                    my-3
                                                "
                                                v-for="(
                                                    single, index
                                                ) in newAddedDay"
                                                :key="single.id"
                                            >
                                                <div class="page_small_title">
                                                    <h3>
                                                        اليوم #{{
                                                            newDayArray.length +
                                                            (index + 1)
                                                        }}
                                                    </h3>
                                                </div>
                                                <!-- START:: COURSE TIME INPUT -->
                                                <div
                                                    class="my-3 input_wrapper"
                                                >
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_time'
                                                            )
                                                        "
                                                        v-model.trim="
                                                            single.duration
                                                        "
                                                    />
                                                </div>
                                                <!-- END:: COURSE TIME INPUT -->

                                                <!-- START:: COURSE NAME -->
                                                <div class="my-3 input_wrapper">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_name'
                                                            )
                                                        "
                                                        v-model.trim="
                                                            single.name
                                                        "
                                                    />
                                                </div>
                                                <!-- END:: COURSE NAME -->

                                                <!-- START:: COURSE LINK -->
                                                <div class="my-3 input_wrapper">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_link'
                                                            )
                                                        "
                                                        v-model.trim="
                                                            single.link
                                                        "
                                                    />
                                                </div>
                                                <!-- END:: COURSE LINK -->

                                                <!-- START:: DATEPICKER INPUT GROUP -->
                                                <div
                                                    class="my-3"
                                                    v-if="
                                                        editCourseData.type_subject ==
                                                        'online'
                                                    "
                                                >
                                                    <a-date-picker
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.start_date_course'
                                                            )
                                                        "
                                                        format="YYYY-MM-DD"
                                                        valueFormat="YYYY-MM-DD"
                                                        v-model="
                                                            single.start_date
                                                        "
                                                    />
                                                </div>
                                                <!-- END:: DATEPICKER INPUT GROUP -->

                                                <!-- START:: TIMEPICKER INPUT GROUP -->
                                                <div
                                                    class="my-3"
                                                    v-if="
                                                        editCourseData.type_subject ==
                                                        'online'
                                                    "
                                                >
                                                    <a-time-picker
                                                        class="w-100"
                                                        :placeholder="
                                                            $t(
                                                                'PLACEHOLDERS.course_start_time'
                                                            )
                                                        "
                                                        format="HH:mm"
                                                        valueFormat="HH:mm"
                                                        v-model="
                                                            single.start_time
                                                        "
                                                    />
                                                </div>
                                                <!-- END:: TIMEPICKER INPUT GROUP -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END:: NEW ADDED DAYS CARDS -->

                                    <!-- START:: ADD BUTTON WRAPPER -->
                                    <div class="col-lg-7 my-3">
                                        <div
                                            class="
                                                btn_wrapper
                                                solid
                                                text-center
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="w-50 mt-0 add_day_btn"
                                                @click="addNewDay"
                                            >
                                                {{ $t("BUTTONS.add_day") }}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- END:: ADD BUTTON WRAPPER -->

                                    <!-- START:: SUBMIT BUTTON WRAPPER -->
                                    <div class="col-lg-7 my-3">
                                        <div class="btn_wrapper text-center">
                                            <button class="w-50 mt-0">
                                                {{ $t("BUTTONS.save") }}
                                                <span
                                                    class="btn_loader"
                                                    v-if="isWaitingRequest"
                                                ></span>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- END:: SUBMIT BUTTON WRAPPER -->
                                </div>
                            </form>
                            <!-- END:: FORM -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END:: PAGE CONTENT -->

        <!-- START:: EDIT LESSON MODAL -->
        <EditLessonModal
            :courseType="editCourseData.type_subject"
            :selectedLesson="selectedLessonToEdit"
            :modalApperanceData="editLessonModalIsOpen"
            @controleModalApperance="toggleEditLessonModal"
            @getDetailsAfterEdit="getCourseDetails"
        />
        <!-- END:: EDIT LESSON MODAL -->

        <!-- START:: DELETE MODAL -->
        <DeleteModal
            :targetElement="'lesson'"
            :selectedLesson="selectedLessonToDelete"
            :modalApperanceData="deleteModalIsOpen"
            @controleModalApperance="toggleDeleteModal"
            @getDetailsAfterDelete="getCourseDetails"
        />
        <!-- END:: DELETE MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING MODALS
import EditLessonModal from "../../components/modals/EditLessonModal.vue";
import DeleteModal from "../../components/modals/DeleteModal.vue";
// END:: IMPORTING MODALS

import image_path from "../../assets/media/images/upload_image.png";

export default {
    name: "editCourse",

    components: {
        Breadcrumb,
        EditLessonModal,
        DeleteModal,
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: MODALS HANDLING DATA
            editLessonModalIsOpen: false,
            deleteModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SELECTIONS DATA
            studyDegrees: null,
            subjectsNames: null,
            studyYears: null,
            subjectCats: null,
            // END:: SELECTIONS DATA

            // START:: ADD SUBJECT DATA
            editCourseData: {
                image: {
                    path: image_path,
                    file: null,
                },
                degree: null,
                name: null,
                studyYear: null,
                subjectCat: null,
                language: null,
                type_subject: null,
                start_date: null,
                hour_count: null,
                price_subject: null,
                desc_subject: null,
                goals_subject: null,
                course_enrichments: null,
                attachmentsFiles: [],
                has_offer: true,
                offer_desc: true,
                offer_amount: true,
            },
            // END:: ADD SUBJECT DATA
            previewVisible: false,
            previewImage: "",
            fileList: [],

            // START:: SELECTED LESSON
            selectedLessonToEdit: null,
            selectedLessonToDelete: null,
            // END:: SELECTED LESSON

            // START:: ADD SUBJECT DATA
            newDayArray: [
                {
                    id: 1,
                    name: null,
                    link: null,
                    duration: null,
                    start_date: null,
                    start_time: null,
                },
            ],
            // END:: ADD SUBJECT DATA

            // START:: NEW ADDED DAY DATA
            newAddedDay: [],
            // END:: NEW ADDED DAY DATA
        };
    },

    methods: {
        // START:: TOGGLE EDIT LESSON MODAL METHOD
        toggleEditLessonModal(item) {
            this.editLessonModalIsOpen = !this.editLessonModalIsOpen;
            this.selectedLessonToEdit = item;
        },
        // END:: TOGGLE EDIT LESSON MODAL METHOD

        // START:: TOGGLE DELETE MODAL METHOD
        toggleDeleteModal(item) {
            this.deleteModalIsOpen = !this.deleteModalIsOpen;
            this.selectedLessonToDelete = item;
        },
        // END:: TOGGLE DELETE MODAL METHOD

        // START:: GET DEGREES
        getStudyDegrees () {
            this.$axios
                .get("teacher/all_degrees", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.studyDegrees = res.data.data;
                });
        },
        // END:: GET DEGREES

        // START:: GET SUBJECTS NAME
        getSubjectsName(id) {
            this.$axios
                .get(`teacher/all-subject-name/${id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    console.log("Names ==>", res.data.data);
                    this.subjectsNames = res.data.data;
                });
        },
        // END:: GET SUBJECTS NAME

        // START:: GET STUDY YEAR
        getStudyYears(id) {
            this.$axios
                .get(`teacher/academic_years/${id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.studyYears = res.data.data;
                });
        },
        // END:: GET STUDY YEAR

        // START:: GET STUDY YEAR
        getSubjectCats() {
            this.$axios
                .get("teacher/categories", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.subjectCats = res.data.data;
                });
        },
        // END:: GET STUDY YEAR

        // START:: GET COURSE DETAILS
        getCourseDetails() {
            this.$axios
                .get(`teacher/subject/show/${this.$route.params.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isLoading = false;

                    // START:: SET COURSE DATA
                    this.editCourseData.image.path = res.data.data.image;
                    this.editCourseData.degree = res.data.data.degree;
                    this.editCourseData.name = res.data.data.degree.specializations[0].id;
                    this.editCourseData.studyYear = res.data.data.academic_year.id;
                    this.editCourseData.subjectCat = res.data.data.category.id;
                    this.editCourseData.language = res.data.data.lang;
                    this.editCourseData.start_date = res.data.data.start_date;
                    this.editCourseData.hour_count = res.data.data.num_of_hour;
                    this.editCourseData.type_subject = res.data.data.subject_kind;
                    this.editCourseData.price_subject = res.data.data.price;
                    this.editCourseData.offer_desc = res.data.data.offer_desc;
                    this.editCourseData.offer_amount = res.data.data.discount_percentage;
                    this.editCourseData.desc_subject = res.data.data.desc;
                    this.editCourseData.goals_subject = res.data.data.objectives;
                    this.editCourseData.course_enrichments = res.data.data.enriching;
                    this.editCourseData.attachmentsFiles = res.data.data.attachments;
                    this.newDayArray = res.data.data.listens;
                    // END:: SET COURSE DATA

                    this.getSubjectsName(res.data.data.degree.degree.id);
                    this.getStudyYears(res.data.data.degree.degree.id);
                });
        },
        // END:: GET COURSE DETAILS

        // START:: VALIDATE STEP ONE
        validateEditCourseForm() {
            this.isWaitingRequest = true;
            if (!this.editCourseData.name) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.name_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            }  else if (!this.editCourseData.subjectCat) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.subject_cat"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.language) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.language"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.type_subject) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.type_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.start_date) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.start_date"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.hour_count) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.hour_count"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (
                this.editCourseData.type_subject == "online" &&
                !this.editCourseData.price_subject
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.price_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.desc_subject) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.desc_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.goals_subject) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.goals_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.editCourseData.course_enrichments) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.course_enrichments"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitEditCourseForm();
            }
        },
        // END:: VALIDATE STEP ONE

        // START:: SUBMIT EDIT COURSE FORM
        submitEditCourseForm() {
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND EDIT COURSE DATA
            theData.append("subject_id", this.$route.params.id);
            if (this.editCourseData.image.file) {
                theData.append("image", this.editCourseData.image.file);
            }
            // theData.append("teacher_specialization_id", this.editCourseData.name);
            theData.append("teacher_degree_id", this.editCourseData.degree.id);
            theData.append("academic_year_id", this.editCourseData.studyYear);
            theData.append("category_id", this.editCourseData.subjectCat);
            theData.append("lang", this.editCourseData.language);
            theData.append("start_date", this.editCourseData.start_date);
            theData.append("num_of_hour",parseInt(this.editCourseData.hour_count));
            theData.append("subject_kind", this.editCourseData.type_subject);
            if (this.editCourseData.type_subject == "online") {
                theData.append(
                    "price",
                    parseInt(this.editCourseData.price_subject)
                );
            }
            theData.append("desc", this.editCourseData.desc_subject);
            theData.append("objectives", this.editCourseData.goals_subject);
            theData.append("enriching", this.editCourseData.course_enrichments);

            var fileValues = this.editCourseData.attachmentsFiles;
            fileValues.forEach((item, index) => {
                if (item.file) {
                    theData.append("attachments[" + index + "]", item.file);
                }
            });

            this.newAddedDay.forEach((element, index) => {
                theData.append(`listens[${index}][link]`, element.link);
                theData.append(`listens[${index}][name]`, element.name);
                theData.append(`listens[${index}][duration]`, parseInt(element.duration));
                if (this.editCourseData.type_subject == "online") {
                    theData.append(
                        `listens[${index}][start_date]`,
                        element.start_date
                    );
                    theData.append(
                        `listens[${index}][start_time]`,
                        element.start_time
                    );
                }
            });
            // END:: APPEND EDIT COURSE DATA

            // START:: SEND REQUEST
            this.$axios
                .post(`teacher/subject/update`, theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;

                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("MESSAGES.edited_successfully"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });

                    this.newAddedDay = [];
                    this.getCourseDetails();
                    this.$router.back();
                })
                .catch((err) => {
                    this.isWaitingRequest = false;

                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT EDIT COURSE FORM

        // START:: SELECT UPLOADED IMAGE
        selectUploadedImage(e) {
            this.editCourseData.image.path = URL.createObjectURL(
                e.target.files[0]
            );
            this.editCourseData.image.file = e.target.files[0];
        },
        // END:: SELECT UPLOADED IMAGE

        // START:: HANDLE UPLOAD ATTACHMENTS
        handleUploadAttachments(e) {
            let selectedFiles = e.currentTarget.files;
            selectedFiles.forEach((file) => {
                this.editCourseData.attachmentsFiles.push({
                    id: Date.now(),
                    file: file,
                    file_name: file.name,
                });
            });
        },
        // END:: HANDLE UPLOAD ATTACHMENTS

        // START:: REMOVE PREVIEW CARD
        removePreviewCard(cardId) {
            let targetElement = this.editCourseData.attachmentsFiles.find(
                (element) => element.id == cardId
            );
            let targetElementIndex =
                this.editCourseData.attachmentsFiles.indexOf(targetElement);
            this.editCourseData.attachmentsFiles.splice(targetElementIndex, 1);

            this.$axios
                .delete(`teacher/subject/attachment/${cardId}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("VALIDATION.attachment_deleted"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                });
        },
        // END:: REMOVE PREVIEW CARD

        // START:: ADD NEW DAY METHOD
        addNewDay() {
            this.newAddedDay.push({
                id: Date.now(),
                name: null,
                link: null,
                duration: null,
                start_date: null,
                start_time: null,
            });
        },
        // END:: ADD NEW DAY METHOD
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCourseDetails();
        this.getStudyDegrees();
        this.getSubjectCats();
        // END:: FIRE METHODS
    },
};
</script>
