<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: SECTION -->
        <div class="courses_section_wrapper fadeIn" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="header_title_wrapper">
                                <h1>{{ $t("TITLES.searchSubject") }}</h1>
                            </div>
                        </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->

            <div class="section_content_wrapper">
                <div class="container">
                    <!-- START:: SUBJECTS -->
                    <div class="course_section_subjects_wrapper">
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptySearchMessage v-if="subjects.length == 0" />
                        <!-- END:: EMPTY COURSES MESSAGE -->
                        <div class="row" v-else>
                            <!-- START:: COURSE CARD -->
                            <div
                                v-for="course in subjects"
                                :key="course.id"
                                class="col-lg-3 my-3"
                            >
                                <!-- STUDENT CARD  -->
                                <router-link :to="redirectRoute(course.id)">
                                    <ImagedCard>
                                        <template v-slot:card_image>
                                            <img
                                                :src="course.specialization_ar"
                                                alt="Course Category Image"
                                            />
                                        </template>

                                        <template v-slot:card_text>
                                            <div class="wrapper">
                                                <p class="course_name">
                                                    {{ course.subject_name }}
                                                </p>
                                                <p class="courses_count">
                                                    {{ course.num_of_courses }}
                                                    كورس
                                                </p>
                                            </div>

                                            <div class="wrapper">
                                                <p class="teachers_count">
                                                    {{ course.num_of_teachers }}
                                                    معلم
                                                </p>
                                            </div>
                                        </template>
                                    </ImagedCard>
                                </router-link>
                            </div>
                            <!-- END:: COURSE CARD -->
                        </div>
                    </div>
                    <!-- END:: SUBJECTS -->
                </div>
            </div>
        </div>
        <!-- END:: SECTION -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING LOADERS
import MainLoader from "../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

// START:: IMPORTING IMAGED CARD COMPONENT
import ImagedCard from "../components/ui/ImagedCard.vue";
// END:: IMPORTING IMAGED CARD COMPONENT
// START:: IMPORTING EMPTY MESSAGES
import EmptySearchMessage from "../components/ui/emptyMessages/EmptySearchMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
export default {
    name: "CoursesSection",

    components: {
        MainLoader,
        ImageHeader,
        ImagedCard,
        EmptySearchMessage,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: SECTION TYPE DATA
            sectionType: this.$route.params.type,
            // END:: SECTION TYPE DATA

            // START:: SUBJECTS DATA
            subjects: [],
            // END:: SUBJECTS DATA

            // START:: CATEGORY DATA
            categoryData: [],
            // END:: CATEGORY DATA

            // START:: REGISTERED USER TYPE
            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),
            // END:: REGISTERED USER TYPE
        };
    },

    methods: {
        getSearchResult() {
            this.isLoading = true;
            const theData = new FormData();
            theData.append("name", this.$route.params.text);
            theData.append("type", "subject_name");
            this.$axios
                .post(`search`, theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.subjects = res.data.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
        },

        redirectRoute(course_id) {
            if (this.getAuthenticatedUserData.type == "teacher") {
                return `/teacher/course-details/online/${course_id}`;
            } else {
                if (this.sectionType == "Private_reinforcement") {
                    return `/strengthening-requests-teachers/${course_id}`;
                }
                return `/courses-categories/${course_id}`;
            }
        },
    },

    mounted() {
        this.getSearchResult();
    },
};
</script>
