<template>
    <div class="lessons_wrapper">
        <h3 class="title">
            {{ $t("TITLES.subject_lessons") }}
        </h3>

        <ul class="lessons_list">
            <!-- START:: LESSON -->
            <template>
                <li
                    class="lesson"
                    v-for="(lesson, index) in lessonsList.listens"
                    :key="lesson.id"
                >
                    <div class="lesson_details">
                        <router-link
                            :to="`/course-videos/${lesson.id}`"
                            class="lesson_details"
                            v-if="courseType == 'record'"
                        >
                            <div class="item_content_wrapper">
                                <span class="lesson_number"
                                    >#{{ index + 1 }}</span
                                >
                                <div class="lesson_info">
                                    <span class="name"> {{ lesson.name }}</span>
                                    <span
                                        class="lesson_duration"
                                        v-show="lesson.duration != 0"
                                    >
                                        <span>
                                            {{
                                                Math.floor(lesson.duration / 60)
                                            }}
                                            {{ $t("TITLES.hour") }}
                                        </span>
                                        <!-- <span
                                            >{{ lesson.diff_of_time.hour }}
                                            {{ $t("TITLES.hour") }}
                                        </span>
                                        <span
                                            >{{ lesson.diff_of_time.minute }}
                                            {{ $t("TITLES.minute") }}
                                        </span>
                                        <span
                                            >{{ lesson.diff_of_time.second }}
                                            {{ $t("TITLES.second") }}
                                        </span> -->
                                    </span>
                                </div>
                            </div>
                        </router-link>
                        <a
                            :href="lesson.link"
                            class="lesson_details"
                            target="_blank"
                            v-if="courseType == 'online'"
                        >
                            <div class="item_content_wrapper">
                                <span class="lesson_number">
                                    #{{ ++index }}
                                </span>
                                <div class="lesson_info">
                                    <span class="name">
                                        {{ lesson.name }}
                                    </span>
                                    <span>
                                        <span
                                            class="lesson_status running"
                                            v-if="lesson.status == 'now'"
                                        >
                                            <i class="fas fa-circle"></i>
                                            {{ $t("STATUS.on_going") }}
                                        </span>
                                        <span
                                            class="lesson_status done"
                                            v-if="lesson.status == 'finish'"
                                        >
                                            <i class="fas fa-circle"></i>
                                            {{ $t("STATUS.done") }}
                                        </span>
                                        <span
                                            class="lesson_status have_not"
                                            v-if="
                                                lesson.status ==
                                                'have_not_came_yet'
                                            "
                                        >
                                            <i class="fas fa-circle"></i>
                                            {{ lesson.start_date }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </a>

                        <div class="item_btns_wrapper">
                            <router-link
                                :to="`/course-videos/${lesson.id}`"
                                class="play_icon"
                                v-if="
                                    courseType == 'record' &&
                                    routeName != 'teacher_my_course'
                                "
                            >
                                <i class="fas fa-play-circle"></i>
                            </router-link>

                            <div
                                class="lesson_actions_menu_wrapper"
                                v-if="routeName == 'teacher_my_course'"
                            >
                                <button
                                    class="single_lesson_actions_btn"
                                    @click.stop="toggleActionsMenu($event)"
                                >
                                    <i class="fal fa-ellipsis-h"></i>
                                </button>

                                <ul class="lesson_actions_menu">
                                    <li class="menu_item">
                                        <button
                                            @click.stop="
                                                closeActionsMenu($event);
                                                toggleEditLessonModal(lesson);
                                            "
                                        >
                                            {{ $t("BUTTONS.edit_lesson") }}
                                        </button>
                                    </li>

                                    <li class="menu_item">
                                        <button
                                            @click.stop="
                                                closeActionsMenu($event);
                                                toggleDeleteModal(lesson);
                                            "
                                        >
                                            {{ $t("BUTTONS.delete_lesson") }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </template>
        </ul>

        <!-- START:: EDIT LESSON MODAL -->
        <EditLessonModal
            :courseType="courseType"
            :selectedLesson="selectedLessonToEdit"
            :modalApperanceData="editLessonModalIsOpen"
            @controleModalApperance="toggleEditLessonModal"
            @getDetailsAfterEdit="getDetailsAfterEdit"
        />
        <!-- END:: EDIT LESSON MODAL -->

        <!-- START:: DELETE MODAL -->
        <DeleteModal
            :targetElement="'lesson'"
            :selectedLesson="selectedLessonToDelete"
            :modalApperanceData="deleteModalIsOpen"
            @controleModalApperance="toggleDeleteModal"
            @getDetailsAfterDelete="getDetailsAfterEdit"
        />
        <!-- END:: DELETE MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING MODALS
import EditLessonModal from "../../components/modals/EditLessonModal.vue";
import DeleteModal from "../../components/modals/DeleteModal.vue";
// END:: IMPORTING MODALS

export default {
    name: "CourseLessonsLinks",

    components: {
        EditLessonModal,
        DeleteModal,
    },

    props: ["viewType", "courseType", "routeName", "lessonsList"],

    emits: ["getDetailsAfterEdit"],

    data() {
        return {
            // START:: LESSON ACTIONS MENU HANDLING DATA
            lessonActionsMenuIsOpen: false,
            // END:: LESSON ACTIONS MENU HANDLING DATA

            // START:: MODALS HANDLING DATA
            editLessonModalIsOpen: false,
            deleteModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SELECTED LESSON
            selectedLessonToEdit: null,
            selectedLessonToDelete: null,
            // END:: SELECTED LESSON
        };
    },

    methods: {
        // START:: TOGGLE LESSON ACTIONS MENU
        toggleActionsMenu(e) {
            let targetMenuElement = e.currentTarget.nextSibling;
            targetMenuElement.classList.toggle("active");
        },

        closeActionsMenu(e) {
            let targetMenuElement = e.target.parentElement.parentElement;
            targetMenuElement.classList.remove("active");
        },
        // END:: TOGGLE LESSON ACTIONS MENU

        // START:: TOGGLE EDIT LESSON MODAL METHOD
        toggleEditLessonModal(item) {
            this.editLessonModalIsOpen = !this.editLessonModalIsOpen;
            this.selectedLessonToEdit = item;
        },
        // END:: TOGGLE EDIT LESSON MODAL METHOD

        // START:: TOGGLE DELETE MODAL METHOD
        toggleDeleteModal(item) {
            this.deleteModalIsOpen = !this.deleteModalIsOpen;
            this.selectedLessonToDelete = item;
        },
        // END:: TOGGLE DELETE MODAL METHOD

        // START:: GET DETAILS AFTER EDIT
        getDetailsAfterEdit() {
            this.$emit("getDetailsAfterEdit");
        },
        // END:: GET DETAILS AFTER EDIT
    },

    created() {
        window.addEventListener("click", () => {
            let targetMenuElement = document.querySelectorAll(
                ".lesson_actions_menu"
            );
            targetMenuElement.forEach((element) => {
                element.classList.remove("active");
            });
        });
    },

    mounted() {},
};
</script>
