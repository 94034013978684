<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="strengthening_requests_courses_wrapper fadeIn" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="header_title_wrapper">
                                <h1>
                                    {{ $t("TITLES.searchTeacher") }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->

            <div class="courses_wrapper py-5 fadeIn">
                <div class="container">
                    <!-- START:: EMPTY COURSES MESSAGE -->
                    <EmptySearchMessage v-if="instructors.length == 0" />
                    <!-- END:: EMPTY COURSES MESSAGE -->
                    <div class="row" v-else>
                        <!-- START:: ONLINE COURSE CARD -->
                        <div
                            v-for="instructor in instructors"
                            :key="instructor.id"
                            class="col-lg-3 my-3"
                        >
                            <!-- START:: STRENGTHENING REQUEST CARD -->
                            <button
                                class="w-100"
                                @click="toggleTeacherModal(instructor)"
                            >
                                <ImagedCard>
                                    <template #card_image>
                                        <img
                                            :src="instructor.image"
                                            :alt="instructor.fullname"
                                        />
                                    </template>

                                    <template #instructor_card_data>
                                        <div class="wrapper">
                                            <div
                                                class="instructor_info_wrapper"
                                            >
                                                <img
                                                    :src="instructor.image"
                                                    :alt="instructor.fullname"
                                                    width="100"
                                                    height="100"
                                                />

                                                <div class="text">
                                                    <p class="name">
                                                        {{
                                                            instructor.fullname
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </ImagedCard>
                            </button>
                            <!-- START:: STRENGTHENING REQUEST CARD -->
                        </div>
                        <!-- END:: ONLINE COURSE CARD -->
                    </div>
                </div>
            </div>

            <!-- START:: TEACHER MODAL -->
            <TeacherModal
                :modalApperanceData="teacherModalIsOpen"
                :teacherData="selectedTeacher"
                @controleModalApperance="toggleTeacherModal"
            />
            <!-- END:: TEACHER MODAL -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD

// START:: IMPORTING MODALS
import TeacherModal from "../components/modals/TeacherModal.vue";
// END:: IMPORTING MODALS
// START:: IMPORTING LOADERS
import MainLoader from "../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: IMPORTING EMPTY MESSAGES
import EmptySearchMessage from "../components/ui/emptyMessages/EmptySearchMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
export default {
    name: "StrengtheningRequestsCourses",

    components: {
        ImageHeader,
        ImagedCard,
        TeacherModal,
        MainLoader,
        EmptySearchMessage,
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: MODALS DATA
            teacherModalIsOpen: false,
            // END:: MODALS DATA

            // START:: SELECTED TEACHER
            selectedTeacher: null,
            // END:: SELECTED TEACHER

            // START:: REGISTERED COURSES DATA
            instructors: [],
            // END:: REGISTERED COURSES DATA

            // START:: SUBJECT DATA
            subjectData: null,
            // END:: SUBJECT DATA
        };
    },

    methods: {
        // START:: TOGGLE MODALS
        toggleTeacherModal(selected_teacher) {
            this.teacherModalIsOpen = !this.teacherModalIsOpen;
            this.selectedTeacher = selected_teacher;
        },
        // END:: TOGGLE MODALS

        // START:: GET STRENGTHENING REQUESTS TEACHERS
        getSearchResult() {
            this.isLoading = true;
            const theData = new FormData();
            theData.append("name", this.$route.params.text);
            theData.append("type", "teacher");
            this.$axios
                .post(`search`, theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.instructors = res.data.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
        },
        // END:: GET STRENGTHENING REQUESTS TEACHERS
    },

    mounted() {
        // START:: FIRE METHODS
        this.getSearchResult();
        // END:: FIRE METHODS
    },
};
</script>
