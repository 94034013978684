<template>
    <div class="course_details_column">
        <!-- START:: ABOUT COURSE -->
        <div class="course_details_group my-3">
            <h3 class="title">
                {{ $t("TITLES.about_course") }}
            </h3>
            <div class="text">
                {{ description }}
            </div>
        </div>
        <!-- END:: ABOUT COURSE -->

        <!-- START:: COURSE GOALS -->
        <div class="course_details_group my-3">
            <h3 class="title">
                {{ $t("TITLES.course_goals") }}
            </h3>
            <div class="text">
                {{ goals }}
            </div>
        </div>
        <!-- END:: COURSE GOALS -->

        <!-- START:: COURSE ENRICHMENTS -->
        <div class="course_details_group my-3">
            <h3 class="title">
                {{ $t("TITLES.course_enrichments") }}
            </h3>
            <div class="text">
                {{ enrichments }}
            </div>
        </div>
        <!-- END:: COURSE ENRICHMENTS -->

        <!-- START:: COURSE ENRICHMENTS -->
        <div class="course_details_group my-3">
            <h3 class="title">
                {{ $t("TITLES.attachments") }}
            </h3>
            <div class="wrapper">
                <!-- START:: ATTACHMENT CARD -->
                <a
                    class="attachment_wrapper my-3"
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    :href="attachment.url"
                    download
                    target="_blank"
                >
                    <span class="file_name">
                        <i class="fal fa-file-alt"></i>
                        <span>
                            {{ attachment.file_name.substring(0, 25) + "..." }}
                        </span>
                    </span>

                    <i class="fal fa-download"></i>
                </a>
                <!-- END:: ATTACHMENT CARD -->
            </div>
        </div>
        <!-- END:: COURSE ENRICHMENTS -->
    </div>
</template>

<script>
export default {
    name: "CourseStaticInfoAndAttachments",

    props: ["description", "goals", "enrichments", "attachments"],
};
</script>
