export default {
    // START:: GET  CREATE COURSE STEP ONE DATA
    createCourseStepOneData(state) {
        return state.createCourseStepOne;
    },
    createCourseStepTwoData(state) {
        return state.createCourseStepTwo;
    }
    // END:: GET  CREATE COURSE STEP ONE DATA
};
