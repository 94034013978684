<template>
    <div class="user_account_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.my_account") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.my_account") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <!-- START:: ROUTES WRAPPER -->
        <div class="routes_wrapper">
            <router-link to="/user-account" class="edit_account_route">
                {{ $t("TITLES.personal_info") }}
            </router-link>

            <router-link
                to="/career_info"
                class="edit_account_route"
                v-if="getAuthenticatedUserData.type == 'teacher'"
            >
                {{ $t("TITLES.career_info") }}
            </router-link>
        </div>
        <!-- END:: ROUTES WRAPPER -->

        <!-- START:: USER ACCOUNT ROUTER VIEW -->
        <router-view></router-view>
        <!-- END:: USER ACCOUNT ROUTER VIEW -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// START:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "UserAccount",

    components: {
        Breadcrumb,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },
};
</script>
