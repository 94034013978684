<template :key="$route.path">
    <div class="chat_content_wrapper">
        <!-- START:: MESSAGES -->
        <div
            class="messages_box"
            v-chat-scroll="{ always: true, smooth: true }"
        >
            <!-- START:: START OF THE CHAT TITLE -->
            <p class="start_of_the_chat">
                {{ $t("TITLES.start_of_the_chat") }}
            </p>
            <!-- END:: START OF THE CHAT TITLE -->

            <!-- START:: TEXT MESSAGES -->
            <div
                v-for="message in [...singleChatData.data].reverse()"
                :key="message.id"
                class="message_wrapper"
                :class="
                    message.message_position == 'current'
                        ? 'sender'
                        : 'receiver'
                "
            >
                <p
                    class="text_message_content"
                    v-if="message.message_type == 'text'"
                >
                    {{ message.message }}
                </p>

                <div
                    class="image_message_content"
                    v-else-if="message.message_type == 'image'"
                >
                    <img :src="message.message" alt="Image Message" />
                </div>

                <div class="file_message_content" v-else>
                    <a :href="message.message" target="_blank">
                        <i class="fal fa-file-alt"></i>
                        <span>
                            {{ message.message.substring(0, 25) + "..." }}
                        </span>
                    </a>
                </div>
            </div>
            <!-- END:: TEXT MESSAGES -->
        </div>
        <!-- END:: MESSAGES -->

        <!-- START:: CHAT INPUT -->
        <form @submit.prevent="sendChatMessage" class="chat_input_wrapper">
            <!-- START:: SELECTED FILE PREVIEW -->
            <transition mode="fadeIn">
                <div class="selected_file_preview" v-if="filePreviewIsOpen">
                    <p>{{ selectedMediaFile.name }}</p>
                    <button
                        type="button"
                        class="close_file_preview"
                        @click.stop="closeFilePreview"
                    >
                        <i class="fal fa-times"></i>
                    </button>
                </div>
            </transition>
            <!-- END:: SELECTED FILE PREVIEW -->

            <!-- START:: INPUT -->
            <div class="input_wrapper">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.write_here')"
                    v-model.trim="textMessageContent"
                    :disabled="selectedMediaFile.file"
                />

                <!-- START:: MEDIA BUTTON -->
                <div class="media_buttons_wrapper">
                    <div class="wrapper">
                        <label for="select_media">
                            <i class="fal fa-paperclip"></i>
                        </label>
                        <input
                            id="select_media"
                            type="file"
                            accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .txt"
                            @change="selectMediaFile"
                        />
                    </div>

                    <button type="button" class="select_emoji">
                        <!-- START:: EMOJI CARD -->
                        <div class="chat-emoji wrapper">
                            <emoji-picker @emoji="append" :search="searchEmoji">
                                <div
                                    class="emoji-invoker"
                                    slot="emoji-invoker"
                                    slot-scope="{
                                        events: { click: clickEvent },
                                    }"
                                    @click.stop="clickEvent"
                                >
                                    <i class="fal fa-smile"></i>
                                </div>
                                <div
                                    slot="emoji-picker"
                                    slot-scope="{ emojis, insert, display }"
                                >
                                    <div
                                        class="emoji-picker"
                                        :style="{
                                            top: display.y + 'px',
                                            left: display.x + 'px',
                                        }"
                                    >
                                        <div class="emoji-picker__search">
                                            <input
                                                type="text"
                                                v-model="searchEmoji"
                                                :placeholder="
                                                    $t(
                                                        'PLACEHOLDERS.search_for_emoji'
                                                    )
                                                "
                                                v-focus
                                            />
                                        </div>
                                        <div>
                                            <div
                                                v-for="(
                                                    emojiGroup, category
                                                ) in emojis"
                                                :key="category"
                                            >
                                                <h5>{{ category }}</h5>
                                                <div class="emojis">
                                                    <span
                                                        v-for="(
                                                            emoji, emojiName
                                                        ) in emojiGroup"
                                                        :key="emojiName"
                                                        @click="insert(emoji)"
                                                        :title="emojiName"
                                                        >{{ emoji }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </emoji-picker>
                        </div>
                        <!-- END:: EMOJI CARD -->
                    </button>
                </div>
                <!-- END:: MEDIA BUTTON -->
            </div>
            <!-- END:: INPUT -->

            <!-- START:: SEND BUTTON -->
            <div class="send_btn">
                <button class="send_message_btn">
                    <i class="fal fa-paper-plane"></i>
                </button>
            </div>
            <!-- END:: SEND BUTTON -->
        </form>
        <!-- END:: CHAT INPUT -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING EMOJI PICKER
import EmojiPicker from "vue-emoji-picker";
// END:: IMPORTING EMOJI PICKER
// socket
import Echo from "laravel-echo";
export default {
    name: "ChatContent",

    components: {
        EmojiPicker,
    },

    computed: {
        // START:: VUEX CHAT DATA
        ...mapGetters("ChatModule", ["singleChatData"]),
        // END:: VUEX CHAT DATA
    },

    data() {
        return {
            // START:: RECEIVER ID DATA
            receiverId: this.$route.params.id,
            // END:: RECEIVER ID DATA

            // START:: SENDER ID DATA
            senderId: localStorage.getItem("elmo3lm_elmosa3d_user_id"),
            // END:: SENDER ID DATA

            // START:: FILE PREVIEW APPERANCE DATA
            filePreviewIsOpen: false,
            // END:: FILE PREVIEW APPERANCE DATA

            // START:: EMOJIS DATA
            SearchActive: false,
            searchEmoji: "",
            properties: false,
            // END:: EMOJIS DATA

            // START:: MESSAGES DATA
            textMessageContent: "",
            selectedMediaFile: {
                file: null,
                name: null,
                path: null,
                type: null,
            },
            // END:: MESSAGES DATA
            // socket
            userIdLogin: "",
            chat_id: "",
        };
    },

    methods: {
        // START:: VUEX CHAT SEND MESSAGE
        ...mapActions("ChatModule", [
            "getChatContent",
            "sendMessage",
            "getChatsList",
        ]),
        // END:: VUEX CHAT SEND MESSAGE

        // START:: EMOJIS HANDLING METHODS
        toggleClassActive() {
            if (this.SearchActive) {
                this.SearchActive = false;
            } else {
                this.SearchActive = true;
            }
        },
        append(emoji) {
            this.textMessageContent += emoji;
        },
        showProperties() {
            if (this.properties) {
                this.properties = false;
            } else {
                this.properties = true;
            }
        },
        // END:: EMOJIS HANDLING METHODS

        // START:: CLOSE FILE PREVIEW
        closeFilePreview() {
            this.filePreviewIsOpen = false;

            (this.selectedMediaFile.file = null),
                (this.selectedMediaFile.name = null);
        },
        // END:: CLOSE FILE PREVIEW

        // START:: SELECT MEDIA FILE
        selectMediaFile(e) {
            this.selectedMediaFile.file = e.currentTarget.files[0];
            this.selectedMediaFile.name = e.currentTarget.files[0].name;
            this.selectedMediaFile.path = URL.createObjectURL(
                e.currentTarget.files[0]
            );
            this.selectedMediaFile.type = e.currentTarget.files[0].type;
            this.filePreviewIsOpen = true;
        },
        // END:: SELECT MEDIA FILE

        // START:: SEND MESSAGE
        sendChatMessage() {
            let messageDetails = new FormData();
            messageDetails.append("sender_id", this.senderId);
            messageDetails.append("receiver_id", this.receiverId);

            if (this.textMessageContent && !this.selectedMediaFile.file) {
                messageDetails.append("message_type", "text");
                messageDetails.append("message", this.textMessageContent);
                this.sendMessage({
                    messageDetails: messageDetails,
                    id: this.receiverId,
                });
                this.textMessageContent = "";
            } else if (
                !this.textMessageContent &&
                this.selectedMediaFile.file
            ) {
                if (this.selectedMediaFile.type.includes("image")) {
                    messageDetails.append("message_type", "image");
                    messageDetails.append(
                        "message",
                        this.selectedMediaFile.file
                    );
                } else if (this.selectedMediaFile.type.includes("video")) {
                    messageDetails.append("message_type", "video");
                    messageDetails.append(
                        "message",
                        this.selectedMediaFile.file
                    );
                } else if (
                    this.selectedMediaFile.type.includes("application") ||
                    this.selectedMediaFile.type.includes("text")
                ) {
                    messageDetails.append("message_type", "file");
                    messageDetails.append(
                        "message",
                        this.selectedMediaFile.file
                    );
                }

                this.sendMessage({
                    messageDetails: messageDetails,
                    id: this.receiverId,
                });
                this.selectedMediaFile.file = null;
                this.selectedMediaFile.name = null;
                this.selectedMediaFile.path = null;
                this.selectedMediaFile.type = null;
                this.closeFilePreview();
            }
        },
        // END:: SEND MESSAGE
        // start:: run message
        runSocketOnVuex(id) {
            this.getChatContent(id);
            setTimeout(() => {
                this.receiveMessage();
            }, 1000);
        },
        // end:: run message
        // socket chat
        receiveMessage() {
            if (localStorage.getItem("elmo3lm_elmosa3d_user_token") != null) {
                window.io = require("socket.io-client");
                window.Echo = new Echo({
                    broadcaster: "socket.io",
                    host: "https://moaalem.khlod.aait-d.com:6045",
                    csrfToken:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    auth: {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            Accept: "application/json",
                        },
                    },
                });
                // -----------------
                window.Echo.private(
                    `mo3alem-mosa3ed-chat.${this.singleChatData.conversation_id}`
                ).listen(".ChatEvent", (payload) => {
                    // console.log("chat_payload =>", payload);
                    if (payload) {
                        this.getChatsList();
                        this.getChatContent(this.receiverId);
                    }
                });
                // console.log("chat_socket =>", window.Echo);
            }
        },
    },
    mounted() {
        // START:: FIRE METHODS
        this.runSocketOnVuex(this.receiverId);
        // END:: FIRE METHODS
    },
};
</script>
