<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="strengthening_requests_courses_wrapper fadeIn" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="header_title_wrapper">
                                <h1>
                                    {{ subjectData.subject_name }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->

            <div class="courses_wrapper py-5 fadeIn">
                <div class="container">
                    <div class="row">
                        <!-- START:: ONLINE COURSE CARD -->
                        <div
                            v-for="instructor in instructors"
                            :key="instructor.id"
                            class="col-lg-3 my-3"
                        >
                            <!-- START:: STRENGTHENING REQUEST CARD -->
                            <button
                                class="w-100"
                                @click="toggleTeacherModal(instructor)"
                            >
                                <ImagedCard>
                                    <template #card_image>
                                        <img
                                            :src="subjectData.specialization_ar"
                                            :alt="subjectData.subject_name"
                                        />
                                    </template>

                                    <template #instructor_card_data>
                                        <div class="wrapper">
                                            <div
                                                class="instructor_info_wrapper"
                                            >
                                                <img
                                                    :src="instructor.image"
                                                    :alt="instructor.fullname"
                                                    width="100"
                                                    height="100"
                                                />

                                                <div class="text">
                                                    <p class="name">
                                                        {{
                                                            instructor.fullname
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </ImagedCard>
                            </button>
                            <!-- START:: STRENGTHENING REQUEST CARD -->
                        </div>
                        <!-- END:: ONLINE COURSE CARD -->
                    </div>
                </div>
            </div>

            <!-- START:: TEACHER MODAL -->
            <TeacherModal
                :modalApperanceData="teacherModalIsOpen"
                :teacherData="selectedTeacher"
                @controleModalApperance="toggleTeacherModal"
            />
            <!-- END:: TEACHER MODAL -->
        </div>
        <!-- START:: PAGENATION FOR TEACHER-->
        <Pagenation
            :last_page="last_page"
            :current_page="current_page"
            @pagenationClick="pagenationClick"
        ></Pagenation>
        <!-- END:: PAGENATION FOR TEACHER-->
    </div>
</template>

<script>
// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD

// START:: IMPORTING MODALS
import TeacherModal from "../../components/modals/TeacherModal.vue";
// END:: IMPORTING MODALS
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "StrengtheningRequestsCourses",

    components: {
        ImageHeader,
        ImagedCard,
        TeacherModal,
        MainLoader,
        Pagenation,
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: MODALS DATA
            teacherModalIsOpen: false,
            // END:: MODALS DATA

            // START:: SELECTED TEACHER
            selectedTeacher: null,
            // END:: SELECTED TEACHER

            // START:: REGISTERED COURSES DATA
            instructors: [],
            // END:: REGISTERED COURSES DATA

            // START:: SUBJECT DATA
            subjectData: null,
            // END:: SUBJECT DATA
            // START:: PAGENATION
            last_page: null,
            current_page: 1,
            // END:: PAGENATION
        };
    },

    methods: {
        // START:: TOGGLE MODALS
        toggleTeacherModal(selected_teacher) {
            this.teacherModalIsOpen = !this.teacherModalIsOpen;
            this.selectedTeacher = selected_teacher;
        },
        // END:: TOGGLE MODALS

        // START:: GET STRENGTHENING REQUESTS TEACHERS
        getStrengtheningRequestsTeachers() {
            this.isLoading = true;
            this.$axios
                .get(
                    `student/all-teacher/${this.$route.params.id}?page=${this.current_page}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.instructors = res.data.data;
                    this.subjectData = res.data.subject_name;
                    this.last_page = res.data.meta.last_page;
                    this.isLoading = false;
                });
        },
        // END:: GET STRENGTHENING REQUESTS TEACHERS
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getStrengtheningRequestsTeachers();
        },
        // END:: PAGENATION
    },

    mounted() {
        // START:: FIRE METHODS
        this.getStrengtheningRequestsTeachers();
        // END:: FIRE METHODS
    },
};
</script>
