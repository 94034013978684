export default {
    // START:: CHATS LIST GETTER
    chatsListData(state) {
        return state.chatsList;
    },
    // END:: CHATS LIST GETTER

    // START:: SINGLE CHATS GETTER
    singleChatData(state) {
        return state.singleChatData;
    },
    // END:: SINGLE CHATS GETTER
};
