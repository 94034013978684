<template>
    <div class="teacher_balance_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.my_balance") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.my_balance") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="my_balance_card_wrapper fadeIn" v-else>
            <div class="container">
                <div class="row">
                    <div class="my_balance_card">
                        <h4>{{ $t("TITLES.your_balance_now") }}</h4>
                        <h2>
                            {{ wallet.mainValueForWallet }}
                            <span>{{ wallet.currency }}</span>
                        </h2>
                    </div>
                    <div
                        class="withdraw_btn"
                        v-if="wallet.mainValueForWallet > 0"
                    >
                        <router-link to="/withdraw">{{
                            $t("TITLES.withdraw")
                        }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB
// START:: IMPORTING LOADERS
import MainLoader from "../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "TeacherBalance",

    components: {
        Breadcrumb,
        MainLoader,
    },
    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE
            wallet: "",
        };
    },
    methods: {
        getWallet() {
            this.isLoading = true;
            this.$axios
                .get(`user/wallet`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.wallet = res.data.data;
                    this.isLoading = false;
                    // console.log(this.wallet);
                });
        },
    },
    mounted() {
        this.getWallet();
    },
};
</script>
