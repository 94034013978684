<template>
    <div class="courses_categories_wrapper">
        <div class="container">
            <!-- START:: TEACHER HEADER WRAPPER -->
            <div class="section_title_wrapper">
                <h2 class="title">
                    <img
                        src="../../assets/media/shapes/title_icon.svg"
                        alt="Title Icon"
                    />
                    <span> {{ $t("TITLES.courses_cats") }} </span>
                </h2>
            </div>
            <!-- END:: TEACHER HEADER WRAPPER -->

            <!-- START:: STUDENT HEADER WRAPPER -->
            <!-- <div class="section_title_wrapper">
                <h2 class="title">
                    <img
                        src="../../assets/media/shapes/title_icon.svg"
                        alt="Title Icon"
                    />
                    <span> {{ $t("TITLES.categories") }} </span>
                </h2>

                <div class="route_wrapper">
                    <router-link to="/"> {{ $t("BUTTONS.show_all") }} </router-link>
                </div>
            </div> -->
            <!-- END:: STUDENT HEADER WRAPPER -->

            <!-- START:: COURSES CATEGORIES CARD -->
            <div class="row mt-5">
                <!-- START:: CATEGORY CARD -->
                <div
                    v-for="category in courseCatsData"
                    :key="category.id"
                    class="col-lg-6 my-3"
                >
                    <SolidCard>
                        <router-link
                            :to="`/course-section/${category.id}/${category.special_key}`"
                            class="card_content_wrapper"
                        >
                            <h3>{{ category.name }}</h3>
                            <img
                                :src="category.category_image"
                                :alt="category.name"
                                width="145"
                                height="160"
                            />
                        </router-link>
                    </SolidCard>
                </div>
                <!-- END:: CATEGORY CARD -->

                <!-- START:: CATEGORY CARD -->
                <!-- <div
                    class="col-lg-6 my-3"
                    v-if="getAuthenticatedUserData.type == 'student'"
                >
                    <SolidCard>
                        <router-link to="/course-section/distance-learning-lessons" class="card_content_wrapper">
                            <h3>دروس تقوية عن بعد</h3>
                            <img
                                src="../../assets/media/illustrations/illustration_3.svg"
                                alt="Illustration"
                                width="145"
                                height="160"
                            />
                        </router-link>
                    </SolidCard>
                </div> -->
                <!-- END:: CATEGORY CARD -->

                <!-- START:: CATEGORY CARD -->
                <!-- <div
                    class="col-lg-6 my-3"
                    v-if="getAuthenticatedUserData.type == 'student'"
                >
                    <SolidCard>
                        <router-link to="/course-section/strengthening-requests" class="card_content_wrapper">
                            <h3>دروس تقوية خاصة</h3>
                            <img
                                src="../../assets/media/illustrations/illustration_2.svg"
                                alt="Illustration"
                                width="145"
                                height="160"
                            />
                        </router-link>
                    </SolidCard>
                </div> -->
                <!-- END:: CATEGORY CARD -->

                <!-- START:: CATEGORY CARD -->
                <!-- <div
                    class="col-lg-6 my-3"
                    v-if="getAuthenticatedUserData.type == 'teacher'"
                >
                    <SolidCard>
                        <router-link to="/course-section/postgraduate" class="card_content_wrapper">
                            <h3>دورات الدراسات العليا</h3>
                            <img
                                src="../../assets/media/illustrations/illustration_4.svg"
                                alt="Illustration"
                                width="145"
                                height="160"
                            />
                        </router-link>
                    </SolidCard>
                </div> -->
                <!-- END:: CATEGORY CARD -->

                <!-- START:: CATEGORY CARD -->
                <!-- <div
                    class="col-lg-6 my-3"
                    v-if="getAuthenticatedUserData.type == 'teacher'"
                >
                    <SolidCard>
                        <router-link to="/" class="card_content_wrapper">
                            <h3>دورات تدريبية</h3>
                            <img
                                src="../../assets/media/illustrations/illustration_1.svg"
                                alt="Illustration"
                                width="145"
                                height="160"
                            />
                        </router-link>
                    </SolidCard>
                </div> -->
                <!-- END:: CATEGORY CARD -->
            </div>
            <!-- END:: COURSES CATEGORIES CARD -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING SOLID CARD COMPONENT
import SolidCard from "../ui/SolidCard.vue";
// END:: IMPORTING SOLID CARD COMPONENT

export default {
    name: "CoursesCategories",

    components: {
        SolidCard,
    },

    props: ["courseCatsData"],

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },
};
</script>
