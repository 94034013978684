var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured_courses_wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section_title_wrapper"},[_c('h2',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/media/shapes/title_icon.svg"),"alt":"Title Icon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("TITLES.teachers"))+" ")])]),_c('div',{staticClass:"route_wrapper"},[_c('router-link',{attrs:{"to":"/teachers"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.show_all"))+" ")])],1)]),_c('div',{staticClass:"featured_courses_slider_wrapper mt-5"},[_c('carousel',{attrs:{"dir":"rtl","nav":false,"dots":true,"items":4,"margin":15,"loop":false,"autoplay":true,"autoplayTimeout":6000,"dragEndSpeed":2000,"smartSpeed":2000,"responsive":{
                    0: {
                        items: 1,
                    },
                    750: {
                        items: 2,
                    },
                    900: {
                        items: 4,
                    },
                }}},_vm._l((_vm.Items),function(teacher){return _c('div',{key:teacher.id,staticClass:"slider_image_wrapper",attrs:{"dir":_vm.getAppLocale == 'ar' ? 'rtl' : 'ltr'}},[_c('SolidCard',{staticClass:"col-10"},[_c('router-link',{staticClass:"card_content_wrapper row bg-white py-3",attrs:{"to":`/teacher/${teacher.id}`}},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-12 col-sm-12 m-auto"},[_c('img',{staticClass:"rounded",attrs:{"src":teacher.profile_image,"alt":teacher.user_type}})]),_c('div',{staticClass:"col mt-5"},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(teacher.fullname))]),_c('div',{staticClass:"d-flex"},[_vm._l((teacher.specializations.slice(0, 2)),function(specialization){return _c('div',{key:specialization.id,attrs:{"value":_vm.id}},[_c('h4',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(specialization.name)+" "),(teacher.specializations.length > 1)?_c('span',{staticClass:"text-secondary"},[_vm._v(" / ")]):_vm._e()])])}),(teacher.specializations.length > 2)?_c('div',[_c('h4',{staticClass:"text-secondary"},[_vm._v("...")])]):_vm._e()],2)])])],1)],1)}),0)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }