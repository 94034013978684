<template>
    <div>
        <MainLoader v-if="isLoading" />

        <div v-else>
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.quiz_questions") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.quiz_questions") }}
                </template>
            </Breadcrumb>

            <div class="d-flex justify-content-center container">
                <form @submit.prevent="validateFormInputs()">
                    <div class="row mt-6">
                        <h3 class="fw-bold" v-if="!showUnavailableQuiz">
                            {{ $t("TITLES.communication_data") }}
                        </h3>
                        <div class="input_wrapper row mb-5" v-for="question in quiz" :key="question.id">
                            <div class="col-6">
                                <input type="text" readonly rows="4" cols="60" :style="{
                                    'background-color': '#f7f7fa',
                                }" class="form-control ml-5" :value="`${question.question}`" />
                            </div>
                            <div class="input_wrapper col d-flex" :key="question.id">
                                <div class="d-flex col-3" v-for="answer in question.answers" :key="answer.id">
                                    <input type="button" class="form-control ml-4 mt-2 pt-1" :style="{
                                        'background-color': '#ededef',
                                        height: '70%',
                                    }" :value="answer.answer" :id="'answer-' + answer.id"
                                        @click="selectAnswer(question, answer)" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="btn_wrapper mt-5" v-if="!showUnavailableQuiz">
                        <button type="submit">
                            {{ $t("BUTTONS.end_quiz") }}
                        </button>
                    </div>
                </form>
            </div>

            <div v-if="showUnavailableQuiz" id="unavailableQuiz" style="flex-direction: column"
                class="mt-5 d-flex align-items-center">
                <img src="../assets/media/images/block.png" style="
                        margin-bottom: 20px;
                        width: 200px;
                        height: auto;
                        margin-bottom: 20px;
                    " />
                <h3 class="text-danger text-center">
                    {{ $t("TITLES.unavailableQuiz") }}
                </h3>
                <div class="btn_wrapper">
                    <router-link :to="`/student/course-details/${courseType}/${courseId}`">
                        <button type="submit">
                            {{ $t("BUTTONS.show_subject") }}
                        </button>
                    </router-link>
                </div>
            </div>

            <SuccessModal v-if="successModalIsOpen" :modalApperanceData="successModalIsOpen"
                @controleModalApperance="toggleSuccessModal">
                <template #title>
                    {{ $t("TITLES.quiz_added") }}
                </template>
                <template #sub_title>
                    {{ $t("TITLES.quiz_ending") }}
                    ({{ quizResult.student_result }} -
                    {{ quizResult.final_result }})
                    {{ $t("TITLES.quiz_degree") }}
                </template>
                <template #success_btn>
                    <router-link :to="`/student/course-details/${courseType}/${courseId}`">
                        {{ $t("BUTTONS.show_subject") }}
                    </router-link>
                </template>
            </SuccessModal>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "./../components/structure/TheBreadcrumb.vue";
import MainLoader from "./../components/ui/loaders/MainLoader.vue";
import SuccessModal from "./../components/modals/SuccessModal.vue";

export default {
    name: "ReportsPage",

    components: {
        MainLoader,
        Breadcrumb,
        SuccessModal,
    },
    data() {
        return {
            successModalIsOpen: false,
            isLoading: false,
            courseId: this.$route.params.id,
            courseType: this.$route.params.courseType,
            quiz: null,
            quizResult: null,
            showUnavailableQuiz: false,
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type")
        };
    },

    methods: {
        toggleSuccessModal() {
            this.successModalIsOpen = !this.successModalIsOpen;
        },
        validateFormInputs() {
            for (const question of this.quiz) {
                if (!question.selectedAnswer) {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("VALIDATION.answer_required"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                }
            }
            this.submitQuiz();
        },

        selectAnswer(question, selectedAnswer) {
            question.selectedAnswer = selectedAnswer.answer;

            // Reset the 'selected' property and style for all answers
            for (const answer of question.answers) {
                answer.selected = false;
                const button = document.getElementById("answer-" + answer.id);
                if (button) {
                    button.style.backgroundColor = "#ededef";
                    button.style.color = "";
                }
            }

            selectedAnswer.selected = true;

            // Change the color of the selected button
            const selectedButton = document.getElementById(
                "answer-" + selectedAnswer.id
            );
            if (selectedButton) {
                selectedButton.style.backgroundColor = "#34bf6c";
                selectedButton.style.color = "white";
            }

            console.log(question.selectedAnswer);
        },
        submitQuiz() {
            if (this.userType === "student") {
                const formData = {
                    answers_ids: [],
                    questions_ids: [],
                };

                this.quiz.forEach((question) => {
                    const selectedAnswer = question.answers.find(
                        (answer) => answer.selected
                    );
                    if (selectedAnswer) {
                        formData.answers_ids.push({ id: selectedAnswer.id });
                    }
                    formData.questions_ids.push(question.id);
                });

                this.$axios
                    .post(
                        `student/subject/${this.courseId}/quiz/result`,
                        formData,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                        "elmo3lm_elmosa3d_user_token"
                                    ),
                                "Accept-language": localStorage.getItem(
                                    "elmo3lm_elmosa3d_app_lang"
                                ),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        this.successModalIsOpen = true;
                        this.quizResult = res.data.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                this.$router.replace({ path: '/added-subjects' });
            }
        },
    },
    mounted() {
        if (this.userType === "student") {

            this.$axios
                .get(`student/subject/${this.courseId}/quiz`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.quiz = res.data.data;
                    this.quiz.forEach((question) => {
                        question.selectedAnswer = "";
                        question.answers.forEach((answer) => {
                            answer.selected = false;
                        });
                    });
                })
                .catch((error) => {
                    if (error.message === "Request failed with status code 401") {
                        this.showUnavailableQuiz = true;
                    } else {
                        this.$router.replace({ path: '/notFound' });
                    }
                });
        } else {
            this.$router.replace({ path: '/added-subjects' });
        }
    }
};
</script>
