import chatMutations from "./mutations";
import chatActions from "./actions.js";
import chatGetters from "./getters.js";

export default {
    namespaced: true,
    state: {
        // START:: CHATS DATA
        chatsList: null,
        singleChatData: null,
        // END:: CHATS DATA
    },
    mutations: chatMutations,
    actions: chatActions,
    getters: chatGetters,
};
