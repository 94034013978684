export default {
    // START:: STRENGTHENING REQUESTS GETTER
    strengtheningRequestsData(state) {
        return {
            strengtheningRequestsData: state.strengtheningRequestsData,
            last_page: state.last_page,
        };
    },
    // END:: STRENGTHENING REQUESTS GETTER
};
