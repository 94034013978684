<template>
    <div class="image_header_wrapper">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ImageHeader",
};
</script>
