// import iziToast from "izitoast";
// import i18n from '@/i18n.js'

// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS

export default {
    // START:: GET STRENGTHENING REQUESTS DATA
    getStrengtheningRequests(context, payload) {
        var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
        if (userType == "teacher") {
            axios
                .get("teacher/relay_request?page=" + payload, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    context.commit("setStrengtheningRequests", res.data);
                });
        } else if (userType == "parent") {
            axios
                .get("parent/relay_request?page=" + payload, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    context.commit("setStrengtheningRequests", res.data);
                });
        }
    },
    // END:: GET STRENGTHENING REQUESTS DATA

    // START:: CHANGE STRENGTHENING REQUESTS STATUS
    changeStrengtheningRequestsStatus(context, payload) {
        let theData = new FormData();
        // START:: SET REQUEST DATA
        theData.append("relay_request_id", payload.id);
        theData.append("status", payload.newStatus);
        // END:: SET REQUEST DATA

        axios
            .post("teacher/relay_request/change_status", theData, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then(() => {
                context.dispatch("getStrengtheningRequests");
            });
    },
    // END:: CHANGE STRENGTHENING REQUESTS STATUS
};
