<template>
    <div class="videos_list_wrapper">
        <div class="container">
            <!-- START:: COURSE TITLE WRAPPER -->
            <div id="iframe_section" class="course_title_wrapper">
                <h3 class="title">{{ subjectData.subject_name }}</h3>
                <h3 class="duration">
                    <span>
                        {{ hours }}
                        {{ $t("TITLES.hours") }}
                    </span>
                    <!-- <span
                        >{{ lessonData.diff_of_time.minute }}
                        {{ $t("TITLES.minute") }}
                    </span> -->
                </h3>
            </div>
            <!-- END:: COURSE TITLE WRAPPER -->

            <!-- START:: VIDEO IFRAME -->
            <div class="video_frame_wrapper">
                <iframe
                    id="video_iframe"
                    :src="`${lessonData.link}`"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <!-- END:: VIDEO IFRAME -->

            <!-- START:: VIDEOS LIST -->
            <ul class="videos_list">
                <!-- START:: LESSON -->
                <li
                    v-for="(video, index) in videos"
                    :key="video.id"
                    class="lesson"
                    @click="playVideo(video.link)"
                >
                    <div class="wrapper">
                        <span class="lesson_number"> #{{ index + 1 }} </span>
                        <p class="lesson_details">
                            <span class="name"> {{ video.name }} </span>
                            <span
                                class="lesson_duration"
                                v-show="video.duration != 0"
                            >
                                <span>
                                    {{ Math.floor(video.duration / 60) }}
                                    {{ $t("TITLES.hour") }}
                                </span>
                            </span>
                            <!-- <span>{{ video.diff_of_time.hour }} {{ $t("TITLES.hours") }} </span>
                                <span>{{ video.diff_of_time.minute }} {{ $t("TITLES.minute") }} </span> -->
                        </p>
                    </div>

                    <span class="play_icon">
                        <i class="fas fa-play-circle"></i>
                    </span>
                </li>
                <!-- END:: LESSON -->
            </ul>
            <!-- END:: VIDEOS LIST -->
        </div>
    </div>
</template>

<script>
export default {
    name: "VideosList",

    data() {
        return {
            playerOptions: {
                height: "360",
                autoplay: true,
                muted: true,
                language: "en",
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                // sources: [{
                //     src: "http://vjs.zencdn.net/v/oceans.mp4",
                // }],
            },

            // START:: LESSON DATA
            subjectData: null,
            lessonData: null,
            videos: [],
            hours: null,
            // END:: LESSON DATA
        };
    },

    methods: {
        // START:: GET COURSE VIDEOS
        getCourseVideo() {
            this.$axios
                .get(`student/listen/${this.$route.params.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.subjectData = res.data.data.subject_name;
                    this.lessonData = res.data.data.listen;
                    this.videos = res.data.data.listens;
                    this.hours = res.data.data.num_of_hour;
                });
        },
        // END:: GET COURSE VIDEOS

        // START:: PLAY VIDEO
        playVideo(url) {
            const iframe_section = document.querySelector("#iframe_section");
            const video_iframe = document.querySelector("#video_iframe");
            video_iframe.src = url;
            iframe_section.scrollIntoView();
        },
        // END:: PLAY VIDEO
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCourseVideo();
        // END:: FIRE METHODS
    },
};
</script>
