<template>
    <div class="student_favs_wrapper">
        <div class="container">
            <Tabs :tabsContent="tabsContent">
                <!-- START:: FAVORITES REGISTERED SUBJECTS -->
                <template #registered_subjects>
                    <!-- START:: MAIN LOADER -->
                    <MainLoader v-if="isLoading" />
                    <!-- END:: MAIN LOADER -->
                    <div class="fadeIn" v-else>
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptyCoursesMessage v-if="recordCourses.length == 0" />
                        <!-- END:: EMPTY COURSES MESSAGE -->

                        <div class="registered_courses_wrapper" v-else>
                            <div class="row">
                                <!-- START:: REGISTERED COURSE CARD -->
                                <div
                                    v-for="course in recordCourses"
                                    :key="course.id"
                                    class="col-lg-3 my-3 fadeIn"
                                >
                                    <!-- START:: COURSE CARD -->
                                    <router-link
                                        :to="`/student/course-details/record/${course.subject.id}`"
                                    >
                                        <ImagedCard>
                                            <template v-slot:card_image>
                                                <img
                                                    :src="course.subject.image"
                                                    :alt="
                                                        course.subject
                                                            .subject_name
                                                            .subject_name
                                                    "
                                                />
                                                <div class="overlay">
                                                    <div
                                                        class="
                                                            instructor_info_wrapper
                                                        "
                                                    >
                                                        <img
                                                            :src="
                                                                course.subject
                                                                    .teacher
                                                                    .image
                                                            "
                                                            :alt="
                                                                course.subject
                                                                    .teacher
                                                                    .fullname
                                                            "
                                                            width="100"
                                                            height="100"
                                                        />

                                                        <div class="text">
                                                            <p class="name">
                                                                {{
                                                                    course
                                                                        .subject
                                                                        .teacher
                                                                        .fullname
                                                                }}
                                                            </p>
                                                            <p class="rate">
                                                                <span>
                                                                    <i
                                                                        class="
                                                                            fas
                                                                            fa-star
                                                                        "
                                                                    ></i>
                                                                </span>
                                                                <span>
                                                                    {{
                                                                        course
                                                                            .subject
                                                                            .avg_rate
                                                                    }}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-slot:card_text>
                                                <div class="wrapper">
                                                    <p class="course_name">
                                                        {{
                                                            course.subject
                                                                .subject_name
                                                                .subject_name
                                                        }}
                                                    </p>
                                                    <p class="duration">
                                                        <span>
                                                            {{
                                                                course.subject
                                                                    .num_of_hour
                                                            }}
                                                            ساعات
                                                        </span>
                                                        <!-- <span
                                                        >{{
                                                            course.duration
                                                                .minutes
                                                        }}
                                                        دقيقة
                                                    </span> -->
                                                    </p>
                                                </div>
                                            </template>
                                        </ImagedCard>
                                    </router-link>
                                    <!-- END:: COURSE CARD -->
                                </div>
                                <!-- END:: REGISTERED COURSE CARD -->
                            </div>
                        </div>
                    </div>
                </template>
                <!-- END:: FAVORITES REGISTERED SUBJECTS -->

                <!-- START:: FAVORITES ONLINE SUBJECTS -->
                <template #online_subjects>
                    <!-- START:: MAIN LOADER -->
                    <MainLoader v-if="isLoading" />
                    <!-- END:: MAIN LOADER -->
                    <div class="fadeIn" v-else>
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptyCoursesMessage v-if="onlineCourses.length == 0" />
                        <!-- END:: EMPTY COURSES MESSAGE -->

                        <div class="online_courses_wrapper" v-else>
                            <div class="row">
                                <!-- START:: ONLINE COURSE CARD -->
                                <div
                                    v-for="course in onlineCourses"
                                    :key="course.id"
                                    class="col-lg-3 my-3 fadeIn"
                                >
                                    <!-- START:: COURSE CARD -->
                                    <router-link
                                        :to="`/student/course-details/online/${course.subject.id}`"
                                    >
                                        <ImagedCard>
                                            <template v-slot:card_image>
                                                <img
                                                    :src="course.subject.image"
                                                    :alt="
                                                        course.subject
                                                            .subject_name
                                                            .subject_name
                                                    "
                                                />
                                                <div class="overlay">
                                                    <div
                                                        class="
                                                            instructor_info_wrapper
                                                        "
                                                    >
                                                        <img
                                                            :src="
                                                                course.subject
                                                                    .teacher
                                                                    .image
                                                            "
                                                            :alt="
                                                                course.subject
                                                                    .teacher
                                                                    .fullname
                                                            "
                                                            width="100"
                                                            height="100"
                                                        />

                                                        <div class="text">
                                                            <p class="name">
                                                                {{
                                                                    course
                                                                        .subject
                                                                        .teacher
                                                                        .fullname
                                                                }}
                                                            </p>
                                                            <p class="rate">
                                                                <span>
                                                                    <i
                                                                        class="
                                                                            fas
                                                                            fa-star
                                                                        "
                                                                    ></i>
                                                                </span>
                                                                <span>
                                                                    {{
                                                                        course
                                                                            .subject
                                                                            .avg_rate
                                                                    }}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-slot:card_text>
                                                <div class="wrapper">
                                                    <p class="course_name">
                                                        {{
                                                            course.subject
                                                                .subject_name
                                                                .subject_name
                                                        }}
                                                    </p>
                                                    <p class="start_date">
                                                        <span class="text">
                                                            {{
                                                                $t(
                                                                    "TITLES.start_date"
                                                                )
                                                            }}
                                                        </span>
                                                        <span class="date">
                                                            {{
                                                                course.subject
                                                                    .start_date
                                                            }}
                                                        </span>
                                                    </p>
                                                </div>

                                                <div class="wrapper">
                                                    <p class="price">
                                                        {{
                                                            course.subject.price
                                                        }}
                                                        {{
                                                            course.subject
                                                                .currency
                                                        }}
                                                    </p>
                                                </div>
                                            </template>
                                        </ImagedCard>
                                    </router-link>
                                    <!-- END:: COURSE CARD -->
                                </div>
                                <!-- END:: ONLINE COURSE CARD -->
                            </div>
                        </div>
                    </div>
                </template>
                <!-- END:: FAVORITES ONLINE SUBJECTS -->

                <!-- START:: FAVORITES PRODUCTS -->
                <template #products>
                    <!-- START:: MAIN LOADER -->
                    <MainLoader v-if="isLoading" />
                    <!-- END:: MAIN LOADER -->
                    <div class="fadeIn" v-else>
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptyFavoritesProducts v-if="products.length == 0" />
                        <!-- END:: EMPTY COURSES MESSAGE -->
                        <div class="row justify-content-center fadeIn" v-else>
                            <!-- START:: STORE ITEM CARD -->
                            <div
                                v-for="item in products"
                                :key="item.id"
                                class="col-lg-3 my-3 fadeIn"
                            >
                                <!-- START:: PRODUCT CARD -->
                                <button
                                    class="store_item_card w-100"
                                    @click="toggleStoreItemModal(item.product)"
                                >
                                    <!-- START:: ITEM IMAGE -->
                                    <div class="item_image_wrapper">
                                        <img
                                            :src="item.product.product_image"
                                            :alt="item.product.name"
                                            width="120"
                                            height="120"
                                        />
                                    </div>
                                    <!-- END:: ITEM IMAGE -->

                                    <!-- START:: ITEM NAME -->
                                    <div class="item_name">
                                        <h3>{{ item.product.name }}</h3>
                                    </div>
                                    <!-- END:: ITEM NAME -->

                                    <!-- START:: ITEM PRICE -->
                                    <div class="item_price">
                                        <h3>
                                            {{ item.product.price }}
                                            {{ item.product.currency }}
                                        </h3>
                                    </div>
                                    <!-- END:: ITEM PRICE -->
                                </button>
                                <!-- END:: PRODUCT CARD -->
                            </div>
                            <!-- END:: STORE ITEM CARD -->
                        </div>
                    </div>
                    <!-- START:: STORE ITEM MODAL -->
                    <StoreItemModal
                        :modalApperanceData="storeItemModalIsOpen"
                        :selectedItem="selectedStoreItem"
                        @controleModalApperance="toggleStoreItemModal"
                        @getStudentFavorites="getStudentFavorites"
                    />
                    <!-- END:: STORE ITEM MODAL -->
                </template>
                <!-- END:: FAVORITES PRODUCTS -->
            </Tabs>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// START:: IMPORTING VUEX HELPERS

// START:: IMPORTING EMPTY MESSAGES
import EmptyCoursesMessage from "../../components/ui/emptyMessages/EmptyCoursesMessage.vue";
import EmptyFavoritesProducts from "../../components/ui/emptyMessages/EmptyFavoritesProducts.vue";
// END:: IMPORTING EMPTY MESSAGES

// START:: IMPORTING TABS
import Tabs from "../../components/structure/Tabs.vue";
// END:: IMPORTING TABS

// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD

// START:: IMPORTING MODALS
import StoreItemModal from "../../components/modals/StoreItemModal.vue";
// END:: IMPORTING MODALS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "StudentFavorites",

    components: {
        Tabs,
        ImagedCard,
        StoreItemModal,
        EmptyCoursesMessage,
        EmptyFavoritesProducts,
        MainLoader,
    },

    computed: {
        // START:: VUEX GET WISHLIST DATA
        ...mapGetters("StoreAndWishlistModule", ["getWishlistItems"]),
        // END:: VUEX GET WISHLIST DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: TABS CONTENT
            tabsContent: [
                {
                    tab: this.$t("TABS.registered_subjects"),
                    key_name: "registered_subjects",
                },
                {
                    tab: this.$t("TABS.online_subjects"),
                    key_name: "online_subjects",
                },
                {
                    tab: this.$t("TABS.products"),
                    key_name: "products",
                },
            ],
            // END:: TABS CONTENT

            // START:: FAVORITES ITEMS
            recordCourses: null,
            onlineCourses: null,
            products: null,
            // END:: FAVORITES ITEMS

            // START:: MODALS HANDLING DATA
            storeItemModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SELECTED STORE ITEM
            selectedStoreItem: null,
            // END:: SELECTED STORE ITEM
        };
    },

    methods: {
        // START:: TOGGLE STORE ITEM MODAL METHOD
        toggleStoreItemModal(selectedItem) {
            this.storeItemModalIsOpen = !this.storeItemModalIsOpen;

            // START:: SET MODAL ITEM TO SHOW
            this.selectedStoreItem = selectedItem;
            // END:: SET MODAL ITEM TO SHOW
        },
        // END:: TOGGLE STORE ITEM MODAL METHOD

        // START:: GET STUDENT FAVORITES
        getStudentFavorites() {
            // alert("Testttttttttt");
            this.isLoading = true;
            this.$axios
                .get(`student/favourite`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.recordCourses = res.data.data.record;
                    this.onlineCourses = res.data.data.online;
                    this.products = res.data.data.product;
                    this.isLoading = false;
                    // this.favItems = res.data.data;
                });
        },
        // END:: GET STUDENT FAVORITES
    },

    mounted() {
        this.getStudentFavorites();
    },
};
</script>
