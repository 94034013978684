var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured_courses_wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section_title_wrapper"},[_c('h2',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/media/shapes/title_icon.svg"),"alt":"Title Icon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("TITLES.recently_added"))+" ")])]),_c('div',{staticClass:"route_wrapper"},[_c('router-link',{attrs:{"to":"/course-section/distance-learning-lessons"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.show_all"))+" ")])],1)]),_c('div',{staticClass:"featured_courses_slider_wrapper mt-5"},[_c('carousel',{attrs:{"dir":"rtl","nav":false,"dots":true,"items":4,"margin":15,"loop":false,"autoplay":true,"autoplayTimeout":6000,"dragEndSpeed":2000,"smartSpeed":2000,"responsive":{
                    0: {
                        items: 1,
                    },
                    750: {
                        items: 2,
                    },
                    900: {
                        items: 4,
                    },
                }}},_vm._l((_vm.Items),function(course){return _c('div',{key:course.id,staticClass:"slider_image_wrapper",attrs:{"dir":_vm.getAppLocale == 'ar' ? 'rtl' : 'ltr'}},[_c('router-link',{attrs:{"to":'/courses-categories/' + course.id}},[_c('ImagedCard',{scopedSlots:_vm._u([{key:"card_image",fn:function(){return [_c('img',{attrs:{"src":course.specialization_ar,"alt":"Course Category Image"}})]},proxy:true},{key:"card_text",fn:function(){return [_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"course_name"},[_vm._v(" "+_vm._s(course.subject_name)+" ")]),_c('p',{staticClass:"courses_count"},[_vm._v(" "+_vm._s(course.num_of_courses)+" "),_c('span',{staticClass:"font-weight-light text-secondary"},[_vm._v("كورس")])])]),_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"teachers_count"},[_vm._v(" "+_vm._s(course.num_of_teachers)+" "),_c('span',{staticClass:"font-weight-light text-secondary"},[_vm._v("معلم")])])])]},proxy:true}],null,true)})],1)],1)}),0)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }