var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.last_page > 1)?_c('div',{staticClass:"pagenation"},[_c('ul',_vm._l((_vm.last_page),function(index){return (
                Math.abs(_vm.current_page - index) < 4 ||
                index == _vm.last_page ||
                index == 0
            )?_c('li',{key:index,class:{
                last:
                    index == _vm.last_page &&
                    Math.abs(_vm.current_page - index) > 4,
            }},[_c('button',{class:{
                    active: index == _vm.current_page,
                    last:
                        _vm.last_page == index &&
                        Math.abs(_vm.current_page - index) > 4,
                },attrs:{"type":"button"},on:{"click":function($event){return _vm.pagenationClick(index)}}},[_vm._v(" "+_vm._s(index)+" ")])]):_vm._e()}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }