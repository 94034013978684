<template>
    <div class="withdraw_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title> {{ $t("TITLES.withdraw") }} </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.withdraw") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="withdraw_card_wrapper fadeIn" v-else>
            <div class="container">
                <div class="row">
                    <!-- START:: FORM -->
                    <form class="mt-4" @submit.prevent="selectBankValidate">
                        <div class="row justify-content-center">
                            <!-- START:: AMOUNT VALUE INPUT -->
                            <div class="input_wrapper">
                                <input
                                    type="number"
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.amount_value')
                                    "
                                    v-model="valueRent"
                                />
                            </div>
                            <!-- END:: AMOUNT VALUE INPUT -->

                            <!-- START:: TITLE -->
                            <div class="page_small_title">
                                <h3>{{ $t("TITLES.choose_bank") }}</h3>
                            </div>
                            <!-- END:: TITLE -->

                            <!-- START:: CHOOSE BANK RADIO BUTTONS -->
                            <div class="row">
                                <div
                                    class="col-md-6"
                                    v-for="item in myAccounts"
                                    :key="item.id"
                                >
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <div class="text-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="bank"
                                                    :value="item.id"
                                                    v-model="withDrow.bank"
                                                />
                                                {{ $t("PLACEHOLDERS.iban") }}
                                            </div>
                                            <div class="flex-check">
                                                <span>{{
                                                    item.iban_number
                                                }}</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- START:: CHOOSE BANK RADIO BUTTONS -->

                            <!-- START::  -->
                            <div class="withdraw_btns">
                                <button>
                                    {{ $t("BUTTONS.confirm") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequest"
                                    ></span>
                                </button>
                                <button
                                    type="button"
                                    @click="toggleAddBankAccountModal"
                                >
                                    {{ $t("BUTTONS.add_account") }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- START:: ADD BANK ACCOUNT MODAL -->
        <AddBankAccount
            :modalApperanceData="addBankAccountModalIsOpen"
            @controleModalApperance="toggleAddBankAccountModal"
        />
        <!-- END:: ADD BANK ACCOUNT -->

        <!-- START:: SUCCESS MODAL -->
        <SuccessModal
            :modalApperanceData="confirmBankAccountModalIsOpen"
            @controleModalApperance="toggleConfirmBankAccountModal"
        >
            <template #title>
                {{ $t("TITLES.done_withdraw") }}
            </template>
            <template #sub_title>
                {{ $t("TITLES.review_bank") }}
            </template>
            <template #success_btn>
                <router-link to="/">
                    {{ $t("TITLES.home") }}
                </router-link>
            </template>
        </SuccessModal>
        <!-- END:: SUCCESS MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING MODALS
import AddBankAccount from "../components/modals/AddBankAccount.vue";
// END:: IMPORTING MODALS

// START:: IMPORTING MODALS
import SuccessModal from "../components/modals/SuccessModal.vue";
// END:: IMPORTING MODALS
// START:: IMPORTING LOADERS
import MainLoader from "../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "Withdraw",

    components: {
        Breadcrumb,
        AddBankAccount,
        SuccessModal,
        MainLoader,
    },

    data() {
        return {
            // START:: MODALS HANDLING DATA
            addBankAccountModalIsOpen: false,
            confirmBankAccountModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: LOADER WAIT
            isWaitingRequest: false,
            // END:: LOADER WAIT

            // START:: WITHDRAW DATA
            withDrow: {
                bank: null,
            },
            myAccounts: "",
            isLoading: false,
            valueRent: "",
            // END:: WITHDRAW DATA
        };
    },

    methods: {
        // START:: TOGGLE ADD BANK ACCOUNT MODAL METHOD
        toggleAddBankAccountModal() {
            this.addBankAccountModalIsOpen = !this.addBankAccountModalIsOpen;
        },
        toggleConfirmBankAccountModal() {
            this.confirmBankAccountModalIsOpen =
                !this.confirmBankAccountModalIsOpen;
        },
        // END:: TOGGLE ADD BANK ACCOUNT MODAL METHOD

        // START:: VALIDATE SELECT BANK
        selectBankValidate() {
            this.isWaitingRequest = true;
            if (!this.withDrow.bank) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.select_account"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.selectBankForm();
            }
        },
        // END:: VALIDATE SELECT BANK

        // START:: SUBMIT SELECT BANK
        selectBankForm() {
            // START:: SEND REQUEST
            this.isWaitingRequest = true;
            const theData = new FormData();
            // START:: APPEND CONTACT DATA
            theData.append("value", this.valueRent);
            theData.append("bank_account_id", this.withDrow.bank);
            // END:: APPEND CONTACT DATA
            this.$axios
                .post("user/wallet/withdrawals", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;
                    this.toggleConfirmBankAccountModal();
                })
                .catch((err) => {
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.isWaitingRequest = false;
                    return;
                });
        },
        // END:: SUBMIT SELECT BANK
        getMyAccounts() {
            this.isLoading = true;
            this.$axios
                .get(`user/bank_account`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.myAccounts = res.data.data;
                    this.isLoading = false;
                });
        },
    },
    mounted() {
        this.getMyAccounts();
    },
};
</script>
