<template>
    <div class="payment_form_wrapper">
        <!-- START:: FORM WRAPPER -->
        <form class="mt-5" @submit.prevent="validateFormInputs">
            <!-- START:: PAYMENT CARD SELECT -->
            <div class="input_wrapper imaged_radio my-3">
                <div class="form-check">
                    <div class="check_wrapper">
                        <input
                            id="visa"
                            class="form-check-input"
                            type="radio"
                            name="payment_card_select"
                            value="visa"
                            v-model="paymentData.cardType"
                        />
                        <label class="form-check-label" for="visa">
                            <img
                                src="../../assets/media/payment/visa.svg"
                                alt="Visa Logo"
                                width="90"
                                height="60"
                            />
                        </label>
                    </div>

                    <div class="check_wrapper" v-if="userType == 'teacher'">
                        <input
                            id="mastercard"
                            class="form-check-input"
                            type="radio"
                            name="payment_card_select"
                            value="mastercard"
                            v-model="paymentData.cardType"
                        />

                        <label class="form-check-label" for="mastercard">
                            <img
                                src="../../assets/media/payment/mastercard.svg"
                                alt="MasterCard Logo"
                                width="90"
                                height="60"
                            />
                        </label>
                    </div>
                </div>
            </div>
            <!-- END:: PAYMENT CARD SELECT -->

            <div class="row">
                <!-- START:: NAME INPUT -->
                <div class="col-12 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.name_on_card')"
                            v-model.trim="paymentData.name"
                        />
                    </div>
                </div>
                <!-- END:: NAME INPUT -->

                <!-- START:: CARD NUMBER INPUT -->
                <div class="col-12 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.number_on_card')"
                            v-model.trim="paymentData.cardNumber"
                        />
                    </div>
                </div>
                <!-- END:: CARD NUMBER INPUT -->

                <!-- START:: EXP DATE INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.exp_date')"
                            v-model.trim="paymentData.expDate"
                        />
                    </div>
                </div>
                <!-- END:: EXP DATE INPUT -->

                <!-- START:: CVV INPUT -->
                <div class="col-lg-6 my-3">
                    <div class="input_wrapper">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="CVV"
                            v-model.trim="paymentData.cvv"
                        />
                    </div>
                </div>
                <!-- END:: CVV INPUT -->
            </div>

            <!-- START:: SUBMIT BUTTON WRAPPER -->
            <div class="col-12 btn_wrapper my-3">
                <button class="w-100 mt-0">
                    {{ $t("BUTTONS.confirm_payment") }}
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                </button>
            </div>
            <!-- END:: SUBMIT BUTTON WRAPPER -->
        </form>
        <!-- END:: FORM WRAPPER -->

        <!-- START:: SUCCESS MODAL -->
        <SuccessModal
            :modalApperanceData="successPaymentModalIsOpen"
            @controleModalApperance="toggleSuccessPaymentModal"
        >
            <template #title>
                {{ $t("TITLES.course_registered_successfully") }}
            </template>
            <template #sub_title>
                {{ $t("TITLES.course_registered_successfully_subtitle") }}
            </template>
            <template #success_btn>
                <router-link to="/my-courses">
                    {{ $t("BUTTONS.continue_to_my_courses") }}
                </router-link>
            </template>
        </SuccessModal>
        <!-- END:: SUCCESS MODAL -->

        <!-- START:: STORE PAYMENT SUCCESS MODAL -->
        <SuccessModal
            :modalApperanceData="successStorePaymentModalIsOpen"
            @controleModalApperance="toggleSuccessStorePaymentModal"
        >
            <template #title>
                {{ $t("TITLES.paied_successfully") }}
            </template>
            <template #sub_title>
                {{ $t("TITLES.paied_successfully_subtitle") }}
            </template>
            <template #success_btn>
                <router-link to="/">
                    {{ $t("BUTTONS.home") }}
                </router-link>
            </template>
        </SuccessModal>
        <!-- END:: STORE PAYMENT SUCCESS MODAL -->
    </div>
</template>

<script>
// START:: IMPORTING MODALS
import SuccessModal from "../../components/modals/SuccessModal.vue";
// END:: IMPORTING MODALS

export default {
    name: "CourseRegisterPayment",

    components: {
        SuccessModal,
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            // START:: CURRENT ROUTE DATA
            currentRoute: this.$route.path,
            // END:: CURRENT ROUTE DATA

            // START:: MODALS HANDLING DATA
            successPaymentModalIsOpen: false,
            successStorePaymentModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: PAYMENT DATA
            paymentData: {
                cardType: null,
                name: null,
                cardNumber: null,
                expDate: null,
                cvv: null,
            },
            // END:: PAYMENT DATA
        };
    },

    methods: {
        // START:: VALIDATE FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;
            this.submitForm();
        },
        // END:: VALIDATE FORM INPUTS

        // START:: SUBMIT FORM
        submitForm() {
            setTimeout(() => {
                this.isWaitingRequest = false;

                if (this.currentRoute == "/payment") {
                    this.toggleSuccessStorePaymentModal();
                } else {
                    this.toggleSuccessPaymentModal();
                }
            }, 1500);
        },
        // END:: SUBMIT FORM

        // START:: TOGGLE SUCCESS MODAL METHOD
        toggleSuccessPaymentModal() {
            this.successPaymentModalIsOpen = !this.successPaymentModalIsOpen;
        },
        toggleSuccessStorePaymentModal() {
            this.successStorePaymentModalIsOpen =
                !this.successStorePaymentModalIsOpen;
        },
        // END:: TOGGLE SUCCESS MODAL METHOD
    },
};
</script>
