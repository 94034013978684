<template :key="$route.path">
    <div class="chats_list_wrapper">
        <!-- START:: EMPTY CHATS LIST MESSAGE -->
        <EmptyChatsListMessage v-if="chatsListData.length == 0" />
        <!-- END:: EMPTY CHATS LIST MESSAGE -->

        <!-- START:: CHAT ROUTE -->
        <template v-else>
            <div v-for="chat in chatsListData" :key="chat.id">
                <!-- START:: CHAT SKELETON LOADER -->
                <ChatCardSkeletonLoader v-if="!chatsListData" />
                <!-- END:: CHAT SKELETON LOADER -->

                <!-- START:: CHAT CARD -->
                <router-link
                    :to="`/chat/${chat.user_data.id}`"
                    class="chat_route"
                    v-else
                >
                    <!-- START:: AVATAR -->
                    <div class="avatar_wrapper">
                        <img
                            :src="chat.user_data.image"
                            width="80"
                            height="80"
                        />
                    </div>
                    <!-- END:: AVATAR -->

                    <!-- START:: MESSAGE DETAILS -->
                    <div class="message_details">
                        <!-- START:: NAME AND DATE -->
                        <h3 class="name_and_date">
                            <span class="name">
                                {{ chat.user_data.fullname }}
                            </span>
                            <span class="date"> {{ chat.ago_time }} </span>
                        </h3>
                        <!-- END:: NAME AND DATE -->

                        <!-- START:: LAST MESSAGE -->
                        <div class="last_message">
                            {{ chat.last_message }}
                        </div>
                        <!-- END:: LAST MESSAGE -->
                    </div>
                    <!-- END:: MESSAGE DETAILS -->
                </router-link>
                <!-- END:: CHAT CARD -->
            </div>
        </template>
        <!-- END:: CHAT ROUTE -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START::IMPORTING LOADERS
import ChatCardSkeletonLoader from "../ui/loaders/ChatCardSkeletonLoader.vue";
// END::IMPORTING LOADERS

// START:: IMPORTING EMPTY CHATS LIST MESSAGE
import EmptyChatsListMessage from "../ui/emptyMessages/EmptyChatsListMessage.vue";
// END:: IMPORTING EMPTY CHATS LIST MESSAGE

import avatar from "../../assets/media/images/user_avatar.png";

export default {
    name: "ChatsList",

    components: {
        EmptyChatsListMessage,
        ChatCardSkeletonLoader,
    },

    computed: {
        // START:: VUEX CHAT DATA
        ...mapGetters("ChatModule", ["chatsListData"]),
        // END:: VUEX CHAT DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: CHATS LIST
            chatsList: [
                {
                    id: 1,
                    Image: avatar,
                    name: "عبد الله جابر محرم",
                    date: "17/9/2020",
                    lastMessage:
                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam necessitatibus, quasi cum impedit rem beatae accusamus eius sit deleniti? Quae esse ratione rem at rerum unde, cupiditate earum animi labore",
                },
                {
                    id: 2,
                    Image: avatar,
                    name: "عبد الله جابر محرم",
                    date: "17/9/2020",
                    lastMessage:
                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam necessitatibus, quasi cum impedit rem beatae accusamus eius sit deleniti? Quae esse ratione rem at rerum unde, cupiditate earum animi labore",
                },
                {
                    id: 3,
                    Image: avatar,
                    name: "عبد الله جابر محرم",
                    date: "17/9/2020",
                    lastMessage:
                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam necessitatibus, quasi cum impedit rem beatae accusamus eius sit deleniti? Quae esse ratione rem at rerum unde, cupiditate earum animi labore",
                },
                {
                    id: 4,
                    Image: avatar,
                    name: "عبد الله جابر محرم",
                    date: "17/9/2020",
                    lastMessage:
                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam necessitatibus, quasi cum impedit rem beatae accusamus eius sit deleniti? Quae esse ratione rem at rerum unde, cupiditate earum animi labore",
                },
            ],
            // END:: CHATS LIST
        };
    },

    methods: {
        // START:: VUEX CHAT SEND MESSAGE
        ...mapActions("ChatModule", ["getChatsList"]),
        // END:: VUEX CHAT SEND MESSAGE
    },

    mounted() {
        // START:: FIRE METHODS
        this.getChatsList();
        // END:: FIRE METHODS

        // setTimeout(() => {
        //     this.isLoading = false;
        // }, 1500);
    },
};
</script>
