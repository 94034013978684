<template>
    <div class="add_subject_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.add_subject") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.add_subject") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <div class="add_subject_card_wrapper">
            <div class="container">
                <div class="row">
                    <div class="tabs_add_subject">
                        <ul>
                            <li>
                                <router-link to="/add-subject" :class="{
                                    'router-link-exact-active':
                                        $route.path ==
                                        '/add-subject/step-two',
                                }">1</router-link>
                                <h4>{{ $t("TITLES.subject_details") }}</h4>
                            </li>
                            <li>
                                <router-link disabled to="/add-subject/step-two">2</router-link>
                                <h4>{{ $t("TITLES.subject_lessons") }}</h4>
                            </li>
                            <li>
                                <router-link disabled to="/add-subject/step-three">3</router-link>
                                <h4>{{ $t("TITLES.subject_quizez") }}</h4>
                            </li>
                        </ul>
                    </div>

                    <div class="tab_content_add_subject">
                        <keep-alive>
                            <router-view></router-view>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "addSubject",

    components: {
        Breadcrumb,
    },

    data() {
        return {};
    },

    methods: {},
};
</script>
