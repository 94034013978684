<template :key="$route.path">
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: HOME PAGE CONTENT -->
        <div class="home_wrapper fadeIn" v-else>
            <!-- START:: HERO SECTION -->
            <HeroSlider :sliderData="homeData.sliders" v-if="homeData" />
            <!-- END:: HERO SECTION -->

            <!-- START:: ABOUT US -->
            <!-- <transition
                appear
                @before-enter="fadeInLeftBeforeEnter"
                @enter="fadeInLeftEnter"
            >
            </transition> -->
            <AboutSection :aboutUsData="homeData.about" v-if="homeData" />
            <!-- END:: ABOUT US -->

            <!-- START:: COURSES CATEGORIES -->
            <!-- <transition
                appear
                @before-enter="fadeInLeftBeforeEnter"
                @enter="fadeInLeftEnter"
            >
            </transition> -->

            <!-- <CoursesCategories
                :courseCatsData="homeData.categories"
                v-if="
                    getAuthenticatedUserData.token &&
                    getAuthenticatedUserData.type != 'parent'
                "
            /> -->
            <CoursesCategories
                :courseCatsData="homeData.categories"
                v-if="
                    getAuthenticatedUserData.token &&
                    getAuthenticatedUserData.type != 'parent'
                "
            />
            <!-- END:: COURSES CATEGORIES -->

            <!-- ================= START:: FEATURED COURSES ================= -->
            <!-- START:: PARENT FEATURED COURSES -->
            <ParentFeaturedCourses
                :Items="homeData.my_children"
                v-if="getAuthenticatedUserData.type == 'parent'"
            />
            <!-- END:: PARENT FEATURED COURSES -->

            <!-- START:: STUDENT FEATURED COURSES -->

            <!-- <StudentFeaturedCourses
                :Items="homeData.newer_subject_name"
                v-if="getAuthenticatedUserData.type == 'student'"
            /> -->
            <StudentFeaturedTeachers
                :Items="homeData.teachers"
                v-if="getAuthenticatedUserData.type == 'student'"
            />
            
              <StudentFeaturedCourses
                :Items="homeData.newer_subject_name"
                v-if="getAuthenticatedUserData.type != 'parent' && getAuthenticatedUserData.type != 'teacher'"
            />

            
            <!-- END:: STUDENT FEATURED COURSES -->

            <!-- START:: TEACHER FEATURED COURSES -->
            <TeacherFeaturedCourses
                :Items="homeData.my_subjects"
                v-if="getAuthenticatedUserData.type == 'teacher'"
            />
            <!-- END:: TEACHER FEATURED COURSES -->
            <!-- ================= END:: FEATURED COURSES ================= -->

            <!-- START:: CONTACT US -->
            <ContactUs :contactUsData="homeData.contacts" v-if="homeData" />
            <!-- END:: CONTACT US -->
        </div>
        <!-- END:: HOME PAGE CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING LOADERS
import MainLoader from "../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

// START:: IMPORTING HOME COMPS
import HeroSlider from "../components/structure/TheSlider.vue";
import AboutSection from "../components/general/AboutUs.vue";
import CoursesCategories from "../components/general/CoursesCats.vue";
import ParentFeaturedCourses from "../components/general/ParentFeaturedCourses.vue";
import StudentFeaturedCourses from "../components/general/StudentFeaturedCourses.vue";
import StudentFeaturedTeachers from "../components/general/StudentFeaturedTeachers.vue";
import TeacherFeaturedCourses from "../components/general/TeacherFeaturedCourses.vue";
import ContactUs from "../components/general/ContactUs.vue";
// END:: IMPORTING HOME COMPS

export default {
    name: "HomePage",

    components: {
    MainLoader,
    HeroSlider,
    AboutSection,
    CoursesCategories,
    ParentFeaturedCourses,
    StudentFeaturedCourses,
    StudentFeaturedTeachers,
    TeacherFeaturedCourses,
    ContactUs,
},

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: HOME DATA
            homeData: null,
            // END:: HOME DATA
        };
    },

    methods: {
        fadeInLeftBeforeEnter(el) {
            // SET INITIAL STATE
            el.style.transform = "translateX(-80px)";
            el.style.opacity = 0;
        },

        fadeInLeftEnter(el, done) {
            // GSAP TRANSITIONING
            this.gsap.to(el, {
                scrollTrigger: {
                    trigger: el,
                    toggleActions: "play none none none",
                },
                duration: 1,
                x: 0,
                opacity: 1,
                ease: "bounce.out",
                onComplete: done,
            });
        },

        // START:: GET HOME PAGE DATA
        getHomeData() {
            // START:: GET TEACHER HOME DATA
            this.isLoading = true;
            var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
            if (userType == "teacher") {
                this.$axios
                    .get("teacher/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            } else if (userType == "student") {
                this.$axios
                    .get("student/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            } else if (userType == "parent") {
                this.$axios
                    .get("parent/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            } else {
                this.$axios
                    .get("visitor/home", {
                        headers: {
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.homeData = res.data.data;
                    });
            }
            // END:: GET TEACHER HOME DATA
        },
        // END:: GET HOME PAGE DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getHomeData();
        // END:: FIRE METHODS
    },
};
</script>
