<template>
    <div class="featured_courses_wrapper">
        <div class="container">
            <!-- START:: HEADER WRAPPER -->
            <div class="section_title_wrapper">
                <h2 class="title">
                    <img src="../../assets/media/shapes/title_icon.svg" alt="Title Icon" />
                    <span> {{ $t("TITLES.teachers") }} </span>
                </h2>

                <div class="route_wrapper">
                    <router-link to="/teachers">
                        {{ $t("BUTTONS.show_all") }}
                    </router-link>
                </div>
            </div>
            <!-- END:: HEADER WRAPPER -->

            <!-- START:: FEATURED TEACHER SLIDER -->
            <div class="featured_courses_slider_wrapper mt-5">
                <carousel dir="rtl" :nav="false" :dots="true" :items="4" :margin="15" :loop="false" :autoplay="true"
                    :autoplayTimeout="6000" :dragEndSpeed="2000" :smartSpeed="2000" :responsive="{
                        0: {
                            items: 1,
                        },
                        750: {
                            items: 2,
                        },
                        900: {
                            items: 4,
                        },
                    }">
                    <div :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'" class="slider_image_wrapper" v-for="teacher in Items"
                        :key="teacher.id">
                            <SolidCard class="col-10">
                            <router-link :to="`/teacher/${teacher.id}`" class="card_content_wrapper row bg-white py-3">

                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 m-auto ">
                                    <img :src="teacher.profile_image" :alt="teacher.user_type" class="rounded"  />
                                </div>
                                <div class="col mt-5">
                                    <h2 class="font-weight-bold">{{ teacher.fullname }}</h2>
                                    <div class="d-flex">
                                        <div v-for="(specialization) in teacher.specializations.slice(0, 2)"
                                            :key="specialization.id" :value="id">
                                            <h4 class="text-secondary">

                                                {{ specialization.name }}
                                                <span
                                                    v-if="teacher.specializations.length > 1"
                                                    class="text-secondary"> / </span>
                                            </h4>

                                        </div>
                                        <div v-if="teacher.specializations.length > 2">
                                            <h4 class="text-secondary">...</h4>
                                        </div>
                                    </div>
                                </div>

                            </router-link>
                        </SolidCard>
                    </div>
                </carousel>
            </div>
            <!-- END:: FEATURED TEACHER SLIDER -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";
import SolidCard from "@/components/ui/SolidCard.vue";

export default {
    name: "StudentFeaturedTeachers",

    components: {
        carousel,
        SolidCard
    },

    computed: {
        ...mapGetters("AppLangModule", ["getAppLocale"]),
    },
    props: ["Items"],
};
</script>
