<template>
    <div class="course_register_payment_form_wrapper">
        <!-- START:: FORM TITLE -->
        <h3 class="form_title">{{ $t("TITLES.register_course") }}</h3>
        <h3 class="form_subtitle">
            {{ $t("TITLES.enter_data_to_register_course") }}
        </h3>
        <!-- END:: FORM TITLE -->

        <!-- START:: FORM WRAPPER -->
        <form class="mt-5" @submit.prevent="validateFormInputs">
            <!-- START:: QUALIFICATIONS INPUT -->
            <div class="input_wrapper my-3">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.qualifications')"
                    v-model.trim="courseRegisterData.qualifications"
                />
            </div>
            <!-- END:: QUALIFICATIONS INPUT -->

            <!-- START:: CAREER STATUS INPUT -->
            <div class="input_wrapper select_wrapper my-3">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.career_status')"
                    v-model.trim="courseRegisterData.careerStatus"
                />
            </div>
            <!-- END:: CAREER STATUS INPUT -->

            <!-- START:: RADIO INPUTS -->
            <div class="input_wrapper radio my-3">
                <h4 class="radio_title">
                    {{ $t("TITLES.course_register_question") }}
                </h4>

                <div class="form-check">
                    <label class="form-check-label">
                        <div class="text-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="course_register_question"
                                value="1"
                                v-model="courseRegisterData.registerQuestion"
                            />
                            {{ $t("PLACEHOLDERS.yes") }}
                        </div>
                    </label>

                    <label class="form-check-label">
                        <div class="text-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="course_register_question"
                                value="0"
                                v-model="courseRegisterData.registerQuestion"
                            />
                            {{ $t("PLACEHOLDERS.no") }}
                        </div>
                    </label>
                </div>
            </div>
            <!-- END:: RADIO INPUTS -->

            <!-- START:: SUBMIT BUTTON WRAPPER -->
            <div class="btn_wrapper my-3">
                <button class="w-100 mt-0">
                    {{ $t("BUTTONS.submit") }}
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                </button>
            </div>
            <!-- END:: SUBMIT BUTTON WRAPPER -->
        </form>
        <!-- END:: FORM WRAPPER -->
    </div>
</template>

<script>
export default {
    name: "CourseRegisterPayment",

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            // START:: COURSE REGISTER DATA
            courseRegisterData: {
                qualifications: null,
                careerStatus: null,
                registerQuestion: null,
                subject_subscription_id: null,
            },
            // END:: COURSE REGISTER DATA
        };
    },

    methods: {
        // START:: VALIDATE FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;
            this.submitForm();
        },
        // END:: VALIDATE FORM INPUTS
        submitForm() {
            const theData = new FormData();
            theData.append(
                "qualifications",
                this.courseRegisterData.qualifications
            );
            theData.append(
                "professional_status",
                this.courseRegisterData.careerStatus
            );
            theData.append(
                "attending_online_course_before",
                this.courseRegisterData.registerQuestion
            );
            theData.append("subject_id", this.$route.params.id);

            if (this.userType == "teacher") {
                // START:: SEND REQUEST
                this.$axios
                    .post("teacher/category/subject/payment", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.courseRegisterData.subject_subscription_id =
                            res.data.data.id;
                        this.paymentMethods();

                        return;
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;
                        this.$iziToast.error({
                            timeout: 2000,
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                            message: err.response.data,
                            messageSize: "22",
                        });
                        return;
                    });
                // END:: SEND REQUEST
            } else if (this.userType == "student") {
                // START:: SEND REQUEST
                this.$axios
                    .post("student/subject/payment", theData, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.courseRegisterData.subject_subscription_id =
                            res.data.data.id;
                        this.paymentMethods();

                        return;
                    })
                    .catch((err) => {
                        this.isWaitingRequest = false;
                        this.$iziToast.error({
                            timeout: 2000,
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                            message: err.response.data.message,
                            messageSize: "22",
                        });
                        return;
                    });
                // END:: SEND REQUEST
            }
        },
        // END:: SUBMIT FORM
        paymentMethods() {
            const theData = new FormData();
            theData.append("transactionId", "gbuibubjkk");
            theData.append("pay_type", "visa");
            theData.append(
                "subject_subscription_id",
                this.courseRegisterData.subject_subscription_id
            );
            // START:: SEND REQUEST
            this.$axios
                .post("teacher/category/subject/complete-payment", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.$router.replace("/my-courses");
                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("VALIDATION.paymentSuccess"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.isWaitingRequest = false;
                    this.$router.replace("/my-courses");
                    return;
                })
                .catch(() => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: this.$t("VALIDATION.login_failed"),
                        messageSize: "22",
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
    },
};
</script>
