<template>
    <form class="set_rating_form">
        <h3 class="title">
            {{ $t("TITLES.add_rate") }}
        </h3>

        <!-- START:: RATE STARS -->
        <div class="star_wrapper">
            <SetRate
                dir="ltr"
                :border-width="0"
                border-color="#d8d8d8"
                :rounded-corners="false"
                :star-points="[
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                ]"
                :star-size="45"
                v-model="rateData.stars"
            />
        </div>
        <!-- END:: RATE STARS -->

        <!-- START:: COMMENT INPUT GROUP -->
        <div class="col-lg-12 my-3">
            <div class="input_wrapper">
                <textarea
                    class="form-control"
                    :placeholder="$t('PLACEHOLDERS.write_here')"
                    v-model.trim="rateData.comment"
                    rows="3"
                ></textarea>
            </div>
        </div>
        <!-- END:: COMMENT INPUT GROUP -->

        <!-- START:: SUBMIT BUTTON WRAPPER -->
        <div class="btn_wrapper">
            <button type="button" @click="validateRateCourse">
                {{ $t("BUTTONS.send") }}
                <span class="btn_loader" v-if="isWaitingRequest"></span>
            </button>
        </div>
        <!-- END:: SUBMIT BUTTON WRAPPER -->
    </form>
</template>

<script>
// START:: IMPORTING STAR RATING PLUGIN
import SetRate from "vue-star-rating";
// END:: IMPORTING STAR RATING PLUGIN

export default {
    name: "SetCourseRate",

    components: {
        SetRate,
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: RATE DATA
            rateData: {
                stars: null,
                comment: null,
            },
            // END:: RATE DATA
        };
    },

    methods: {
        // START:: VALIDATE STEP ONE
        validateRateCourse() {
            this.isWaitingRequest = true;
            if (!this.rateData.stars) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.starts"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.rateData.comment) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.commentRate"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.rateCourse();
            }
        },
        // END:: VALIDATE STEP ONE

        // START:: SUBMIT EDIT COURSE FORM
        rateCourse() {
            // START:: SEND REQUEST
            const theData = new FormData();
            // START:: APPEND CONTACT DATA
            theData.append("sub_id", this.$route.params.id);
            theData.append("rate", this.rateData.stars);
            theData.append("content", this.rateData.comment);
            // END:: APPEND CONTACT DATA

            // START:: SEND REQUEST
            this.$axios
                .post("user/rate", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;
                    location.reload();
                })
                .catch((err) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT EDIT COURSE FORM
    },
};
</script>
