<template>
    <div class="add_subject_steps_wrapper">
        <div class="add_subject_steps_card_wrapper">
            <div class="container">
                <form @submit.prevent="validateForm">
                    <div class="row justify-content-center">
                        <div class="day_card fadeIn" v-for="(questions, index) in newQuestionArray" :key="questions.id">
                            <div class="page_small_title">
                                <h3>السؤال #{{ index + 1 }}</h3>
                            </div>
                            <div class="my-3 input_wrapper">
                                <input type="text" class="form-control" :placeholder="$t('PLACEHOLDERS.question')"
                                    v-model.trim="questions.questions" />
                            </div>
                            <div class="my-3 input_wrapper" v-for="(answer, answerIndex) in questions.answers"
                                :key="answerIndex">
                                <input type="text" class="form-control" :placeholder="$t('PLACEHOLDERS.choice')"
                                    v-model.trim="questions.answers[answerIndex]" />
                            </div>
                            <!-- <div class="my-3 input_wrapper">
                                <input type="text" class="form-control" :placeholder="$t('PLACEHOLDERS.correct_answer')"
                                    v-model.trim="questions.correct_answer" />
                            </div> -->
                            <div class="my-3 input_wrapper">
                                <select class="form-control" v-model="questions.correct_answer">
                                    <option value="" disabled>الإجابة الصحيحة</option>
                                    <option v-for="(answer, answerIndex) in questions.answers" :value="answerIndex"
                                        :key="answerIndex">{{ answer }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-7 my-3">
                            <div class="btn_wrapper solid">
                                <button type="button" class="w-100 mt-0 add_day_btn" @click="addNewQuestion">
                                    {{ $t("BUTTONS.add_question") }}
                                </button>
                            </div>
                        </div>

                        <div class="col-lg-7 my-3">
                            <div class="btn_wrapper d-flex">
                                <button type="button" class="mt-0 mx-3" @click="$router.push('/add-subject/step-two')">
                                    {{ $t("BUTTONS.previous") }}
                                </button>
                                <button class="mt-0 mx-3">
                                    {{ $t("BUTTONS.add") }}
                                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <SuccessModal :modalApperanceData="successModalIsOpen" @controleModalApperance="toggleSuccessModal">
                    <template #title>
                        {{ $t("TITLES.subject_added") }}
                    </template>
                    <template #sub_title>
                        {{ $t("TITLES.you_can_accept_requests") }}
                    </template>
                    <template #success_btn>
                        <router-link to="/added-subjects">
                            {{ $t("BUTTONS.added_subjects") }}
                        </router-link>
                    </template>
                </SuccessModal>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING MODALS
import SuccessModal from "../../components/modals/SuccessModal.vue";
// END:: IMPORTING MODALS

export default {
    name: "addSubjectStepThree",

    components: {
        SuccessModal,
    },

    computed: {
        // START:: VUEX GETTERS
        ...mapGetters("CreateCourseModule", ["createCourseStepOneData"]),
        ...mapGetters("CreateCourseModule", ["createCourseStepTwoData"]),
        // ...mapGetters("CreateCourseModule", ),        // END:: VUEX GETTERS
        // END:: VUEX GETTERS
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: MODALS HANDLING DATA
            successModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SUBJECT TYPE DATA
            subjectType: localStorage.getItem("subjectType"),
            // END:: SUBJECT TYPE DATA

            // START:: ADD SUBJECT DATA
            newQuestionArray: [
                {
                    id: 1,
                    questions: null,
                    answers: [
                        null, null, null, null
                    ],
                    correct_answer: ""
                },
            ],
            // END:: ADD SUBJECT DATA
        };
    },

    methods: {
        // START:: TOGGLE SUCCESS MODAL METHOD
        toggleSuccessModal() {
            this.successModalIsOpen = !this.successModalIsOpen;
        },
        // END:: TOGGLE SUCCESS MODAL METHOD

        // START:: ADD NEW DAY METHOD
        addNewQuestion() {
            this.newQuestionArray.push({
                id: Date.now(),
                questions: null,
                answers: [
                    null,
                    null,
                    null,
                    null
                ],
                correct_answer: null
            });
        },
        // END:: ADD NEW DAY METHOD

        // START:: VALIDATE FORM
        validateForm() {
            this.isWaitingRequest = true;

            // Validate each question
            const invalidQuestions = this.newQuestionArray.filter(
                (question) =>
                    !question.questions ||
                    question.answers.filter((answer) => answer !== null).length < 2 ||
                    question.correct_answer === null
            );

            if (invalidQuestions.length > 0) {
                this.isWaitingRequest = false;
                return;
            }
            this.submitForm();
        },
        // END:: VALIDATE FORM

        // START:: SUBMIT FORM
        submitForm() {
            console.log(this.newDayArray);
            console.log("done");
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND ADD COURSE DATA
            theData.append("image", this.createCourseStepOneData.image.file);
            theData.append("teacher_degree_id", this.createCourseStepOneData.degree.id);
            // theData.append("teacher_specialization_id", "7");
            theData.append("teacher_specialization_id", this.createCourseStepOneData.name);
            theData.append("academic_year_id", this.createCourseStepOneData.studyYear);
            theData.append("category_id", this.createCourseStepOneData.subjectCat);
            theData.append("lang", this.createCourseStepOneData.language);
            theData.append("start_date", this.createCourseStepOneData.start_date);
            theData.append("num_of_hour", parseInt(this.createCourseStepOneData.hour_count));
            theData.append("subject_kind", this.createCourseStepOneData.type_subject);
            if (this.createCourseStepOneData.type_subject == "online") {
                theData.append("price", parseInt(this.createCourseStepOneData.price_subject));
            }
            theData.append("desc", this.createCourseStepOneData.desc_subject);
            theData.append("objectives", this.createCourseStepOneData.goals_subject);
            theData.append("enriching", this.createCourseStepOneData.course_enrichments);
            if (this.createCourseStepOneData.has_offer && this.createCourseStepOneData.type_subject == "online") {
                theData.append("offer_desc", this.createCourseStepOneData.offer_desc);
                theData.append("discount_percentage", this.createCourseStepOneData.offer_amount);
            }
            var fileValues = this.createCourseStepOneData.attachmentsFiles;
            fileValues.forEach((item, index) => {
                theData.append("attachments[" + index + "]", item.file);
            });

            this.newQuestionArray.forEach((questions, index) => {
                theData.append(`questions[${index}]`, questions.questions);
                questions.answers.forEach((answer, answerIndex) => {
                    theData.append(`answers[${index}][${answerIndex}]`, answer);
                });
                theData.append(`correct_answer[${index}]`, questions.correct_answer);
            })

            this.createCourseStepTwoData.forEach((element, index) => {
                theData.append(`listens[${index}][link]`, element.course_link);
                theData.append(`listens[${index}][name]`, element.course_name);
                theData.append(
                    `listens[${index}][duration]`,
                    element.course_time
                );
                if (this.createCourseStepOneData.type_subject == "online") {
                    theData.append(
                        `listens[${index}][start_date]`,
                        element.start_date_course
                    );
                    theData.append(
                        `listens[${index}][start_time]`,
                        element.course_start_time
                    );
                }
            });

            // console.log("SEND");

            // START:: SEND REQUEST
            this.$axios
                .post("teacher/subject/create", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;
                    localStorage.removeItem("stepOneData");
                    this.toggleSuccessModal();
                })
                .catch((err) => {
                    console.log(err);
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: err.response.data.message,
                        messageSize: "22",
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT FORM
    },

    mounted() {
        console.log(this.createCourseStepOneData);
        console.log(this.createCourseStepTwoData);

        if (!this.createCourseStepOneData) {
            this.$router.replace("/add-subject");
        }
    },
};
</script>
