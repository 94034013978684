<template>
    <div class="search_content_wrapper">
        <!-- START:: OVERLAY -->
        <div class="search_overlay" @click="closeSearch"></div>
        <!-- END:: OVERLAY -->

        <!-- START:: SEARCH CONTENT -->
        <div class="container">
            <!-- START:: SEARCH FORM -->
            <form>
                <div class="row justify-content-center fadeIn">
                    <!-- START:: SEARCH TEXT INPUT -->
                    <div class="col-8 col-md-10 my-3">
                        <div class="input_wrapper">
                            <input
                                v-on:keyup.enter="validateSearchForm"
                                type="text"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.write_here')"
                                v-model="searchData.text"
                            />
                        </div>
                    </div>
                    <!-- END:: SEARCH TEXT INPUT -->

                    <!-- START:: SEARCH TYPE INPUT -->
                    <div class="col-4 col-md-2 my-3">
                        <div class="input_wrapper select_wrapper">
                            <multiselect
                                v-on:keyup.enter="validateSearchForm"
                                v-model="searchData.type"
                                track-by="value"
                                label="type"
                                :placeholder="$t('PLACEHOLDERS.search_type')"
                                :options="searchTypes"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <!-- END:: SEARCH TYPE INPUT -->
                </div>
            </form>
            <!-- END:: SEARCH FORM -->
        </div>
        <!-- END:: SEARCH CONTENT -->
    </div>
</template>

<script>
export default {
    name: "Search",

    emits: ["closeSearch"],

    data() {
        return {
            // START:: SEARCH TYPES DATA
            searchTypes: [
                {
                    id: 1,
                    type: this.$t("PLACEHOLDERS.teacher"),
                    value: "teacher",
                },
                {
                    id: 2,
                    type: this.$t("PLACEHOLDERS.subject"),
                    value: "subject_name",
                },
            ],
            // END:: SEARCH TYPES DATA

            // START:: SEARCH DATA
            searchData: {
                text: null,
                type: null,
            },
            // END:: SEARCH DATA
        };
    },

    methods: {
        // START:: CLOSE SEARCH
        closeSearch() {
            this.$emit("closeSearch");
        },
        // END:: CLOSE SEARCH
        validateSearchForm() {
            if (!this.searchData.text) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.textSearch"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.searchData.type) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.typeSearch"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitSearchForm();
            }
        },
        submitSearchForm() {
            if (this.searchData.type.value == "subject_name") {
                this.$router.push(
                    `/search-result/subject_name/${this.searchData.text}`
                );
            } else if (this.searchData.type.value == "teacher") {
                this.$router.push(
                    `/search-result/teacher/${this.searchData.text}`
                );
            }
            if (
                this.$route.name == "search_result_subject_name" ||
                this.$route.name == "search_result_teacher"
            ) {
                location.reload();
            }
            this.closeSearch();
        },
    },
};
</script>
