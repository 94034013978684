// import iziToast from "izitoast";
// import i18n from '@/i18n.js' 

export default {
    // START:: SET  CREATE COURSE STEP ONE DATA
    setCrateCourseStepOneData(context, payload) {
        context.commit("setCrateCourseStepOneData", payload);
    },
    setCrateCourseStepTwoData(context, payload) {
        context.commit("setCrateCourseStepTwoData", payload);
    }
    // END:: SET  CREATE COURSE STEP ONE DATA
}