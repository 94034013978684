import StrengtheningRequestsMutations from "./mutations.js";
import StrengtheningRequestsActions from "./actions.js";
import StrengtheningRequestsGetters from "./getters.js";

export default {
    namespaced: true,

    state: {
        // START:: STRENGTHENING REQUESTS DATA
        strengtheningRequestsData: null,
        // START:: STRENGTHENING REQUESTS DATA
        last_page: null,
    },

    mutations: StrengtheningRequestsMutations,
    actions: StrengtheningRequestsActions,
    getters: StrengtheningRequestsGetters,
};
