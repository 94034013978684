<template>
    <div class="courses_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                <span v-if="getAuthenticatedUserData.type == 'parent'"
                    >{{ $t("TITLES.son_courses") }}
                </span>
                <span v-if="getAuthenticatedUserData.type == 'student'"
                    >{{ $t("TITLES.subjects") }}
                </span>
                <span v-if="getAuthenticatedUserData.type == 'teacher'"
                    >{{ $t("TITLES.added_subjects") }}
                </span>
            </template>
            <template v-slot:breadcrumb_current_page>
                <span v-if="getAuthenticatedUserData.type == 'parent'">
                    {{ $t("TITLES.son_courses") }}
                </span>
                <span v-if="getAuthenticatedUserData.type == 'student'">
                    {{ $t("TITLES.subjects") }}
                </span>
                <span v-if="getAuthenticatedUserData.type == 'teacher'">
                    {{ $t("TITLES.added_subjects") }}
                </span>
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <div class="courses_card_wrapper fadeIn" v-else>
            <!-- START:: EMPTY COURSES MESSAGE -->
            <EmptyCoursesMessage v-if="sonCourses.length == 0" />
            <!-- END:: EMPTY COURSES MESSAGE -->

            <!-- START:: PAGE CONTENT -->
            <div class="container">
                <div class="row">
                    <!-- START:: COURSE CARD -->
                    <div
                        v-for="course in sonCourses"
                        :key="course.id"
                        class="col-lg-3 my-3"
                    >
                        <router-link
                            :to="`/courses-categories/${$route.params.id}/${course.id}`"
                        >
                            <ImagedCard>
                                <template v-slot:card_image>
                                    <img
                                        :src="course.specialization_ar"
                                        alt="Course Category Image"
                                    />
                                </template>

                                <template v-slot:card_text>
                                    <div class="wrapper">
                                        <p class="course_name">
                                            {{ course.subject_name }}
                                        </p>
                                        <p
                                            class="courses_count"
                                            v-if="
                                                getAuthenticatedUserData.type ==
                                                'student'
                                            "
                                        >
                                            {{ course.courses }} كورس
                                        </p>
                                        <p
                                            class="courses_count"
                                            v-if="
                                                getAuthenticatedUserData.type ==
                                                'parent'
                                            "
                                        >
                                            {{ course.num_of_courses }} كورس
                                        </p>
                                    </div>

                                    <div class="wrapper">
                                        <p
                                            class="teachers_count"
                                            v-if="
                                                getAuthenticatedUserData.type ==
                                                'student'
                                            "
                                        >
                                            {{ course.teachers }} معلم
                                        </p>
                                        <p
                                            class="teachers_count"
                                            v-if="
                                                getAuthenticatedUserData.type ==
                                                'parent'
                                            "
                                        >
                                            {{ course.num_of_teachers }} معلم
                                        </p>
                                    </div>
                                </template>
                            </ImagedCard>
                        </router-link>
                    </div>
                    <!-- END:: COURSE CARD -->
                </div>
            </div>
            <!-- END:: PAGE CONTENT -->

            <!-- START:: PAGENATION FOR TEACHER-->
            <Pagenation
                :last_page="last_page"
                :current_page="current_page"
                @pagenationClick="pagenationClick"
            ></Pagenation>
            <!-- END:: PAGENATION FOR TEACHER-->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING EMPTY MESSAGES
import EmptyCoursesMessage from "../../components/ui/emptyMessages/EmptyCoursesMessage.vue";
// END:: IMPORTING EMPTY MESSAGES

// START:: IMPORTING IMAGED CARD COMPONENT
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING IMAGED CARD COMPONENT
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "SonCourses",

    components: {
        Breadcrumb,
        EmptyCoursesMessage,
        ImagedCard,
        MainLoader,
        Pagenation,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: SON COURSES DATA
            sonCourses: "",
            // END:: SON COURSES DATA
            isLoading: false,
            // START:: PAGENATION
            last_page: null,
            current_page: 1,
            // END:: PAGENATION
        };
    },
    methods: {
        getSubjects() {
            this.isLoading = true;
            this.$axios
                .get(
                    `parent/subjectName?page=${this.current_page}&student_id=${this.$route.params.id}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.sonCourses = res.data.data;
                    this.last_page = res.data.meta.last_page;
                    this.isLoading = false;
                });
        },
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getSubjects();
        },
        // END:: PAGENATION
    },
    mounted() {
        this.getSubjects();
    },
};
</script>
