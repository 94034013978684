<template>
    <div class="orders_list_content_wrapper">
        <!-- START:: TABS -->
        <Tabs :tabsContent="tabsContent" @getItems="getItems">
            <!-- START:: REGISTERED COURSES TAB -->
            <template #current_orders>
                <!-- START:: MAIN LOADER -->
                <MainLoader
                    class="custom_loader"
                    v-if="
                        isLoading &&
                        currentTab == 'current_orders' &&
                        current_page == 1
                    "
                />
                <!-- END:: MAIN LOADER -->
                <div class="fadeIn" v-else>
                    <!-- START:: EMPTY ORDERS MESSAGE -->
                    <EmptyOrdersMessage v-if="currentOrders.length == 0" />
                    <!-- END:: EMPTY ORDERS MESSAGE -->

                    <!-- START:: ORDERS -->
                    <div class="current_orders_wrapper" v-else>
                        <div class="row">
                            <!-- START:: ORDER CARD -->
                            <div
                                v-for="order in currentOrders"
                                :key="order.id"
                                class="col-lg-6 my-3 fadeIn"
                            >
                                <router-link
                                    :to="`/order-details/${order.id}`"
                                    class="order_card"
                                >
                                    <!-- START:: TITLE -->
                                    <h3 class="order_title">
                                        <span class="order_number">
                                            {{ $t("TITLES.order_number") }}
                                            {{ order.id }}
                                        </span>
                                        <span class="order_date">
                                            {{ order.created_at }}
                                        </span>
                                    </h3>
                                    <!-- END:: TITLE -->

                                    <!-- START:: STATUS -->
                                    <h4 class="order_status">
                                        <span class="orders_count">
                                            <span class="number">
                                                {{ order.products.length }}
                                            </span>
                                            {{ $t("TITLES.products") }}
                                        </span>

                                        <span
                                            class="status"
                                            :class="order.status"
                                            v-if="order.status"
                                        >
                                            <span
                                                v-if="
                                                    order.status == 'accepted'
                                                "
                                                >{{ $t("STATUS.on_going") }}
                                            </span>
                                            <span
                                                v-else-if="
                                                    order.status == 'pending'
                                                "
                                                >{{ $t("STATUS.pending") }}
                                            </span>
                                        </span>
                                    </h4>
                                    <!-- END:: STATUS -->

                                    <!-- START:: PRICE AND IMAGES -->
                                    <div class="order_price_and_thumbnails">
                                        <h3 class="price">
                                            {{ order.total_with_vat }} ر.س
                                        </h3>

                                        <div class="thumbnails">
                                            <!-- START:: THUMBNAIL -->
                                            <div
                                                v-for="thumbnail in order.products"
                                                :key="thumbnail.id"
                                                class="thumbnail_wrapper"
                                            >
                                                <img
                                                    :src="
                                                        thumbnail.product_image
                                                    "
                                                    width="50"
                                                    height="50"
                                                    :alt="thumbnail.product"
                                                />
                                            </div>
                                            <!-- END:: THUMBNAIL -->
                                        </div>
                                    </div>
                                    <!-- END:: PRICE AND IMAGES -->
                                </router-link>
                            </div>
                            <!-- END:: ORDER CARD -->
                        </div>
                    </div>
                    <!-- END:: ORDERS -->
                    <!-- START:: PAGENATION FOR TEACHER-->
                    <Pagenation
                        :last_page="last_page"
                        :current_page="current_page"
                        @pagenationClick="pagenationClick"
                    ></Pagenation>
                    <!-- END:: PAGENATION FOR TEACHER-->
                </div>
            </template>
            <!-- END:: REGISTERED COURSES TAB -->

            <!-- START:: ONLINE COURSES TAB -->
            <template #finished_orders v-if="currentTab == 'finished_orders'">
                <!-- START:: MAIN LOADER -->
                <MainLoader
                    class="custom_loader"
                    v-if="
                        isLoading &&
                        currentTab == 'finished_orders' &&
                        current_page == 1
                    "
                />
                <!-- END:: MAIN LOADER -->
                <div class="fadeIn" v-else>
                    <!-- START:: EMPTY ORDERS MESSAGE -->
                    <EmptyOrdersMessage v-if="finishedOrders.length == 0" />
                    <!-- END:: EMPTY ORDERS MESSAGE -->

                    <!-- START:: ORDERS -->
                    <div class="finished_orders_wrapper" v-else>
                        <div class="row">
                            <!-- START:: ORDER CARD -->
                            <div
                                v-for="order in finishedOrders"
                                :key="order.id"
                                class="col-lg-6 my-3 fadeIn"
                            >
                                <router-link
                                    :to="`/order-details/${order.id}`"
                                    class="order_card"
                                >
                                    <!-- START:: TITLE -->
                                    <h3 class="order_title">
                                        <span class="order_number">
                                            {{ $t("TITLES.order_number") }}
                                            {{ order.id }}
                                        </span>
                                        <span class="order_date">
                                            {{ order.created_at }}
                                        </span>
                                    </h3>
                                    <!-- END:: TITLE -->

                                    <!-- START:: STATUS -->
                                    <h4 class="order_status">
                                        <span class="orders_count">
                                            <span class="number">
                                                {{ order.products.length }}
                                            </span>
                                            {{ $t("TITLES.products") }}
                                        </span>

                                        <span
                                            class="status"
                                            :class="order.status"
                                            v-if="order.status"
                                        >
                                            <span
                                                v-if="order.status == 'accept'"
                                                >{{ $t("STATUS.accepted") }}
                                            </span>
                                            <span
                                                v-if="order.status == 'reject'"
                                                >{{ $t("STATUS.canceled") }}
                                            </span>
                                        </span>
                                    </h4>
                                    <!-- END:: STATUS -->

                                    <!-- START:: PRICE AND IMAGES -->
                                    <div class="order_price_and_thumbnails">
                                        <h3 class="price">
                                            {{ order.total_with_vat }} ر.س
                                        </h3>

                                        <div class="thumbnails">
                                            <!-- START:: THUMBNAIL -->
                                            <div
                                                v-for="thumbnail in order.products"
                                                :key="thumbnail.id"
                                                class="thumbnail_wrapper"
                                            >
                                                <img
                                                    :src="
                                                        thumbnail.product_image
                                                    "
                                                    width="50"
                                                    height="50"
                                                    alt="Order Image"
                                                />
                                            </div>
                                            <!-- END:: THUMBNAIL -->
                                        </div>
                                    </div>
                                    <!-- END:: PRICE AND IMAGES -->
                                </router-link>
                            </div>
                            <!-- END:: ORDER CARD -->
                        </div>
                    </div>
                    <!-- START:: PAGENATION FOR TEACHER-->
                    <Pagenation
                        :last_page="last_page"
                        :current_page="current_page"
                        @pagenationClick="pagenationClick"
                    ></Pagenation>
                    <!-- END:: PAGENATION FOR TEACHER-->
                </div>
                <!-- END:: ORDERS -->
            </template>
            <!-- END:: ONLINE COURSES TAB -->
        </Tabs>
        <!-- END:: TABS -->
    </div>
</template>

<script>
// START:: IMPORTING TABS
import Tabs from "../../components/structure/Tabs.vue";
// END:: IMPORTING TABS

// START:: IMPORTING EMPTY MESSAGES
import EmptyOrdersMessage from "../ui/emptyMessages/EmptyOrdersMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
// END:: IMPORTING MODALS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "OrdersList",

    components: {
        Tabs,
        EmptyOrdersMessage,
        MainLoader,
        Pagenation,
    },

    data() {
        return {
            // START:: TABS CONTENT
            tabsContent: [
                {
                    tab: this.$t("TABS.current_orders"),
                    key_name: "current_orders",
                },
                {
                    tab: this.$t("TABS.finished_orders"),
                    key_name: "finished_orders",
                },
            ],
            // END:: TABS CONTENT

            // START:: CURRENT ORDERS DATA
            currentOrders: [],
            // END:: CURRENT ORDERS DATA

            // START:: CURRENT ORDERS DATA
            finishedOrders: [],
            // END:: CURRENT ORDERS DATA
            isLoading: false,
            // START:: PAGENATION
            currentTab: "current_orders",
            last_page: null,
            current_page: 1,
            // END:: PAGENATION
        };
    },

    methods: {
        // START:: GET MY ORDERS
        getItems(item) {
            this.currentTab = item;
            this.getMyOrders();
        },
        getMyOrders() {
            // START:: GET PENDING ORDERS
            this.isLoading = true;
            if (this.currentTab == "current_orders") {
                // START:: GET PENDING ORDERS
                this.$axios
                    .get(
                        `user/order?page=${this.current_page}&status=pending`,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                        "elmo3lm_elmosa3d_user_token"
                                    ),
                                "Accept-language": localStorage.getItem(
                                    "elmo3lm_elmosa3d_app_lang"
                                ),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        this.currentOrders = res.data.data;
                        this.last_page = res.data.meta.last_page;
                        this.isLoading = false;
                    });
                // END:: GET PENDING ORDERS
            } else if (this.currentTab == "finished_orders") {
                // START:: GET FINISHED ORDERS
                this.$axios
                    .get(`user/order?page=${this.current_page}&status=finish`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.finishedOrders = res.data.data;
                        this.last_page = res.data.meta.last_page;
                        this.isLoading = false;
                    });
                // END:: GET FINISHED ORDERS
            }
        },
        // END:: GET MY ORDERS
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getMyOrders();
            document
                .getElementById("app")
                .scrollIntoView({ behavior: "smooth" });
        },
        // END:: PAGENATION
    },

    mounted() {
        // START:: FIRE METHODS
        this.getMyOrders();
        // END:: FIRE METHODS
    },
};
</script>
