<template>
    <div class="instructor_info_wrapper">
        <h3 class="title">
            {{ $t("TITLES.instructor_info") }}
        </h3>

        <div class="instructor_info">
            <div class="card_wrapper">
                <!-- START:: IMAGE -->
                <div class="image_wrapper">
                    <img
                        :src="instructor.image"
                        :alt="instructor.fullname"
                        width="80"
                        height="80"
                    />
                </div>
                <!-- END:: IMAGE -->

                <!-- START:: NAME AND COURSES COUNT -->
                <div class="name_and_count">
                    <h3 class="name">
                        {{ instructor.fullname }}
                    </h3>
                    <h4 class="courses_count">
                        {{ instructor.num_courses }}
                        {{ $t("TITLES.course") }}
                    </h4>
                </div>
                <!-- END:: NAME AND COURSES COUNT -->
            </div>

            <div class="card_wrapper">
                <!-- START:: CHAT ROUTE WRAPPER -->
                <router-link :to="'/chat/' + instructor.id" class="chat_route">
                    <i class="fal fa-comment-lines"></i>
                </router-link>
                <!-- END:: CHAT ROUTE WRAPPER -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CourseInstructor",

    props: ["instructor"],
};
</script>
