<template>
    <div>
        <MainLoader v-if="isLoading" />

        <div v-else>
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.add_report") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.add_report") }}
                </template>
            </Breadcrumb>

            <div class="d-flex justify-content-center">
                <form
                    @submit.prevent="
                        validateFormInputs({
                            strength_points,
                            weakness_points,
                            recommendations,
                        })
                    "
                >
                    <div class="row mt-6">
                        <div class="input_wrapper col-6">
                            <textarea
                                rows="4"
                                cols="60"
                                class="form-control ml-5"
                                :placeholder="$t('PLACEHOLDERS.strength')"
                                v-model="strength_points"
                            />
                        </div>
                        <div class="input_wrapper col-6">
                            <textarea
                                rows="4"
                                cols="60"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.weakness')"
                                v-model="weakness_points"
                            />
                        </div>
                    </div>
                    <div class="input_wrapper mt-5">
                        <textarea
                            rows="4"
                            cols="60"
                            class="form-control"
                            :placeholder="$t('PLACEHOLDERS.advices')"
                            v-model="recommendations"
                        />
                    </div>
                    <div class="btn_wrapper mt-5">
                        <button type="submit">
                            {{ $t("BUTTONS.save") }}
                        </button>
                    </div>
                </form>
            </div>

            <SuccessModal
                v-if="successModalIsOpen"
                :modalApperanceData="successModalIsOpen"
                @controleModalApperance="toggleSuccessModal"
            >
                <template #title>
                    {{ $t("TITLES.report_added") }}
                </template>
                <template #sub_title>
                    {{ $t("TITLES.you_can_accept_reports") }}
                </template>
                <template #success_btn>
                    <router-link :to="`/reports/course=${courseId}/student=${studentId}`">
                        {{ $t("BUTTONS.added_report") }}
                    </router-link>
                </template>
            </SuccessModal>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "./../components/structure/TheBreadcrumb.vue";
import MainLoader from "./../components/ui/loaders/MainLoader.vue";
import SuccessModal from "./../components/modals/SuccessModal.vue";

export default {
    name: "ReportsPage",

    components: {
        MainLoader,
        Breadcrumb,
        SuccessModal,
    },
    data() {
        return {
            successModalIsOpen: false,
            isLoading: false,
            studentId: this.$route.params.id,
            // strength_points: "",
            // weakness_points: "",
            // recommendations: "",
            courseId: this.$route.params.courseId,

            tabsContent: [
                {
                    tab: this.$t("TABS.strength"),
                    key_name: "strength_points",
                },
                {
                    tab: this.$t("TABS.weakness"),
                    key_name: "weakness_points",
                },
                {
                    tab: this.$t("TABS.advices"),
                    key_name: "recommendations",
                },
            ],
        };
    },

    methods: {
        toggleSuccessModal() {
            this.successModalIsOpen = !this.successModalIsOpen;
        },
        validateFormInputs(formData) {
            if (!formData.strength_points) {
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.strength"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!formData.weakness_points) {
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.weakness"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!formData.recommendations) {
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.recommendations"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.AddReport();
            }
        },
        AddReport() {
            var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
            if (userType == "teacher") {
                const formData = {
                    strength_points: this.strength_points,
                    weakness_points: this.weakness_points,
                    recommendations: this.recommendations,
                };
                this.$axios
                    .post(
                        `teacher/subject/student_subject_report/subject/${this.courseId}/student/${this.studentId}/add`,
                        formData,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                        "elmo3lm_elmosa3d_user_token"
                                    ),
                                "Accept-language": localStorage.getItem(
                                    "elmo3lm_elmosa3d_app_lang"
                                ),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        this.successModalIsOpen = true;
                        console.log(res.data.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                console.log("You are not a teacher");
            }
        },
    },
    mounted() {
        const studentId = this.$route.params.id;
        const courseId = this.$route.params.courseId;

        console.log(this.$route.params);
console.log(studentId);
console.log(courseId);

    },
};
</script>

<style lang="scss">
@import "@/assets/sass/sassHelpers/_mixins.scss";
.btn_wrapper {
    @include flexCenterAlignment;

    button {
        @include simpleButtonStyle;
    }
}
</style>
