<template>
    <div class="add_subject_steps_wrapper">
        <div class="add_subject_steps_card_wrapper">
            <div class="container">
                <!-- START:: FORM -->
                <form @submit.prevent="validateForm">
                    <div class="row justify-content-center">
                        <div class="day_card fadeIn" v-for="(single, index) in newDayArray" :key="single.id">
                            <div class="page_small_title">
                                <h3>اليوم #{{ index + 1 }}</h3>
                            </div>
                            <!-- START:: COURSE TIME INPUT -->
                            <div class="my-3 input_wrapper">
                                <input type="number" class="form-control" :placeholder="$t('PLACEHOLDERS.course_time')
                                    " v-model.trim="single.course_time" />
                            </div>
                            <!-- END:: COURSE TIME INPUT -->

                            <!-- START:: COURSE NAME -->
                            <div class="my-3 input_wrapper">
                                <input type="text" class="form-control" :placeholder="$t('PLACEHOLDERS.course_name')
                                    " v-model.trim="single.course_name" />
                            </div>
                            <!-- END:: COURSE NAME -->

                            <!-- START:: COURSE LINK -->
                            <div class="my-3 input_wrapper">
                                <input type="text" class="form-control" :placeholder="$t('PLACEHOLDERS.course_link')
                                    " v-model.trim="single.course_link" />
                            </div>
                            <!-- END:: COURSE LINK -->

                            <!-- START:: DATEPICKER INPUT GROUP -->
                            <div class="input_wrapper my-3" v-if="subjectType == 'online'">
                                <a-date-picker :placeholder="$t('PLACEHOLDERS.start_date_course')
                                    " v-model="single.start_date_course" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                                    :disabled-date="disabledDate" />
                            </div>
                            <!-- END:: DATEPICKER INPUT GROUP -->

                            <!-- START:: TIMEPICKER INPUT GROUP -->
                            <div class="my-3" v-if="subjectType == 'online'">
                                <a-time-picker class="w-100" :placeholder="$t('PLACEHOLDERS.course_start_time')
                                    " format="HH:mm" valueFormat="HH:mm" v-model="single.course_start_time" />
                            </div>
                            <!-- END:: TIMEPICKER INPUT GROUP -->
                        </div>

                        <!-- START:: ADD BUTTON WRAPPER -->
                        <div class="col-lg-7 my-3">
                            <div class="btn_wrapper solid">
                                <button type="button" class="w-100 mt-0 add_day_btn" @click="addNewDay">
                                    {{ $t("BUTTONS.add_day") }}
                                </button>
                            </div>
                        </div>
                        <!-- END:: ADD BUTTON WRAPPER -->

                        <!-- START:: SUBMIT BUTTON WRAPPER -->
                        <div class="col-lg-7 my-3">
                            <div class="btn_wrapper d-flex">
                                <button type="button" class="mt-0 mx-3" @click="$router.push('/add-subject')">
                                    {{ $t("BUTTONS.previous") }}
                                </button>

                                <button class="mt-0 mx-3">
                                    {{ $t("BUTTONS.add") }}
                                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                                </button>
                            </div>
                        </div>
                        <!-- END:: SUBMIT BUTTON WRAPPER -->
                    </div>
                </form>
                <!-- END:: FORM -->

                <!-- START:: SUCCESS MODAL -->
                <SuccessModal :modalApperanceData="successModalIsOpen" @controleModalApperance="toggleSuccessModal">
                    <template #title>
                        {{ $t("TITLES.subject_added") }}
                    </template>
                    <template #sub_title>
                        {{ $t("TITLES.you_can_accept_requests") }}
                    </template>
                    <template #success_btn>
                        <router-link to="/added-subjects">
                            {{ $t("BUTTONS.added_subjects") }}
                        </router-link>
                    </template>
                </SuccessModal>
                <!-- END:: SUCCESS MODAL -->
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING MOMENT
import moment from "moment";
// END:: IMPORTING MOMENT

// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING MODALS
import SuccessModal from "../../components/modals/SuccessModal.vue";
// END:: IMPORTING MODALS

export default {
    name: "addSubjectStepTwo",

    components: {
        SuccessModal,
    },

    // computed: {
       
    // },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: MODALS HANDLING DATA
            successModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: SUBJECT TYPE DATA
            subjectType: localStorage.getItem("subjectType"),
            // END:: SUBJECT TYPE DATA

            // START:: ADD SUBJECT DATA
            newDayArray: [
                {
                    id: 1,
                    course_time: null,
                    course_link: null,
                    course_name: null,
                    start_date_course: null,
                    course_start_time: null,
                },
            ],
            // END:: ADD SUBJECT DATA
        };
    },

    methods: {
        ...mapActions("CreateCourseModule", ["setCrateCourseStepTwoData"]),
        ...mapGetters("CreateCourseModule", ["createCourseStepOneData"]),
        // START:: TOGGLE SUCCESS MODAL METHOD
        toggleSuccessModal() {
            this.successModalIsOpen = !this.successModalIsOpen;
        },
        // END:: TOGGLE SUCCESS MODAL METHOD

        // START:: VALIDATE STEP ONE
        selectBankValidate() {
            this.isWaitingRequest = true;

            if (!this.withDrow.bank) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.owner_name"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.selectBankForm();
            }
        },
        // END:: VALIDATE STEP ONE

        // START:: ADD NEW DAY METHOD
        addNewDay() {
            this.newDayArray.push({
                id: Date.now(),
                course_time: null,
                course_link: null,
                start_date_course: null,
                course_start_time: null,
            });
        },
        // END:: ADD NEW DAY METHOD

        // START:: DATEPICKER DISABLE PREVIOUS DAYS METHOD
        disabledDate(current) {
            return current && current < moment().startOf("day");
        },
        // END:: DATEPICKER DISABLE PREVIOUS DAYS METHOD

        // START:: VALIDATE FORM
        validateForm() {
            this.isWaitingRequest = true;

            this.newDayArray.forEach((element) => {
                if (!element.course_link) {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("VALIDATION.course_link"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                } else if (!element.course_name) {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("VALIDATION.course_name"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                } else if (!element.course_time) {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("VALIDATION.course_time"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    return;
                } else if (
                    this.createCourseStepOneData.type_subject == "online"
                ) {
                    if (!element.start_date_course) {
                        this.isWaitingRequest = false;
                        this.$iziToast.error({
                            timeout: 2000,
                            message: this.$t("VALIDATION.start_date_course"),
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });
                        return;
                    } else if (!element.course_start_time) {
                        this.isWaitingRequest = false;
                        this.$iziToast.error({
                            timeout: 2000,
                            message: this.$t("VALIDATION.course_start_time"),
                            messageSize: "22",
                            position: this.$t("iziToastConfigs.position"),
                            rtl: this.$t("iziToastConfigs.dir"),
                        });
                        return;
                    } else {
                        this.submitStepTwoForm();
                    }

                }
                else {
                    this.submitStepTwoForm();
                }
            });
            // this.submitForm();
        },
        // END:: VALIDATE FORM

        submitStepTwoForm() {
            this.isWaitingRequest = false;
            this.setCrateCourseStepTwoData(this.newDayArray);
            this.$router.push(`/add-subject/step-three`);

        },

        // START:: SUBMIT FORM
        // submitForm() {
        //     this.isWaitingRequest = true;

        //     const theData = new FormData();
        //     // START:: APPEND ADD COURSE DATA
        //     theData.append("image", this.createCourseStepOneData.image.file);
        //     theData.append("teacher_degree_id", this.createCourseStepOneData.degree.id);
        //     theData.append("teacher_specialization_id", "7");
        //     // theData.append("teacher_specialization_id", this.createCourseStepOneData.name);
        //     theData.append("academic_year_id", this.createCourseStepOneData.studyYear);
        //     theData.append("category_id", this.createCourseStepOneData.subjectCat);
        //     theData.append("lang", this.createCourseStepOneData.language);
        //     theData.append("start_date", this.createCourseStepOneData.start_date);
        //     theData.append("num_of_hour", parseInt(this.createCourseStepOneData.hour_count));
        //     theData.append("subject_kind",this.createCourseStepOneData.type_subject);
        //     if (this.createCourseStepOneData.type_subject == "online") {
        //         theData.append("price",parseInt(this.createCourseStepOneData.price_subject));
        //     }
        //     theData.append("desc", this.createCourseStepOneData.desc_subject);
        //     theData.append("objectives", this.createCourseStepOneData.goals_subject);
        //     theData.append("enriching",this.createCourseStepOneData.course_enrichments);
        //     if(this.createCourseStepOneData.has_offer && this.createCourseStepOneData.type_subject == "online") {
        //         theData.append("offer_desc",this.createCourseStepOneData.offer_desc);
        //         theData.append("discount_percentage",this.createCourseStepOneData.offer_amount);
        //     }
        //     var fileValues = this.createCourseStepOneData.attachmentsFiles;
        //     fileValues.forEach((item, index) => {
        //         theData.append("attachments[" + index + "]", item.file);
        //     });

        //     this.newDayArray.forEach((element, index) => {
        //         theData.append(`listens[${index}][link]`, element.course_link);
        //         theData.append(`listens[${index}][name]`, element.course_name);
        //         theData.append(
        //             `listens[${index}][duration]`,
        //             element.course_time
        //         );
        //         if (this.createCourseStepOneData.type_subject == "online") {
        //             theData.append(
        //                 `listens[${index}][start_date]`,
        //                 element.start_date_course
        //             );
        //             theData.append(
        //                 `listens[${index}][start_time]`,
        //                 element.course_start_time
        //             );
        //         }
        //     });
        //     // END:: APPEND ADD COURSE DATA

        //     // console.log("SEND");

        //     // START:: SEND REQUEST
        //     this.$axios
        //         .post("teacher/subject/create", theData, {
        //             headers: {
        //                 Authorization:
        //                     "Bearer " +
        //                     localStorage.getItem("elmo3lm_elmosa3d_user_token"),
        //                 "Accept-language": localStorage.getItem(
        //                     "elmo3lm_elmosa3d_app_lang"
        //                 ),
        //                 "cache-control": "no-cache",
        //                 Accept: "application/json",
        //             },
        //         })
        //         .then(() => {
        //             this.isWaitingRequest = false;
        //             localStorage.removeItem("stepOneData");
        //             this.toggleSuccessModal();
        //         })
        //         .catch((err) => {
        //             this.isWaitingRequest = false;
        //             this.$iziToast.error({
        //                 timeout: 2000,
        //                 position: this.$t("iziToastConfigs.position"),
        //                 rtl: this.$t("iziToastConfigs.dir"),
        //                 message: err.response.data.message,
        //                 messageSize: "22",
        //             });
        //             return;
        //         });
        //     // END:: SEND REQUEST
        // },
        // END:: SUBMIT FORM
    },

    mounted() {
        if (!this.createCourseStepOneData) {
            this.$router.replace("/add-subject");
        }
    },
};
</script>
