<template :key="$route.path">
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: SECTION -->
        <div class="teachers_wrapper fadeIn" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="d-flex">
                            <div class="header_title_wrapper" style="margin-top: 7%;">
                                <h1 class="w-100">
                                    {{ this.teacherDetails.fullname }}
                                </h1>
                                <h3 class="text-secondary">
                                    <div class="d-flex">
                                        <div v-for="specialization in teacherDetails.specializations.slice(
                                            0,
                                            2
                                        )" :key="specialization.id" :value="specialization.id">
                                            <h4 class="text-secondary">
                                                {{ specialization.name }}
                                                <span v-if="teacherDetails.specializations.length > 1" class="text-secondary">
                                                    /
                                                </span>
                                            </h4>
                                        </div>
                                        <div v-if="teacherDetails.specializations.length > 2">
                                            <h4 class="text-secondary">...</h4>
                                        </div>
                                    </div>
                                </h3>
                                <!-- <div class="teacherRate text-center mt-3 py-1">
                                    <i class="fas fa-star"></i>
                                    <span>
                                        {{ this.teacherDetails.avg_rate }}
                                    </span>
                                </div> -->
                            </div>
                            <div style=" margin-right: auto;" >
                             <!-- <img src="./../../assets/media/illustrations/header.png" alt="teachers"
                                 /> -->
                            </div>
                        </div>
                    </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->
        </div>

        <div class="courses_categories_wrapper">
            <div class="container">

                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h2 class="font-weight-bold mt-5">
                            نبذة عنـي
                        </h2>
                        <h5 class="text-secondary">
                            {{this.teacherDetails.desc}}
                            
                            <!-- "خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو، إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ
                            انه، ربع الأثنان المقيتة في، اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام، الصين وتنامت حين
                            30,
                            ونتج والحزب المذابح كل جوي. أسر كارثة المشتّتون بل، وبعض وبداية الصفحة غزو قد، أي بحث تعداد
                            الجنوب.

                            قصف المسرح واستمر الاتحاد في، ذات أسيا للغزو، الخطّة و، الآخر لألمانيا جهة بل. في سحقت هيروشيما
                            البريطاني يتم، غريمه باحتلال الأيديولوجية، في فصل، دحر وقرى لهيمنة الإيطالية 30. استبدال استسلام
                            القاذفات عل مما. ببعض مئات وبلجيكا، قد أما، قِبل الدنمارك حتى كل، العمليات اليابانية انه أن.

                            حتى هاربر موسكو ثم، وتقهقر المنتصرة حدة عل، التي فهرست واشتدّت أن أسر. كانت المتاخمة التغييرات
                            أم
                            وفي. ان وانتهاءً باستحداث قهر. ان ضمنها للأراضي الأوروبية ذات.

                            حشد الثقيل المنتصر ثم، أسر قررت تم. وغير تصفح الحزب واستمر، مشروط الساحلية هذا ان. أما معركة
                            لبلجيكا، من، الألوف الثقيلة الإنجليزية أسر 30. 30 دار أمام أحدث، أما بحشد الهادي الدولارات ما،
                            هو
                            الحزب الصفحة محاولات قبل. وبحلول الخنادق الأوروبية، ان غير، وليرتفع برلين، انه، انتباه الوزراء
                            البولندي تم تلك. -->
                        </h5>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <video ref="videoElement" :src="teacherDetails.explanatory_video" class="custom-video"
                            controls></video>
                    </div>
                </div>
                <hr class="teacherHr my-6" />
                <div class="mt-5">
                    <h3 class="font-weight-bold">
                        <img src="@/assets/media/shapes/title_icon.svg" />
                        المواد الدراسية
                    </h3>
                    <div class="row">
                        <div class="col-4 mt-4" v-for="specialization in teacherDetails.specializations" :key="specialization.id"  >
                            <div class="card_content_wrapper card_profile row ml-1 p-0  pb-2">
                                <img :src="specialization.specialization_ar" alt="category.name" width="100"
                                    height="120" class="col-xl-5 col-lg-6 col-md-12 col-sm-12" />
                                <div class="col ">
                                    <h2 class="font-weight-bold">{{
                                        specialization.name
                                    }}</h2>
                                    <!-- <h4 class="text-secondary">
                                        <span class="font-weight-bold">100</span> درس
                                    </h4> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
import ImageHeader from "../../components/ui/ImageHeader.vue";

export default {
    name: "TeacherProfile",

    components: {
        MainLoader,
        ImageHeader,
    },

    data() {
        return {
            isLoading: true,
            routeName: this.$route.name,
            teacherDetails:[],
            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),
        };
    },

    methods: {
        getTeacherProfile() {
            if (
                this.registeredUserType == "student"
            ) {
                this.$axios
                    .get(`student/teacher_profile/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.teacherDetails = res.data.data;
                        console.log(this.teacherDetails);
                    })
            }
        },
    },

    mounted() {
        this.getTeacherProfile();
    },
};
</script>

<style lang="scss">
.card_content_wrapper {
    border-radius: 10px;
    border: 2px solid var(--lighter_gray_clr);
}

.card_profile {
    background-color: white;
}

.teacherHr {
    color: #dfdeeb;
    border: 1px solid #f4f3fd;
}

.custom-video {
    width: 100%;
    height: 400px;
    border-radius: 20px;
}

// @import url("../../assets/sass/components/ui/_imagedCard.scss");

.teacherRate {
    font-size: 18px;
    background-color: var(--gold_clr);
    color: white;
    border-radius: 20px;
    width: 80px;
}
</style>
