<template>
    <div>
        <MainLoader v-if="isLoading" />

        <div class="reports_page_wrapper fadeIn" v-else>
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.reports") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.reports") }}
                </template>
            </Breadcrumb>

            <div class="reports_header d-flex mt-3">
                <div class="exam_result_container">
                    <h4 class="subTitle">
                        نتيجة الاختبــار - لقـد حصل الطالب على درجة ( 18 -20 )
                    </h4>
                </div>
                <div class="btn_wrapper">
                    <router-link :to="`/add-report/course=${courseId}/student=${studentId}`">
                        <button type="button">
                            <i class="fal fa-plus"></i>
                            {{ $t("BUTTONS.add_report") }}
                        </button>
                    </router-link>
                </div>
            </div>

            <div class="reports_content">
                <div class="content_header">
                    <Tabs :tabsContent="tabsContent">
                        <template #strength>
                            <div class="report_tab_container">
                                <ol class="reports_list row">
                                    <div class="col-5">
                                        <li class="report">
                                            <span class="list_content">{{
                                                reportData.strength_points
                                            }}</span>
                                        </li>
                                        <hr />
                                        <!-- <li class="report"><span class="list_content">قـوي في درس اللوغريتمــات ولابد من المراجعـــة عليـه مــرة أخرى</span></li><hr/>
          -->
                                    </div>
                                    <div class="col-1 vertical"></div>
                                    <div class="col-5">
                                        <!-- <li class="report"><span class="list_content">قـوي في درس اللوغريتمــات ولابد من المراجعـــة عليـه مــرة أخرى</span></li><hr/>
                -->
                                    </div>
                                </ol>
                            </div>
                        </template>

                        <template #weakness>
                            <div class="report_tab_container">
                                <ol class="reports_list row">
                                    <div class="col-5">
                                        <li class="report">
                                            <span class="list_content">{{
                                                reportData.weakness_points
                                            }}</span>
                                        </li>
                                        <hr />
                                        <!-- <li class="report"><span class="list_content">قـوي في درس اللوغريتمــات ولابد من المراجعـــة عليـه مــرة أخرى</span></li><hr/>
          -->
                                    </div>
                                    <div class="col-1 vertical"></div>
                                    <div class="col-5">
                                        <!-- <li class="report"><span class="list_content">قـوي في درس اللوغريتمــات ولابد من المراجعـــة عليـه مــرة أخرى</span></li><hr/>
                -->
                                    </div>
                                </ol>
                            </div>
                        </template>

                        <template #advices>
                            <div class="report_tab_container">
                                <ol class="reports_list row">
                                    <div class="col-5">
                                        <li class="report">
                                            <span class="list_content">{{
                                                reportData.recommendations
                                            }}</span>
                                        </li>
                                        <hr />
                                        <!-- <li class="report"><span class="list_content">قـوي في درس اللوغريتمــات ولابد من المراجعـــة عليـه مــرة أخرى</span></li><hr/>
          -->
                                    </div>
                                    <div class="col-1 vertical"></div>
                                    <div class="col-5">
                                        <!-- <li class="report"><span class="list_content">قـوي في درس اللوغريتمــات ولابد من المراجعـــة عليـه مــرة أخرى</span></li><hr/>
                -->
                                    </div>
                                </ol>
                            </div>
                        </template>
                    </Tabs>
                </div>
                <div class="report_details"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "@/components/structure/TheBreadcrumb.vue";
import MainLoader from "@/components/ui/loaders/MainLoader.vue";
import Tabs from "@/components/structure/Tabs.vue";

export default {
    name: "ReportsPage",

    components: {
        MainLoader,
        Breadcrumb,
        Tabs
    },

    data() {
        return {
            isLoading: false,
            successModalIsOpen: false,
            reportData: "",
            studentId: this.$route.params.id,
            courseId: this.$route.params.courseId,
            tabsContent: [
                {
                    tab: this.$t("TABS.strength"),
                    key_name: "strength",
                },
                {
                    tab: this.$t("TABS.weakness"),
                    key_name: "weakness",
                },
                {
                    tab: this.$t("TABS.advices"),
                    key_name: "advices",
                },
            ],
        };
    },

    methods: {
        getReportData() {
            this.isLoading = true;
            this.isLoading = true;
            var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
            if (userType == "teacher") {
                this.$axios
                    .get(
                        `teacher/subject/student_subject_report/subject/${this.courseId}/student/${this.studentId}`,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                        "elmo3lm_elmosa3d_user_token"
                                    ),
                                "Accept-language": localStorage.getItem(
                                    "elmo3lm_elmosa3d_app_lang"
                                ),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.reportData = res.data.data;
                        console.log(this.reportData);
                    })
                    .catch((error) => {
                        console.log("Error:", error);
                    });
            } else {
                console.log("You are not a teacher");
            }
        },
    },

    mounted() {
        this.getReportData();
        const studentId = this.$route.params.id;
        const courseId = this.$route.params.courseId;

        console.log(this.$route.params);
console.log(studentId);
console.log(courseId);

    },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/staticPages/reportsPage.scss";
</style>
