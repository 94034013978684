import iziToast from "izitoast";
import i18n from "@/i18n.js";
// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS
export default {
    // =================== START:: STORE ACTIONS ===================
    // START:: ADD TO CART
    addToCart(context, payload) {
        const theData = new FormData();
        theData.append("product_id", payload.id);
        theData.append("count", payload.count);

        // START:: SEND REQUEST
        axios
            .post(`user/cart`, theData, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then(() => {
                iziToast.success({
                    timeout: 2000,
                    message: i18n.t("MESSAGES.added_to_cart"),
                    position: i18n.t("iziToastConfigs.position"),
                    rtl: i18n.t("iziToastConfigs.dir"),
                });
                context.dispatch("getCart");
            })
            .catch((err) => {
                iziToast.error({
                    timeout: 2000,
                    message: err.response.data.message,
                    messageSize: "22",
                    position: i18n.t("iziToastConfigs.position"),
                    rtl: i18n.t("iziToastConfigs.dir"),
                });
                return;
            });
        // END:: SEND REQUEST
    },
    // END:: ADD TO CART

    // START:: REMOVE FROM CART
    removeFromCart(context, payload) {
        // context.commit("removeFromCart", payload);
        axios
            .delete(`user/cart/${payload}`, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then(() => {
                iziToast.success({
                    timeout: 2000,
                    message: i18n.t("MESSAGES.deleted_successfully"),
                    messageSize: "22",
                    position: i18n.t("iziToastConfigs.position"),
                    rtl: i18n.t("iziToastConfigs.dir"),
                });
                context.dispatch("getCart");
                return;
            });
    },
    // END:: REMOVE FROM CART

    // START:: AXIOS GET PRODUCTS
    getProducts(context, payload) {
        axios
            .get("user/product?page=" + payload, {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then((res) => {
                context.commit("setStoreItems", res.data);
            });
    },
    // START:: GET CART

    // START:: GET SHOPPING CART
    getCart(context) {
        axios
            .get("user/cart", {
                headers: {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    "Accept-language": localStorage.getItem(
                        "elmo3lm_elmosa3d_app_lang"
                    ),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                },
            })
            .then((res) => {
                context.commit("setCartItems", res.data);
            });
    },
    // END:: GET SHOPPING CART
    // =================== END:: STORE ACTIONS ===================
};
