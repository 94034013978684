<template>
    <div class="my_orders_page_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.my_orders") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.my_orders") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <!-- START:: START PAGE CONTENT -->
        <div class="page_content_wrapper">
            <div class="container">
                <!-- START:: MY ORDERS ROUTER VIEW -->
                <router-view></router-view>
                <!-- END:: MY ORDERS ROUTER VIEW -->
            </div>
        </div>
        <!-- END:: START PAGE CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "MyOrders",

    components: {
        Breadcrumb
    },
}
</script>