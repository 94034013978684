var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('MainLoader'):_c('div',{staticClass:"courses_section_wrapper fadeIn"},[_c('ImageHeader',[[_c('div',{staticClass:"header_container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header_title_wrapper"},[(
                                    _vm.$route.params.id !=
                                    'distance-learning-lessons'
                                )?_c('h1',[_vm._v(" "+_vm._s(_vm.categoryData.name)+" ")]):_c('h1',[_vm._v(_vm._s(_vm.$t("TITLES.all_courses")))])])])])]],2),_c('div',{staticClass:"section_content_wrapper"},[_c('div',{staticClass:"container"},[(_vm.$route.params.id != 'distance-learning-lessons')?_c('div',{staticClass:"about_course_section_wrapper"},[_c('h2',{staticClass:"course_section_title"},[_vm._v(" "+_vm._s(_vm.$t("TITLES.about_section"))+" ")]),_c('div',{staticClass:"text_wrapper"},[_vm._v(" "+_vm._s(_vm.categoryData.desc)+" ")])]):_vm._e(),_c('div',{staticClass:"course_section_subjects_wrapper"},[_c('h2',{staticClass:"course_section_title"},[_vm._v(" "+_vm._s(_vm.$t("TITLES.subjects"))+" ")]),(_vm.subjects.length == 0)?_c('EmptyCoursesMessage'):_c('div',{staticClass:"row"},_vm._l((_vm.subjects),function(course){return _c('div',{key:course.id,staticClass:"col-lg-3 my-3"},[(_vm.registeredUserType == 'teacher')?_c('router-link',{attrs:{"to":_vm.redirectRoute(course.id)}},[_c('ImagedCard',{scopedSlots:_vm._u([{key:"card_image",fn:function(){return [_c('img',{attrs:{"src":course.image,"alt":course.subject_name
                                                    .subject_name}})]},proxy:true},{key:"card_text",fn:function(){return [_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"course_name"},[_vm._v(" "+_vm._s(course.subject_name .subject_name)+" ")]),(
                                                    _vm.getAuthenticatedUserData.type ==
                                                    'parent'
                                                )?_c('p',{staticClass:"duration"},[_c('span',[_vm._v(_vm._s(course.duration .hours)+" ساعات ")]),_c('span',[_vm._v(_vm._s(course.duration .minutes)+" دقيقة ")])]):_vm._e(),(
                                                    _vm.getAuthenticatedUserData.type ==
                                                    'student'
                                                )?_c('p',{staticClass:"courses_count"},[_vm._v(" "+_vm._s(course.courses)+" كورس ")]):_vm._e()]),_c('div',{staticClass:"wrapper"},[(
                                                    _vm.getAuthenticatedUserData.type ==
                                                    'student'
                                                )?_c('p',{staticClass:"teachers_count"},[_vm._v(" "+_vm._s(course.teachers)+" معلم ")]):_vm._e()]),_c('div',{staticClass:"wrapper w-100 d-flex justify-content-between"},[_c('span',{staticClass:"rate"},[_c('i',{staticClass:"fas fa-star"}),_c('span',[_vm._v(" "+_vm._s(course.avg_rate)+" ")])]),_c('p',{staticClass:"price"},[_vm._v(" "+_vm._s(course.price)+" "+_vm._s(course.currency)+" ")])])]},proxy:true}],null,true)})],1):_vm._e(),(_vm.registeredUserType == 'student')?_c('router-link',{attrs:{"to":_vm.redirectRoute(course.id)}},[_c('ImagedCard',{scopedSlots:_vm._u([{key:"card_image",fn:function(){return [_c('img',{attrs:{"src":course.specialization_ar,"alt":"Course Category Image"}})]},proxy:true},{key:"card_text",fn:function(){return [_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"course_name"},[_vm._v(" "+_vm._s(course.subject_name)+" ")]),_c('p',{staticClass:"courses_count"},[_vm._v(" "+_vm._s(course.num_of_courses)+" كورس ")])]),_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"teachers_count"},[_vm._v(" "+_vm._s(course.num_of_teachers)+" معلم ")])])]},proxy:true}],null,true)})],1):_vm._e()],1)}),0)],1)])])],1),_c('Pagenation',{attrs:{"last_page":_vm.last_page,"current_page":_vm.current_page},on:{"pagenationClick":_vm.pagenationClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }