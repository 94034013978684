<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: SECTION -->
        <div class="courses_section_wrapper fadeIn" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="header_title_wrapper">
                                <h1
                                    v-if="
                                        $route.params.id !=
                                        'distance-learning-lessons'
                                    "
                                >
                                    {{ categoryData.name }}
                                </h1>
                                <h1 v-else>{{ $t("TITLES.all_courses") }}</h1>
                            </div>
                        </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->

            <div class="section_content_wrapper">
                <div class="container">
                    <!-- START:: ABOUT COURSE SECTION -->
                    <div
                        class="about_course_section_wrapper"
                        v-if="$route.params.id != 'distance-learning-lessons'"
                    >
                        <h2 class="course_section_title">
                            {{ $t("TITLES.about_section") }}
                        </h2>

                        <div class="text_wrapper">
                            {{ categoryData.desc }}
                        </div>
                    </div>
                    <!-- END:: ABOUT COURSE SECTION -->

                    <!-- START:: SUBJECTS -->
                    <div class="course_section_subjects_wrapper">
                        <h2 class="course_section_title">
                            {{ $t("TITLES.subjects") }}
                        </h2>
                        <!-- START:: EMPTY COURSES MESSAGE -->
                        <EmptyCoursesMessage v-if="subjects.length == 0" />
                        <!-- END:: EMPTY COURSES MESSAGE -->
                        <div class="row" v-else>
                            <!-- START:: COURSE CARD -->
                            <div
                                v-for="course in subjects"
                                :key="course.id"
                                class="col-lg-3 my-3"
                            >
                                <!-- TEACHER CARD  -->
                                <router-link
                                    :to="redirectRoute(course.id)"
                                    v-if="registeredUserType == 'teacher'"
                                >
                                    <ImagedCard>
                                        <template v-slot:card_image>
                                            <img
                                                :src="course.image"
                                                :alt="
                                                    course.subject_name
                                                        .subject_name
                                                "
                                            />
                                        </template>

                                        <template v-slot:card_text>
                                            <div class="wrapper">
                                                <p class="course_name">
                                                    {{
                                                        course.subject_name
                                                            .subject_name
                                                    }}
                                                </p>
                                                <p
                                                    class="duration"
                                                    v-if="
                                                        getAuthenticatedUserData.type ==
                                                        'parent'
                                                    "
                                                >
                                                    <span
                                                        >{{
                                                            course.duration
                                                                .hours
                                                        }}
                                                        ساعات
                                                    </span>
                                                    <span
                                                        >{{
                                                            course.duration
                                                                .minutes
                                                        }}
                                                        دقيقة
                                                    </span>
                                                </p>
                                                <p
                                                    class="courses_count"
                                                    v-if="
                                                        getAuthenticatedUserData.type ==
                                                        'student'
                                                    "
                                                >
                                                    {{ course.courses }} كورس
                                                </p>
                                            </div>

                                            <div class="wrapper">
                                                <p
                                                    class="teachers_count"
                                                    v-if="
                                                        getAuthenticatedUserData.type ==
                                                        'student'
                                                    "
                                                >
                                                    {{ course.teachers }} معلم
                                                </p>
                                            </div>

                                            <div
                                                class="
                                                    wrapper
                                                    w-100
                                                    d-flex
                                                    justify-content-between
                                                "
                                            >
                                                <span class="rate">
                                                    <i class="fas fa-star"></i>
                                                    <span>
                                                        {{ course.avg_rate }}
                                                    </span>
                                                </span>
                                                <p class="price">
                                                    {{ course.price }}
                                                    {{ course.currency }}
                                                </p>
                                            </div>
                                        </template>
                                    </ImagedCard>
                                </router-link>
                                <!-- STUDENT CARD  -->
                                <router-link
                                    :to="redirectRoute(course.id)"
                                    v-if="registeredUserType == 'student'"
                                >
                                    <ImagedCard>
                                        <template v-slot:card_image>
                                            <img
                                                :src="course.specialization_ar"
                                                alt="Course Category Image"
                                            />
                                        </template>

                                        <template v-slot:card_text>
                                            <div class="wrapper">
                                                <p class="course_name">
                                                    {{ course.subject_name }}
                                                </p>
                                                <p class="courses_count">
                                                    {{ course.num_of_courses }}
                                                    كورس
                                                </p>
                                            </div>

                                            <div class="wrapper">
                                                <p class="teachers_count">
                                                    {{ course.num_of_teachers }}
                                                    معلم
                                                </p>
                                            </div>
                                        </template>
                                    </ImagedCard>
                                </router-link>
                            </div>
                            <!-- END:: COURSE CARD -->
                        </div>
                    </div>
                    <!-- END:: SUBJECTS -->
                </div>
            </div>
        </div>
        <!-- END:: SECTION -->
        <!-- START:: PAGENATION FOR TEACHER-->
        <Pagenation
            :last_page="last_page"
            :current_page="current_page"
            @pagenationClick="pagenationClick"
        ></Pagenation>
        <!-- END:: PAGENATION FOR TEACHER-->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

// START:: IMPORTING IMAGED CARD COMPONENT
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING IMAGED CARD COMPONENT
// START:: IMPORTING EMPTY MESSAGES
import EmptyCoursesMessage from "../../components/ui/emptyMessages/EmptyCoursesMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "CoursesSection",

    components: {
        MainLoader,
        ImageHeader,
        ImagedCard,
        EmptyCoursesMessage,
        Pagenation,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: SECTION TYPE DATA
            sectionType: this.$route.params.type,
            // END:: SECTION TYPE DATA

            // START:: SUBJECTS DATA
            subjects: [],
            // END:: SUBJECTS DATA

            // START:: CATEGORY DATA
            categoryData: [],
            // END:: CATEGORY DATA

            // START:: REGISTERED USER TYPE
            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),
            // END:: REGISTERED USER TYPE
            // START:: PAGENATION
            last_page: null,
            current_page: 1,
            // END:: PAGENATION
        };
    },

    methods: {
        getCoursesData() {
            this.isLoading = true;
            if (this.$route.params.id == "distance-learning-lessons") {
                this.$axios
                    .get(`student/all-subject-name`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.subjects = res.data.data;
                        this.isLoading = false;
                    });
            } else {
                if (this.registeredUserType == "teacher") {
                    this.$axios
                        .get(`teacher/category/${this.$route.params.id}`, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                        "elmo3lm_elmosa3d_user_token"
                                    ),
                                "Accept-language": localStorage.getItem(
                                    "elmo3lm_elmosa3d_app_lang"
                                ),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        })
                        .then((res) => {
                            this.isLoading = false;
                            this.categoryData = res.data.data.category;
                            this.subjects = res.data.data.subjects;
                        });
                } else if (this.registeredUserType == "student") {
                    // student/all-courses/4
                    this.$axios
                        .get(
                            `student/category/courses/${this.$route.params.id}?page=${this.current_page}`,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                            "elmo3lm_elmosa3d_user_token"
                                        ),
                                    "Accept-language": localStorage.getItem(
                                        "elmo3lm_elmosa3d_app_lang"
                                    ),
                                    "cache-control": "no-cache",
                                    Accept: "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            this.isLoading = false;
                            this.categoryData = res.data.category;
                            this.subjects = res.data.data;
                            this.last_page = res.data.meta.last_page;
                        });
                }
            }
        },

        redirectRoute(course_id) {
            if (this.getAuthenticatedUserData.type == "teacher") {
                return `/teacher/course-details/online/${course_id}`;
            } else {
                if (this.sectionType == "Private_reinforcement") {
                    return `/strengthening-requests-teachers/${course_id}`;
                }
                return `/courses-categories/${course_id}`;
            }
        },
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getCoursesData();
        },
        // END:: PAGENATION
    },

    mounted() {
        this.getCoursesData();
    },
};
</script>
