<template>
    <div class="shopping_cart_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title> {{ $t("TITLES.cart") }} </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.cart") }}
            </template>
        </Breadcrumb>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <!-- END:: BREADCRUMB -->
        <div class="fadeIn" v-else>
            <!-- START:: EMPTY CART MESSAGE -->
            <EmptyCartMessage v-if="getShoppingCartItems.data.length == 0" />
            <!-- END:: EMPTY CART MESSAGE -->

            <!-- START:: CART CONTENT -->
            <div class="content_wrapper" v-else>
                <!-- START:: SHOPPING CART CONTENT -->
                <div class="shopping_cart_content">
                    <div class="container">
                        <div class="row">
                            <!-- START:: CART ITEM CARD -->
                            <div
                                v-for="item in getShoppingCartItems.data"
                                :key="item.id"
                                class="col-lg-6 my-3"
                            >
                                <div class="cart_item_card_wrapper">
                                    <div class="item_details">
                                        <!-- START:: ITEM IMAGE -->
                                        <div class="image_wrapper">
                                            <img
                                                :src="
                                                    item.product.product_image
                                                "
                                                :alt="item.product.name"
                                                width="120"
                                                height="120"
                                            />
                                        </div>
                                        <!-- END:: ITEM IMAGE -->

                                        <div class="wrapper">
                                            <!-- START:: ITEM NAME AND QUANTITY -->
                                            <h3 class="name_and_quantity">
                                                <span class="name">
                                                    {{ item.product.name }}
                                                </span>
                                                <span class="quantity">
                                                    {{ item.count }} X
                                                </span>
                                            </h3>
                                            <!-- END:: ITEM NAME AND QUANTITY -->

                                            <!-- START:: ITEM PRICE -->
                                            <h3 class="price">
                                                {{ item.total }}
                                                {{ item.product.currency }}
                                            </h3>
                                            <!-- END:: ITEM PRICE -->
                                        </div>
                                    </div>

                                    <div class="remove_from_cart_btn_wrapper">
                                        <!-- START:: REMOVE FROM CART BUTTON -->
                                        <button
                                            class="remove_item_btn"
                                            @click="removeFromCart(item.id)"
                                        >
                                            <i class="fal fa-trash-alt"></i>
                                        </button>
                                        <!-- END:: REMOVE FROM CART BUTTON -->
                                    </div>
                                </div>
                            </div>
                            <!-- END:: CART ITEM CARD -->
                        </div>
                    </div>
                </div>
                <!-- END:: SHOPPING CART CONTENT -->

                <!-- START:: SHOPPING CART FINANCIAL -->
                <div class="shopping_cart_finance">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <!-- START:: CART FINANCE LINE -->
                                <div class="cart_finance_line">
                                    <h3>
                                        <span class="title">
                                            {{ $t("TITLES.products_total") }}
                                        </span>
                                        <span class="price">
                                            {{
                                                getShoppingCartItems.price
                                                    .main_total
                                            }}
                                            ر.س
                                        </span>
                                    </h3>
                                </div>
                                <!-- END:: CART FINANCE LINE -->

                                <!-- START:: CART FINANCE LINE -->
                                <div class="cart_finance_line">
                                    <h3>
                                        <span class="title">
                                            {{ $t("TITLES.added_tax") }}
                                        </span>
                                        <span class="price">
                                            {{
                                                getShoppingCartItems.price
                                                    .vat_value
                                            }}
                                            ر.س
                                        </span>
                                    </h3>
                                </div>
                                <!-- END:: CART FINANCE LINE -->

                                <!-- START:: CART FINANCE LINE -->
                                <div class="cart_finance_line">
                                    <h3>
                                        <span class="title">
                                            {{ $t("TITLES.total") }}
                                        </span>
                                        <span class="price">
                                            {{
                                                getShoppingCartItems.price
                                                    .total_with_vat
                                            }}
                                            ر.س
                                        </span>
                                    </h3>
                                </div>
                                <!-- END:: CART FINANCE LINE -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END:: SHOPPING CART FINANCIAL -->

                <!-- START:: CHECKOUT ROUTE -->
                <div class="col-12">
                    <div class="route_wrapper">
                        <router-link to="/checkout" class="checkout_route">
                            {{ $t("BUTTONS.next") }}
                        </router-link>
                    </div>
                </div>
                <!-- END:: CHECKOUT ROUTE -->
            </div>
        </div>
        <!-- END:: CART CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING EMPTY CART MESSAGE COMPONENT
import EmptyCartMessage from "../../components/ui/emptyMessages/EmptyCartMessage.vue";
// END:: IMPORTING EMPTY CART MESSAGE COMPONENT
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "ShoppingCart",

    components: {
        Breadcrumb,
        EmptyCartMessage,
        MainLoader,
    },
    data() {
        return {
            isLoading: true,
        };
    },

    computed: {
        // START:: VUEX GET SHOPPING CART DATA
        ...mapGetters("StoreAndWishlistModule", ["getShoppingCartItems"]),
        // END:: VUEX GET SHOPPING CART DATA
    },

    methods: {
        // START:: VUEX REMOVE ITEM FROM SHOPPING CART
        ...mapActions("StoreAndWishlistModule", ["getCart", "removeFromCart"]),
        // END:: VUEX REMOVE ITEM FROM SHOPPING CART
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
};
</script>
