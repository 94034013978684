<template>
    <div class="favorites_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title>
                {{ $t("TITLES.favorites") }}
            </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.favorites") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <!-- START:: STUDENT FAVORITES -->
        <StudentFavorites v-if="getAuthenticatedUserData.type == 'student'" />
        <!-- END:: STUDENT FAVORITES -->

        <!-- START:: TEACHER FAVORITES -->
        <TeacherFavorites v-if="getAuthenticatedUserData.type == 'teacher'" />
        <!-- END:: TEACHER FAVORITES -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// START:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING FAVORITES COMPONENTS
import StudentFavorites from "../components/favorites/StudentFavorites.vue";
import TeacherFavorites from "../components/favorites/TeacherFavorites.vue";
// END:: IMPORTING FAVORITES COMPONENTS

export default {
    name: "Favorites",

    components: {
        Breadcrumb,
        StudentFavorites,
        TeacherFavorites,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },
};
</script>
