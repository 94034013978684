<template>
    <div class="login_form_wrapper col-lg-6">
        <!-- START:: FORM TITLE -->
        <div class="form_title_wrapper">
            <!-- START:: LOGO -->
            <router-link to="/" class="logo_wrapper">
                <img
                    src="../../assets/media/logo/logo.svg"
                    alt="Al-Moalem Al-Mosaed App Logo"
                    width="150"
                    height="120"
                />
            </router-link>
            <!-- END:: LOGO -->

            <!-- START:: TITLES -->
            <h2 class="form_title">{{ $t("TITLES.login") }}</h2>
            <h3 class="form_subtitle">{{ $t("TITLES.welcome_back") }}</h3>
            <!-- END:: TITLES -->
        </div>
        <!-- END:: FORM TITLE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form class="mt-4" @submit.prevent="validateLoginFormInputs">
                <div class="row justify-content-center">
                    <!-- START:: PHONE INPUT GROUP -->
                    <div class="col-lg-7 my-3">
                        <div
                            class="input_wrapper phone_input_wrapper"
                            dir="ltr"
                        >
                            <div
                                class="selected_key_wrapper"
                                @click="toggleCountryKeysMenu"
                                v-if="countriesData.selectedCountry"
                            >
                                <div class="flag_wrapper">
                                    <img
                                        :src="
                                            countriesData.selectedCountry.flag
                                        "
                                        :alt="
                                            countriesData.selectedCountry.name
                                        "
                                        width="30"
                                        height="20"
                                    />
                                </div>
                                <div class="key_wrapper" dir="ltr">
                                    {{ countriesData.selectedCountry.key }}
                                </div>

                                <transition name="fadeInUp" mode="out-in">
                                    <div
                                        class="country_keys_menu_wrapper"
                                        v-if="countryKiesMenuIsOpen"
                                    >
                                        <ul
                                            class="country_keys_menu"
                                            v-if="countriesData"
                                        >
                                            <li
                                                class="menu_item"
                                                v-for="countryKey in countriesData.allCountries"
                                                :key="countryKey.id"
                                                @click="
                                                    changeSelectedPhoneCode(
                                                        countryKey
                                                    )
                                                "
                                            >
                                                <div class="flag_wrapper">
                                                    <img
                                                        :src="countryKey.flag"
                                                        :alt="countryKey.name"
                                                        width="30"
                                                        height="20"
                                                    />
                                                </div>
                                                <div
                                                    class="key_wrapper"
                                                    dir="ltr"
                                                >
                                                    {{ countryKey.key }}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </transition>
                            </div>

                            <input
                                type="number"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.phone')"
                                v-model="loginData.phone"
                                @focus="countryKiesMenuIsOpen = false"
                            />
                        </div>
                    </div>
                    <!-- END:: PHONE INPUT GROUP -->

                    <!-- START:: PASSWORD INPUT GROUP -->
                    <div class="col-lg-7 my-3">
                        <div class="input_wrapper password_input_wrapper">
                            <input
                                type="password"
                                class="form-control"
                                :placeholder="$t('PLACEHOLDERS.password')"
                                v-model.trim="loginData.password"
                            />
                            <button
                                type="button"
                                class="password_visibility_toggler"
                                @click="togglePasswordVisibility"
                            >
                                <i class="fal fa-eye"></i>
                                <i class="fal fa-eye-slash"></i>
                            </button>
                        </div>
                    </div>
                    <!-- END:: PASSWORD INPUT GROUP -->

                    <!-- START:: FORGET PASSWORD ROUTE WRAPPER -->
                    <div class="col-lg-7 my-3">
                        <router-link
                            to="/reset-password-phone-number"
                            class="forget_pass_route"
                        >
                            {{ $t("BUTTONS.forget_password") }}
                        </router-link>
                    </div>
                    <!-- END:: FORGET PASSWORD ROUTE WRAPPER -->

                    <!-- START:: SUBMIT BUTTON WRAPPER -->
                    <div class="col-lg-7 my-3">
                        <div class="btn_wrapper">
                            <button class="w-100 mt-0">
                                {{ $t("BUTTONS.login") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequest"
                                ></span>
                            </button>
                        </div>
                    </div>
                    <!-- END:: SUBMIT BUTTON WRAPPER -->
                </div>
            </form>

            <!-- START:: REGISTER ROUTE WRAPPER -->
            <router-link to="/select-register-type" class="register_route">
                <span>{{ $t("BUTTONS.dont_have_account") }}</span>
                <span> {{ $t("BUTTONS.register") }} </span>
            </router-link>
            <!-- END:: REGISTER ROUTE WRAPPER -->
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "LoginForm",

    computed: {
        // START:: VUEX GET API GETS DATA
        ...mapGetters("ApiGetsModule", ["countriesData"]),
        // END:: VUEX GET API GETS DATA
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: COUNTRIES KEYS MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KEYS MENU HANDLING DATA

            // START:: LOGIN DATA
            loginData: {
                phone: null,
                password: null,
            },
            // END:: LOGIN DATA
        };
    },

    methods: {
        // START:: VUEX SET AUTHENTICATED USER DATA
        ...mapActions("AuthenticationModule", ["setAuthenticatedUserData"]),
        // END:: VUEX SET AUTHENTICATED USER DATA

        // START:: VUEX API GETS ACTIONS
        ...mapActions("ApiGetsModule", [
            "getCountries",
            "changeSelectedPhoneCode",
        ]),
        // END:: VUEX API GETS ACTIONS

        // START:: TOGGLE COUNTRIES KEYS MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KEYS MENU

        // START:: TOGGLE PASSWORD VISIBILITY METHOD
        togglePasswordVisibility(e) {
            let passwordElement = e.currentTarget.parentElement.children[0];
            let passwordTogglerBtn = e.currentTarget;
            if (passwordElement.type == "password") {
                passwordElement.type = "text";
                passwordTogglerBtn.classList.add("password_is_visible");
            } else if (passwordElement.type == "text") {
                passwordElement.type = "password";
                passwordTogglerBtn.classList.remove("password_is_visible");
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY METHOD

        // START:: VALIDATE LOGIN FORM INPUTS
        validateLoginFormInputs() {
            this.isWaitingRequest = true;

            if (!this.loginData.phone) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.phone_number"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.loginData.password) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (this.loginData.password.length < 6) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password_length"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitLoginForm();
            }
        },
        // END:: VALIDATE LOGIN FORM INPUTS

        // START:: SUBMIT LOGIN FORM
        submitLoginForm() {
            this.isWaitingRequest = true;

            const theData = new FormData();
            // START:: APPEND STATIC DATA
            theData.append("type", "ios");
            theData.append("device_token", "static_device_token");
            // END:: APPEND STATIC DATA

            // START:: APPEND GENERAL DATA
            theData.append("country_id", this.countriesData.selectedCountry.id);
            theData.append("identifier", this.loginData.phone);
            theData.append("password", this.loginData.password);
            // END:: APPEND GENERAL DATA

            // START:: SEND REQUEST
            this.$axios
                .post("login", theData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isWaitingRequest = false;

                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("VALIDATION.login_success"),
                        messageSize: "22",
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });

                    // START:: LOCAL STORAGE SET REGISTERED USER DATA
                    this.setAuthenticatedUserData({
                        type: res.data.data.user_type,
                        token: res.data.data.token.access_token,
                        avatar: res.data.data.profile_image,
                    });
                    localStorage.setItem("elmo3lm_elmosa3d_user_id", res.data.data.id);
                    if(res.data.data.user_type == "student") {
                        localStorage.setItem("elmo3lm_elmosa3d_student's_parent_phone", res.data.data.child_parent.parent.phone);
                        localStorage.setItem("elmo3lm_elmosa3d_student's_parent_key", JSON.stringify(res.data.data.child_parent.parent.country));
                    }
                    // END:: LOCAL STORAGE SET REGISTERED USER DATA

                    this.clearLoginFormData();
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                    return;
                })
                .catch(() => {
                    this.isWaitingRequest = false;

                    this.$iziToast.error({
                        timeout: 2000,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                        message: this.$t("VALIDATION.login_failed"),
                        messageSize: "22",
                    });
                    return;
                });
            // END:: SEND REQUEST
        },
        // END:: SUBMIT LOGIN FORM

        // START:: CLEAR LOGIN FORM DATA
        clearLoginFormData() {
            this.loginData.phone = null;
            this.loginData.password = null;
        },
        // END:: CLEAR LOGIN FORM DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getCountries();
        // END:: FIRE METHODS
    },
};
</script>
