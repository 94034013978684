<template>
    <div class="wrapper w-100">
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: CONTENT WRAPPER -->
        <div class="terms_page_wrapper fadeIn" v-else>
            <!-- START:: BREADCRUMB -->
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.terms") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.terms") }}
                </template>
            </Breadcrumb>
            <!-- END:: BREADCRUMB -->

            <!-- START:: CONTENT -->
            <div class="container">
                <div class="page_content" v-html="termsAndConditions"></div>
            </div>
            <!-- END:: CONTENT -->
        </div>
        <!-- END:: CONTENT WRAPPER -->
    </div>
</template>

<script>
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "TermsAndConditions",

    components: {
        MainLoader,
        Breadcrumb,
    },

    data() {
        return {
            // START:: HANDLING MAIN LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING MAIN LOADER APPERANCE

            // START:: TERMS AND CONDITIONS DATA
            termsAndConditions: null,
            // END:: TERMS AND CONDITIONS DATA
        };
    },

    methods: {
        // START:: GET TERMS AND CONDITIONS
        getTermsAndConditions() {
            this.$axios
                .get("terms", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    // START:: HANDLING MAIN LOADER APPERANCE
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                    // END:: HANDLING MAIN LOADER APPERANCE

                    this.termsAndConditions = res.data.data.terms;
                });
        },
        // END:: GET TERMS AND CONDITIONS
    },

    mounted() {
        // START:: GET TERMS AND CONDITIONS
        this.getTermsAndConditions();
        // END:: GET TERMS AND CONDITIONS
    },
};
</script>
