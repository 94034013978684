import CreateCourseMutations from "./mutations.js";
import CreateCourseActions from "./actions.js";
import CreateCourseGetters from "./getters.js";

export default {
    namespaced: true,

    state: {
        // START:: CREATE COURSE DATA
        createCourseStepOne: null,
        createCourseStepTwo: null,
        // END:: CREATE COURSE DATA
    },

    mutations: CreateCourseMutations,
    actions: CreateCourseActions,
    getters: CreateCourseGetters,
};
