<template>
    <div class="auth_wrapper">
        <!-- START:: AUTH ROUTER VIEW -->
        <div class="row justify-content-center">
            <transition name="fadeInUp" mode="out-in">
                <router-view :key="$route.path" />
            </transition>
        </div>
        <!-- END:: AUTH ROUTER VIEW -->
    </div>
</template>

<script>
export default {
    name: "Authentication",
};
</script>
