<template :key="$route.path">
    <div class="chats_wrapper">
        <!-- START:: BREADCRUMB -->
        <Breadcrumb>
            <template v-slot:page_title> {{ $t("TITLES.messages") }} </template>
            <template v-slot:breadcrumb_current_page>
                {{ $t("TITLES.messages") }}
            </template>
        </Breadcrumb>
        <!-- END:: BREADCRUMB -->

        <div class="chats_content_wrapper">
            <div class="container">
                <div class="row">
                    <!-- START:: CHATS LIST -->
                    <div class="col-lg-4" id="chats_list">
                        <router-view
                            name="chats_list_router_view"
                            :key="$route.path"
                        />
                    </div>
                    <!-- END:: CHATS LIST -->

                    <!-- START:: CHATS CONTENT -->
                    <div class="col-lg-8" id="chat_content">
                        <div class="chat_content_wrapper">
                            <router-view
                                name="chat_content_router_view"
                                :key="$route.path"
                            ></router-view>
                        </div>
                    </div>
                    <!-- END:: CHATS CONTENT -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "Chats",

    components: {
        Breadcrumb,
    },

    data() {
        return {
            // START:: CURRENT ROUTE DATA
            currentRoute: this.$route.path,
            // END:: CURRENT ROUTE DATA
        };
    },

    methods: {
        // START:: HANDLING CHAT MEDIA QUERIES
        handlingMediaQueries() {
            if (window.matchMedia("(max-width: 767px)").matches) {
                if (this.currentRoute == "/chats") {
                    document.getElementById("chats_list").style.display =
                        "block";
                    document.getElementById("chat_content").style.display =
                        "none";
                } else {
                    document.getElementById("chats_list").style.display =
                        "none";
                    document.getElementById("chat_content").style.display =
                        "block";
                }
            }
        },
        // END:: HANDLING CHAT MEDIA QUERIES
    },

    mounted() {
        // START:: HANDLING CHAT MEDIA QUERIES
        this.handlingMediaQueries();
        // END:: HANDLING CHAT MEDIA QUERIES
    },
};
</script>
