<template>
    <div class="content_wrapper_wrapper">
        <!-- START:: HEADER -->
        <TheHeader />
        <!-- END:: HEADER -->

        <!-- START:: APP ROUTER VIEW -->
        <section class="main-content">
            <transition name="fadeInUp" mode="out-in">
                <router-view />
            </transition>
        </section>
        <!-- END:: APP ROUTER VIEW -->

        <!-- START:: FOOTER -->
        <TheFooter />
        <!-- END:: FOOTER -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions, mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING LAYOUTS
import TheHeader from "../components/layouts/TheHeader.vue";
import TheFooter from "../components/layouts/TheFooter.vue";
// END:: IMPORTING LAYOUTS
// socket
import Echo from "laravel-echo";
export default {
    name: "AppContentWrapper",
    data() {
        return {
            userId: "",
        };
    },
    components: {
        TheHeader,
        TheFooter,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START:: VUEX GET NOTIFICATIONS DATA
        ...mapGetters("NotificationsModule", ["notificationsData"]),
        // END:: VUEX GET NOTIFICATIONS DATA
    },

    methods: {
        // START:: VUEX REMOVE ITEM FROM SHOPPING CART
        ...mapActions("StoreAndWishlistModule", ["getCart"]),
        // END:: VUEX REMOVE ITEM FROM SHOPPING CART

        // START:: VUEX NOTIFICATIONS ACTIONS
        ...mapActions("NotificationsModule", ["getNotifications"]),
        // END:: VUEX NOTIFICATIONS ACTIONS

        // START::
        receiveMessage() {
            window.io = require("socket.io-client");
            window.Echo = new Echo({
                broadcaster: "socket.io",
                host: "https://moaalem.khlod.aait-d.com:6045",
                csrfToken:
                    "Bearer " +
                    localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                auth: {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                    },
                },
            });
            // -----------------
            window.Echo.private(`mo3alem-mosa3ed-notification.${this.userId}`)
            .notification((payload) => {
                if (payload) {
                    console.log( "Notifications Payload", payload );
                    this.$iziToast.info({
                        message: payload.body,
                        backgroundColor: "#F8F8FA",
                        timeout: 5000,
                        class: "note-alert",
                        titleColor: "#0E59B6",
                        position: "topRight",
                    });
                    this.getNotifications();
                    new Audio(require("./../../src/message.mp3")).play();
                }
            });
        },
    },
    mounted() {
        if (this.getAuthenticatedUserData.token) {
            this.getCart();
            this.userId = localStorage.getItem("elmo3lm_elmosa3d_user_id");
            this.receiveMessage();
        }
    },
};
</script>
